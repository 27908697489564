import * as U from "../utils";

export const isProperPurchaseItemId = (id: string) => {
  // 20211020-01 처럼 YYYYMMDD-NN 형식에 맞는지 체크한다.
  if (!id) return false;
  if (id.length !== 11) return false;
  if (id[8] !== "-") return false;
  let dateStr = id.substring(0, 8);
  if (!U.isProperEightDigitStringDate(dateStr)) return false;
  let nnStr: string = id.substring(9, 11);
  let nn: number = parseInt(nnStr);
  if (Number.isNaN(nn)) return false;
  if (nn === 0) return false;
  return true;
};

export const isProperSystemItemId = (id: string) => {
  // 20211020-01 처럼 YYYYMMDD-NN 형식에 맞는지 체크한다.
  if (!id) return false;
  if (id.length !== 11) return false;
  if (id[8] !== "-") return false;
  let dateStr = id.substring(0, 8);
  if (!U.isProperEightDigitStringDate(dateStr)) return false;
  let nnStr: string = id.substring(9, 11);
  let nn: number = parseInt(nnStr);
  if (Number.isNaN(nn)) return false;
  if (nn === 0) return false;
  return true;
};

export const isInt = (str: string) => {
  // 001 처럼 0으로 시작하는 숫자는 return false
  if (!str) return false;
  if (str.length == 0) return false;
  let nn: number = parseInt(str);
  if (Number.isNaN(nn)) return false;
  let str2: string = `${nn}`;
  if (str.length !== str2.length) return false;
  return true;
};

export const isFloat = (str: string) => {
  // . 을 기준으로 앞과 뒤로 나눈다.
  if (!str) return false;
  if (str.length == 0) return false;
  let splitted = str.split(".");
  if (2 < splitted.length) return false;
  if (splitted.length == 1) return isInt(str);
  else {
    // back은 "123", "001", "0" 등이 가능하다.
    let front: string = splitted[0];
    let back: string = splitted[1];
    if (!isInt(front)) return false;
    let back2 = "1" + back;
    if (back.length == 0) return false;
    if (!isInt(back2)) return false;
    return true;
  }
};
