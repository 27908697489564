import { createContext, useContext } from "react";
export type MeetingContextType = {
  no: string;
  title: string;
  date: Date;
  type: string;
  affiliation: string;
  location: string;
  members: string;
  registrant: string;
  purpose: string;
  content: string;
};
const MeetingContext = createContext<MeetingContextType>(
  {} as MeetingContextType
);
const useMeeting = () => useContext(MeetingContext);
export { useMeeting };
