import { createContext, useContext } from "react";
export type DailyWorkContextType = {
  // taskNo와 taskName에 대한 list와 projectNo와 projectName에 대한 list를 저장하며, status에 관계없이 모두 저장한다.
  taskNos: Array<number>;
  taskNames: Array<string>;
  projectNos: Array<number>;
  projectNames: Array<string>;

  dates: Array<number>;
  userIds: Array<string>;
  taskNo1: Array<number>;
  projectNo1: Array<number>;
  taskNo2: Array<number>;
  projectNo2: Array<number>;
  taskNo3: Array<number>;
  projectNo3: Array<number>;
  taskNo4: Array<number>;
  projectNo4: Array<number>;
  taskNo5: Array<number>;
  projectNo5: Array<number>;
  taskNo6: Array<number>;
  projectNo6: Array<number>;
  taskNo7: Array<number>;
  projectNo7: Array<number>;
  taskNo8: Array<number>;
  projectNo8: Array<number>;
  taskNo9: Array<number>;
  projectNo9: Array<number>;
  taskNo10: Array<number>;
  projectNo10: Array<number>;
};
const DailyWorkContext = createContext<DailyWorkContextType>({} as DailyWorkContextType);
const useDailyWork = () => useContext(DailyWorkContext);
export { useDailyWork };
