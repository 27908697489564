import "./patentNew.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { uploadPatentWithoutFile, uploadPatentWith2Files } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import DateBox from "devextreme-react/date-box";

export default function PatentNew() {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (user) {
  }
  useEffect(() => {}, []);
  const [file, setFile] = useState<File>();
  const [file2, setFile2] = useState<File>();
  const [hiddenFile, setHiddenFile] = useState<boolean>(true);

  const [event, setEvent] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [appNumber, setAppNumber] = useState("");
  const [filingDate, setFilingDate] = useState("");
  const [patentNumber, setPatentNumber] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [title, setTitle] = useState("");
  const [country, setCountry] = useState("");
  const [patentee, setPatentee] = useState("");
  const [inventor1, setInventor1] = useState("");
  const [inventor2, setInventor2] = useState("");
  const [inventor3, setInventor3] = useState("");
  const [price, setPrice] = useState("");
  const [remark, setRemark] = useState("");

  function onEventTextBoxValueChanged(e: string) {
    setEvent(e);
    if (e === "등록") setHiddenFile(false);
    else setHiddenFile(true);
  }
  function onEventDateTextBoxValueChanged(e: string) {
    setEventDate(e);
  }
  function onAppNumberTextBoxValueChanged(e: string) {
    setAppNumber(e);
  }
  function onFilingDateTextBoxValueChanged(e: string) {
    setFilingDate(e);
  }
  function onPatentNumberTextBoxValueChanged(e: string) {
    setPatentNumber(e);
  }
  function onRegistrationDateTextBoxValueChanged(e: string) {
    setRegistrationDate(e);
  }
  function onTitleTextBoxValueChanged(e: string) {
    setTitle(e);
  }
  function onCountryTextBoxValueChanged(e: string) {
    setCountry(e);
  }
  function onPatenteeTextBoxValueChanged(e: string) {
    setPatentee(e);
  }
  function onInventor1TextBoxValueChanged(e: string) {
    setInventor1(e);
  }
  function onInventor2TextBoxValueChanged(e: string) {
    setInventor2(e);
  }
  function onInventor3TextBoxValueChanged(e: string) {
    setInventor3(e);
  }
  function onPriceTextBoxValueChanged(e: string) {
    setPrice(e);
  }
  function onRemarkTextBoxValueChanged(e: string) {
    setRemark(e);
  }
  // png file
  const onChangeImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      let f = e.target.files[0];
      setFile(f);
    }
  };
  // pdf file
  const onChangeImg2 = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      let f = e.target.files[0];
      setFile2(f);
    }
  };

  function onUploadButtonClicked() {
    if (!event || !eventDate || !appNumber || !filingDate) {
      alert("Fill in inputs!", "Error");
      return;
    }

    // 8 digit date 체크
    if (0 < eventDate.length) {
      if (!U.isProperEightDigitStringDate(eventDate)) {
        alert("Enter proper value in Event Date!", "Error");
        return;
      }
    }
    if (0 < filingDate.length) {
      if (!U.isProperEightDigitStringDate(filingDate)) {
        alert("Enter proper value in Filing Date!", "Error");
        return;
      }
    }
    if (0 < registrationDate.length) {
      if (!U.isProperEightDigitStringDate(registrationDate)) {
        alert("Enter proper value in Registration Date!", "Error");
        return;
      }
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let eventDateN = parseInt(eventDate);
    if (Number.isNaN(eventDateN)) eventDateN = 0;

    let filingDateN = parseInt(filingDate);
    if (Number.isNaN(filingDateN)) filingDateN = 0;

    let registrationDateN = parseInt(registrationDate);
    if (Number.isNaN(registrationDateN)) registrationDateN = 0;

    let priceN = parseInt(price);
    if (Number.isNaN(priceN)) priceN = 0;

    if (!hiddenFile) {
      if (!file || !file2) {
        alert("Select file first!", "Error");
        return;
      }
      uploadPatentWith2Files(
        event,
        eventDateN,
        appNumber,
        filingDateN,
        patentNumber,
        registrationDateN,
        title,
        country,
        patentee,
        inventor1,
        inventor2,
        inventor3,
        priceN,
        remark,
        file,
        file2
      );
    } else {
      uploadPatentWithoutFile(
        event,
        eventDateN,
        appNumber,
        filingDateN,
        patentNumber,
        registrationDateN,
        title,
        country,
        patentee,
        inventor1,
        inventor2,
        inventor3,
        priceN,
        remark
      );
    }

    navigate(-1);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>특허 Event 등록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerV"}>
            <div className={"flex-item2"}>
              <TextBox
                label="Event"
                placeholder="Event"
                valueChangeEvent="keyup"
                onValueChange={onEventTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="Event Date (YYYYMMDD)"
                placeholder="Event Date (YYYYMMDD)"
                valueChangeEvent="keyup"
                onValueChange={onEventDateTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="출원번호"
                placeholder="출원번호"
                valueChangeEvent="keyup"
                onValueChange={onAppNumberTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="출원일 (YYYYMMDD)"
                placeholder="출원일 (YYYYMMDD)"
                valueChangeEvent="keyup"
                onValueChange={onFilingDateTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="등록번호"
                placeholder="등록번호"
                valueChangeEvent="keyup"
                onValueChange={onPatentNumberTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="등록일 (YYYYMMDD)"
                placeholder="등록일 (YYYYMMDD)"
                valueChangeEvent="keyup"
                onValueChange={onRegistrationDateTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="특허명"
                placeholder="특허명"
                valueChangeEvent="keyup"
                onValueChange={onTitleTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="국가"
                placeholder="국가"
                valueChangeEvent="keyup"
                onValueChange={onCountryTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="출원인"
                placeholder="출원인"
                valueChangeEvent="keyup"
                onValueChange={onPatenteeTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="발명자1"
                placeholder="발명자1"
                valueChangeEvent="keyup"
                onValueChange={onInventor1TextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="발명자2"
                placeholder="발명자2"
                valueChangeEvent="keyup"
                onValueChange={onInventor2TextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="발명자3"
                placeholder="발명자3"
                valueChangeEvent="keyup"
                onValueChange={onInventor3TextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="비용 (원)"
                placeholder="비용 (원)"
                valueChangeEvent="keyup"
                onValueChange={onPriceTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="Remark"
                placeholder="Remark"
                valueChangeEvent="keyup"
                onValueChange={onRemarkTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-containerH"}>
              <div className={"flex-item1"} hidden={hiddenFile}>
                <p>등록증 (png)</p>
              </div>
              <div className={"flex-item1"} hidden={hiddenFile}>
                <input type="file" accept=".png" onChange={onChangeImg} />
              </div>
            </div>

            <div className={"flex-containerH"}>
              <div className={"flex-item1"} hidden={hiddenFile}>
                <p>등록공보 (pdf)</p>
              </div>
              <div className={"flex-item1"} hidden={hiddenFile}>
                <input type="file" accept=".pdf" onChange={onChangeImg2} />
              </div>
            </div>

            <div className={"flex-item3"}>
              <Button text="Upload" onClick={onUploadButtonClicked} type="default" icon="upload" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
