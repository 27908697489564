import "./purchaseItem.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { usePurchaseItem } from "../../contexts/purchaseItem";
// api
import { queryJsonArray, deleteRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function PurchaseItem() {
  const { user } = useAuth();
  const purchaseItem = usePurchaseItem();
  const navigate = useNavigate();

  if (user) {
  }
  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedId, setSelectedId] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);
  const [isPurchaseRequestButtonVisible, setIsPurchaseRequestButtonVisible] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
    let acc_purch = 0;
    if (user) {
      setUserId(user.id);
      acc_purch = user.acc_purch;
    }
    // (purch) [0] purchase item R, [1] W, [2] D, [3] purchase request R, [4] W, [5] D, [6] Order
    if (U.bitAt(acc_purch, 1)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_purch, 2)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);
    if (U.bitAt(acc_purch, 4)) setIsPurchaseRequestButtonVisible(true);
    else setIsPurchaseRequestButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setSelectedId("");
    setSelectedRow(undefined);
    let query = `SELECT * FROM purchaseitem`;
    queryJsonArray("/purchase/queryitem", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function onNewButtonClicked() {
    purchaseItem.id = "";
    navigate("/purchase/newItem");
  }

  function copyToPurhcaseItemContext(obj: Object) {
    purchaseItem.id = U.stringValueFromJson(obj, "id");
    purchaseItem.name = U.stringValueFromJson(obj, "name");
    purchaseItem.isDisabled = U.booleanValueFromJson(obj, "isDisabled");
    purchaseItem.purpose = U.stringValueFromJson(obj, "purpose");
    purchaseItem.purchaseUnit = U.stringValueFromJson(obj, "purchaseUnit");
    purchaseItem.unitPrice = U.stringValueFromJson(obj, "unitPrice");
    purchaseItem.usageUnit = U.stringValueFromJson(obj, "usageUnit");
    purchaseItem.purchaseUnitRatio = U.stringValueFromJson(obj, "purchaseUnitRatio");
    purchaseItem.leadTime = U.stringValueFromJson(obj, "leadTime");
    purchaseItem.manager = U.stringValueFromJson(obj, "manager");
    purchaseItem.category = U.stringValueFromJson(obj, "category");
    purchaseItem.site = U.stringValueFromJson(obj, "site");
    purchaseItem.storage = U.stringValueFromJson(obj, "storage");
    let isPeriodicStr: string = U.stringValueFromJson(obj, "isPeriodic");
    if (isPeriodicStr === "1") purchaseItem.isPeriodic = "주기적 구매";
    else purchaseItem.isPeriodic = "일시적 구매";
    let isChemicalStr: string = U.stringValueFromJson(obj, "isChemical");
    if (isChemicalStr === "1") purchaseItem.isChemical = "화학 제품";
    else purchaseItem.isChemical = "일반 제품";
    purchaseItem.catNo = U.stringValueFromJson(obj, "catNo");
    purchaseItem.casNo = U.stringValueFromJson(obj, "casNo");
    purchaseItem.manufacturer = U.stringValueFromJson(obj, "manufacturer");
    purchaseItem.supplier = U.stringValueFromJson(obj, "supplier");
    purchaseItem.contactPerson = U.stringValueFromJson(obj, "contactPerson");
    purchaseItem.contact = U.stringValueFromJson(obj, "contact");
    purchaseItem.selectionReason = U.stringValueFromJson(obj, "selectionReason");
    purchaseItem.comparativeEstimate0 = U.stringValueFromJson(obj, "comparativeEstimate0");
    purchaseItem.comparativeEstimate1 = U.stringValueFromJson(obj, "comparativeEstimate1");
    purchaseItem.comparativeEstimate2 = U.stringValueFromJson(obj, "comparativeEstimate2");
    let hasMsdsStr: string = U.stringValueFromJson(obj, "hasMsds");
    if (hasMsdsStr === "1") purchaseItem.hasMsds = "보유";
    else purchaseItem.hasMsds = "미보유";
    purchaseItem.msdsHazSta = U.stringValueFromJson(obj, "msdsHazSta");
    purchaseItem.hazLev = U.stringValueFromJson(obj, "hazLev");
    purchaseItem.hazMat = U.stringValueFromJson(obj, "hazMat");
    purchaseItem.speHazMat = U.stringValueFromJson(obj, "speHazMat");
    purchaseItem.worEnvHazFac = U.stringValueFromJson(obj, "worEnvHazFac");
    purchaseItem.heaCheHazFac = U.stringValueFromJson(obj, "heaCheHazFac");
    purchaseItem.expStaMat = U.stringValueFromJson(obj, "expStaMat");
    purchaseItem.quaConHazFac = U.stringValueFromJson(obj, "quaConHazFac");
    purchaseItem.repHazMat = U.stringValueFromJson(obj, "repHazMat");
    purchaseItem.nonSecMat = U.stringValueFromJson(obj, "nonSecMat");
    purchaseItem.chemicalNote = U.stringValueFromJson(obj, "chemicalNote");

    purchaseItem.quantity = U.stringValueFromJson(obj, "quantity");
    purchaseItem.safetyQuantity = U.stringValueFromJson(obj, "safetyQuantity");
    purchaseItem.monthlyUsage = U.stringValueFromJson(obj, "monthlyUsage");
    purchaseItem.note = U.stringValueFromJson(obj, "note");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToPurhcaseItemContext(selectedRow);
    navigate("/purchase/newItem");
  }

  function onDeleteButtonClicked() {
    if (selectedId.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedId, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/purchase/deleteitem", selectedId).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onPurchaseRequestButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToPurhcaseItemContext(selectedRow);
    navigate("/purchase/newRequest");
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedId(e.selectedRowsData[0].id);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>구매허가품목 조회</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="구매신청" onClick={onPurchaseRequestButtonClicked} visible={isPurchaseRequestButtonVisible} />
            </div>
          </div>
          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="id" caption="허가품목코드" />
              <Column dataField="name" caption="품명" />
              <Column dataField="isDisabled" caption="비사용" dataType="boolean" />
              <Column dataField="purchaseUnit" caption="구매단위" />
              <Column dataField="unitPrice" caption="구매단위단가" dataType="number" format="#,##0" />
              <Column dataField="usageUnit" caption="재고단위" />
              <Column dataField="purchaseUnitRatio" caption="구매단위비율" dataType="number" format="#,##0" />
              <Column dataField="leadTime" caption="안전재고기간 (일)" dataType="number" format="#,##0" />
              <Column dataField="manager" caption="사내관리담당자" />
              <Column dataField="category" caption="분류" />
              <Column dataField="site" caption="사이트" />
              <Column dataField="storage" caption="보관위치" />
              <Column dataField="selectionReason" caption="선정사유" />
              <Column dataField="isPeriodic" caption="주기적구매여부" dataType="boolean" />
              <Column dataField="isChemical" caption="화학약품여부" dataType="boolean" />
              <Column dataField="catNo" caption="Cat. No." />
              <Column dataField="casNo" caption="Cas/Serial" />
              <Column dataField="purpose" caption="사용용도" />
              <Column dataField="manufacturer" caption="제조사" />
              <Column dataField="supplier" caption="공급업체" />
              <Column dataField="contactPerson" caption="업체담당자" />
              <Column dataField="contact" caption="업체연락처" />
              <Column dataField="comparativeEstimate0" caption="비교견적-1" />
              <Column dataField="comparativeEstimate1" caption="비교견적-2" />
              <Column dataField="comparativeEstimate2" caption="비교견적-3" />
              <Column dataField="hasMsds" caption="MSDS 보유" dataType="boolean" />
              <Column dataField="msdsHazSta" caption="MSDS 유해문구" />
              <Column dataField="hazLev" caption="위험도 (1~5)" />
              <Column dataField="hazMat" caption="관리대상 유해물질" />
              <Column dataField="speHazMat" caption="특별관리물질" />
              <Column dataField="worEnvHazFac" caption="작업환경측정대상 유해인자" />
              <Column dataField="heaCheHazFac" caption="특수건강검진대상 유해인자" />
              <Column dataField="expStaMat" caption="노출기준 설정물질" />
              <Column dataField="quaConHazFac" caption="허용기준 이하 유지대상 유해인자" />
              <Column dataField="repHazMat" caption="공정안전보고서 제출대상 위험물질" />
              <Column dataField="nonSecMat" caption="영업비밀 인정제외 물질" />
              <Column dataField="chemicalNote" caption="비고" />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="id" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
