import "./iotDashboard.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
import { useIotDevice } from "../../contexts/iotDevice";
// api
import { queryJsonArray, deleteRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Tick,
  CommonAxisSettings,
  CommonSeriesSettings,
  Export as ExportChart,
  Legend,
  Margin,
  Label,
  Format,
  Crosshair,
  Tooltip,
  Grid,
} from "devextreme-react/chart";

export default function IotDashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const iotDeviceContext = useIotDevice();

  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");

  const [isChartDivHidden, setIsChartDivHidden] = useState(false);
  const [chartTitle, setChartTitle] = useState("");

  const [iotJsonDataSource, setIotJsonDataSource] = useState<Array<Object>>();
  const [chartDataSource, setChartDataSource] = useState<Array<Object>>();
  const [selectedIotSerial, setSelectedIotSerial] = useState("");
  const [selectedIotRow, setSelectedIotRow] = useState<Object>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    setIsChartDivHidden(true);
    if (user) {
    }
    search();
  }, []);

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setIsChartDivHidden(true);
    let query = "SELECT * FROM iotdevice";
    query += " ORDER BY serial Asc";
    queryJsonArray("/iot/query", query).then((jsonData: Array<Object>) => {
      // 마지막 메시지 수신 후 6분 이상 지났으면 status2 = Offline으로 표시해준다.
      let newJsonData = new Array<Object>();
      let dateNow = new Date(Date.now());
      for (let i = 0; i < jsonData.length; i++) {
        let updated_at = U.dateValueFromJson(jsonData[i], "updated_at");
        let elapsedTime_ms = dateNow.getTime() - updated_at.getTime();
        let status = "Online";
        if (360000 < elapsedTime_ms) status = "Offline"; // threshold = 6 min
        //if (3600000 < elapsedTime_ms) status = "Offline";
        let obj: Object | null = U.addStringToJson(jsonData[i], "status", status);
        if (obj !== null) newJsonData.push(obj);
      }

      setIotJsonDataSource(newJsonData);
    });
  }

  function onEditButtonClicked() {
    if (!selectedIotRow) {
      alert("Select row first!", "Error");
      return;
    }
    if (selectedIotSerial.length === 0) {
      alert("Select row first!", "Error");
      return;
    }

    iotDeviceContext.serial = U.stringValueFromJson(selectedIotRow, "serial");
    iotDeviceContext.location = U.stringValueFromJson(selectedIotRow, "location");

    navigate("/iot/edit");
  }
  function onDeleteButtonClicked() {
    if (selectedIotSerial.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedIotSerial, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/iot/deleteiotdevice", selectedIotSerial).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onChartSearchButtonClicked() {
    chartSearch(filterFrom, filterTo);
  }
  function chartSearch(from: string, to: string) {
    if (!U.isProperEightDigitStringDate(from)) {
      alert("Enter from date in proper format!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(to)) {
      alert("Enter to date in proper format!", "Error");
      return;
    }
    let dateFrom: Number = parseInt(from);
    let dateTo: Number = parseInt(to);

    let query = "SELECT * FROM iotlog";
    query += ` WHERE date >= '${dateFrom}'`;
    query += ` AND date <= '${dateTo}'`;
    query += ` AND serial = '${selectedIotSerial}'`;
    query += " ORDER BY updated_at Asc";
    queryJsonArray("/iot/query", query).then((iotJsonData: Array<Object>) => {
      //let jsonData = new Array<Object>();
      setChartDataSource(iotJsonData);
      console.log(iotJsonData);
    });
  }

  function onChartButtonClicked() {
    if (selectedIotSerial.length == 0) {
      alert("Select row first!", "Error");
      return;
    }

    if (isChartDivHidden) {
      setIsChartDivHidden(false);
      chartSearch(filterFrom, filterTo);
    } else {
      setIsChartDivHidden(true);
    }
  }

  function onFromTextBoxValueChanged(e: string) {
    setFilterFrom(e);
  }
  function onToTextBoxValueChanged(e: string) {
    setFilterTo(e);
  }

  function onDateSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    calculateDateRange(str);
  }
  function calculateDateRange(str: string) {
    let todayStr: string = U.todayEightDigitStringDate();
    setFilterTo(todayStr);
    let todayYMD = U.todayIntArray();
    let moveY: number = 0,
      moveM: number = 0,
      moveD: number = 0;
    if (str === "1주일") moveD = -6;
    if (str === "1개월") {
      moveM = -1;
      moveD = 1;
    }
    if (str === "2개월") {
      moveM = -2;
      moveD = 1;
    }
    if (str === "6개월") {
      moveM = -6;
      moveD = 1;
    }
    if (str === "1년") {
      moveY = -1;
      moveD = 1;
    }
    if (str === "2년") {
      moveY = -2;
      moveD = 1;
    }
    let startYMD = U.findMovedDate(todayYMD[0], todayYMD[1], todayYMD[2], moveY, moveM, moveD);
    let startStr = U.eightDigitStringDateFromIntArray(startYMD[0], startYMD[1], startYMD[2]);
    setFilterFrom(startStr);
    return [startStr, todayStr];
  }

  function onIotDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedIotSerial(e.selectedRowsData[0].serial);
      setSelectedIotRow(e.selectedRowsData[0]);
      setChartTitle(e.selectedRowsData[0].serial + "-" + e.selectedRowsData[0].location);
    }
  }

  function onIotDataGridCellDbClick(e: any) {
    onChartButtonClicked();
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>IoT Dashboard</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
            <div className={"flex-item1"}>
              <Button text="Chart" onClick={onChartButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid
              onCellDblClick={onIotDataGridCellDbClick}
              onSelectionChanged={onIotDataGridSelectionChanged}
              dataSource={iotJsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={false}
            >
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="serial" caption="Serial" />
              <Column dataField="location" caption="Location" />
              <Column dataField="status" caption="Status" />
              <Column dataField="updated_at" caption="Last Update" dataType="datetime" />
              <Column dataField="power" caption="Power (W)" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="serial" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-containerV"} hidden={isChartDivHidden}></div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"} hidden={isChartDivHidden}>
              <TextBox
                placeholder="From (YYYYMMDD)"
                value={filterFrom}
                valueChangeEvent="keyup"
                onValueChange={onFromTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"} hidden={isChartDivHidden}>
              <TextBox
                placeholder="To (YYYYMMDD)"
                value={filterTo}
                valueChangeEvent="keyup"
                onValueChange={onToTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"} hidden={isChartDivHidden}>
              <SelectBox
                dataSource={["1주일", "1개월", "2개월", "6개월", "1년", "2년"]}
                defaultValue={"1주일"}
                width={120}
                height={40}
                onSelectionChanged={onDateSelectionChanged}
              />
            </div>

            <div className={"flex-item1"} hidden={isChartDivHidden}>
              <Button text="Search" onClick={onChartSearchButtonClicked} type="default" icon="download" />
            </div>
          </div>

          <div className={"flex-item1"} hidden={isChartDivHidden}>
            <Chart palette="Violet" dataSource={chartDataSource} title={chartTitle}>
              <CommonSeriesSettings argumentField="updated_at" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series key={"updated_at"} valueField={"power"} name={"Power"} />
              <Margin bottom={20} />
              <ArgumentAxis
                title="Date"
                argumentType={"datetime"}
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              >
                <Label>
                  <Format type="shortDateShortTime" />
                </Label>
              </ArgumentAxis>

              <ValueAxis title={"Power (W)"}>
                <Tick visible={true} />
                <Label visible={true} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494"></Label>
              </Crosshair>
              <Legend visible={false} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
