import React, { useState, useEffect, createContext, useContext, useCallback } from "react";
import { getUser, sendLoginRequest as sendLoginRequest } from "../api/auth";
import type { User, AuthContextType } from "../types";
import defaultUser from "../utils/default-user";
import { queryJsonArray } from "../api/auth";
import { useUserList } from "../contexts/userList";
import * as U from "../utils";

function AuthProvider(props: React.PropsWithChildren<unknown>) {
  const [user, setUser] = useState<User>();
  const userListContext = useUserList();

  useEffect(() => {
    (async function () {
      setUser(defaultUser);
    })();
  }, []);

  const login = useCallback(async (id: string, password: string) => {
    const result = await sendLoginRequest(id, password);
    if (result.isOk) {
      setUser(result.data);
    }
    return result;
  }, []);

  const logout = useCallback(() => {
    setUser(defaultUser);
  }, []);

  return <AuthContext.Provider value={{ user, login, logout }} {...props} />;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
