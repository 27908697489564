import "./progress.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProgress } from "../../contexts/progress";
// api
import { queryJsonArray, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import TextArea from "devextreme-react/text-area";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function PurchaseItem() {
  const { user } = useAuth();
  const progressContext = useProgress();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userDepartment, setUserDepartment] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedNo, setSelectedNo] = useState(-1);
  const [selectedRegistrant, setSelectedRegistrant] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [content, setContent] = useState("");
  const [isContentHidden, setIsContentHidden] = useState(true);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_admin = 0;
    if (user) {
      setUserId(user.id);
      setUserName(user.name);
      setUserDepartment(user.department);
      acc_admin = user.acc_admin;
    }
    // (admin) [8] progress R & W, [9] progress D
    if (U.bitAt(acc_admin, 9)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  ////////////////////////// Search Button
  function onSearchButtonClicked() {
    search();
  }
  function search() {
    setSelectedNo(-1);
    setSelectedRegistrant("");
    setIsContentHidden(true);

    let query = "SELECT * FROM progress";
    query += " ORDER BY date Desc, department Asc";
    queryJsonArray("/progress/query", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function onNewButtonClicked() {
    progressContext.no = "";
    progressContext.registrant = userName;
    progressContext.department = userDepartment;
    progressContext.date = new Date(Date.now());
    navigate("/progress/new");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    if (selectedUserId != userId) {
      alert("Only registrant is allowed to edit!", "Error");
      return;
    }

    progressContext.no = selectedNo.toString();
    progressContext.date = U.dateValueFromJson(selectedRow, "date");
    progressContext.department = userDepartment;
    progressContext.registrant = userName;
    progressContext.content = content;
    navigate("/progress/new");
  }

  function onDeleteButtonClicked() {
    if (selectedNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedRegistrant, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/progress/delete", selectedNo).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedNo(e.selectedRowsData[0].no);
      setSelectedRegistrant(e.selectedRowsData[0].registrant);
      setSelectedUserId(e.selectedRowsData[0].updated_by);
      setSelectedRow(e.selectedRowsData[0]);
      setIsContentHidden(false);
      setContent(e.selectedRowsData[0].content);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>주간 진척도</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" visible={isDeleteButtonVisible} onClick={onDeleteButtonClicked} />
            </div>
          </div>

          <div className={"flex-stretch"}>
            <div className={"flex-item2"} hidden={isContentHidden}>
              <TextArea label="내용" value={content} readOnly={true} autoResizeEnabled={true} maxHeight={800} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="date" caption="날짜" dataType="date" />
              <Column dataField="department" caption="부서" />
              <Column dataField="registrant" caption="등록자" />
              <Column dataField="updated_at" caption="수정일" dataType="datetime" />
              <Column dataField="content" caption="내용" />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="date" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
