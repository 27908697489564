import "./trademark.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { queryJsonArray, downloadAndOpenPdf, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";
import { DataGrid, Column, ColumnFixing, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function Trademark() {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (user) {
  }
  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [disableTitle, setDisableTitle] = useState(true);
  const [disableEvent, setDisableEvent] = useState(true);
  const [filterTitle, setFilterTitle] = useState("");
  const [filterEvent, setFilterEvent] = useState("");
  const [selectedNo, setSelectedNo] = useState(-1);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(true);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
    let acc_admin = 0;
    if (user) {
      setUserId(user.id);
      acc_admin = user.acc_admin;
    }
    // (admin) [13] trademark W & D
    if (U.bitAt(acc_admin, 13)) {
      setIsNewButtonVisible(true);
      setIsDeleteButtonVisible(true);
    } else {
      setIsNewButtonVisible(false);
      setIsDeleteButtonVisible(false);
    }

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  function onTitleCheckBoxValueChanged(e: boolean) {
    setDisableTitle(!e);
  }

  function onTitleTextBoxValueChanged(e: string) {
    setFilterTitle(e);
  }

  function onEventCheckBoxValueChanged(e: boolean) {
    setDisableEvent(!e);
  }

  function onEventTextBoxValueChanged(e: string) {
    setFilterEvent(e);
  }

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setSelectedNo(-1);
    setSelectedTitle("");
    let query = `SELECT * FROM trademark`;
    if (disableTitle === false) {
      query += ` WHERE title = '${filterTitle}'`;
      if (disableEvent === false) {
        query += ` AND eventType = '${filterEvent}'`;
      }
    } else {
      if (disableEvent === false) {
        query += ` WHERE eventType = '${filterEvent}'`;
      }
      query += " ORDER by title asc, country asc, eventDate asc";
    }

    queryJsonArray("/trademark/query", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        // 기존 json row에 몇몇 column을 추가
        let oneRowJson: Object | null = jsonData[i];
        let eventDate: number = U.intValueFromJson(oneRowJson, "eventDate");
        oneRowJson = U.addStringToJson(oneRowJson, "eventDate2", U.stringDateFromEightDigitIntDate(eventDate));
        console.log(eventDate);
        console.log(U.stringDateFromEightDigitIntDate(eventDate));

        if (oneRowJson === null) continue;
        let filingDate: number = U.intValueFromJson(oneRowJson, "filingDate");
        oneRowJson = U.addStringToJson(oneRowJson, "filingDate2", U.stringDateFromEightDigitIntDate(filingDate));
        if (oneRowJson === null) continue;
        let registrationDate: number = U.intValueFromJson(oneRowJson, "registrationDate");
        oneRowJson = U.addStringToJson(oneRowJson, "registrationDate2", U.stringDateFromEightDigitIntDate(registrationDate));
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      setJsonDataSource(newJsonData);
    });
  }

  function onContentButtonClicked() {
    if (selectedNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    if (selectedEvent != "등록") {
      alert("Select 등록 event!", "Error");
      return;
    }
    let fileName = `${selectedNo}.pdf`;
    downloadAndOpenPdf("/trademark/downloadfile", fileName);
  }

  function onNewButtonClicked() {
    navigate("/trademark/new");
  }

  function onDeleteButtonClicked() {
    if (selectedNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedTitle, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/trademark/delete", selectedNo);
        setSelectedNo(-1);
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedNo(e.selectedRowsData[0].no);
      setSelectedTitle(e.selectedRowsData[0].title);
      setSelectedEvent(e.selectedRowsData[0].eventType);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>상표</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <CheckBox onValueChange={onTitleCheckBoxValueChanged} />
            </div>
            <div className={"flex-item1"}>
              <TextBox
                disabled={disableTitle}
                valueChangeEvent="keyup"
                onValueChange={onTitleTextBoxValueChanged}
                width={180}
                height={40}
                placeholder="상표명"
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <CheckBox onValueChange={onEventCheckBoxValueChanged} />
            </div>
            <div className={"flex-item1"}>
              <TextBox
                disabled={disableEvent}
                valueChangeEvent="keyup"
                onValueChange={onEventTextBoxValueChanged}
                width={180}
                height={40}
                placeholder="Event"
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="등록공보" onClick={onContentButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>
          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="title" caption="상표명" fixed={true} />
              <Column dataField="country" caption="국가" />
              <Column dataField="eventType" caption="Event" />
              <Column dataField="eventDate2" caption="Event Date" />
              <Column dataField="applicationNumber" caption="출원번호" />
              <Column dataField="filingDate2" caption="출원일" />
              <Column dataField="registrationNumber" caption="등록번호" />
              <Column dataField="registrationDate2" caption="등록일" />
              <Column dataField="price" caption="비용(원)" dataType="number" format="#,##0" />
              <Column dataField="remark" caption="Remark" />
              <ColumnFixing enabled={true} />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="title" summaryType="count" valueFormat="#,##0" />
                <TotalItem column="price" summaryType="sum" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
