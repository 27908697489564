import { createContext, useContext } from "react";
export type ProjectDocumentContextType = {
  no: number;
  projectNo: number;
  name: string;
  type: string;
  ext: string;
  writer: string;
  approval: string;
  link1: string;
  link2: string;
  link3: string;
  created_at: Date;
  updated_by: string;
  updated_at: Date;
};
const ProjectDocumentContext = createContext<ProjectDocumentContextType>({} as ProjectDocumentContextType);
const useProjectDocument = () => useContext(ProjectDocumentContext);
export { useProjectDocument };
