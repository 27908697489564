import "./fundSetupBatchInput.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { LoadIndicator } from "devextreme-react/load-indicator";
import TextArea from "devextreme-react/text-area";

export default function FundSetupBatchInput() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userId, setUserId] = useState<string>("");
  const [showLoadIndicator, setShowLoadIndicator] = useState(false);
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }
  }, []);

  function onInputTextAreaValueChanged(e: string) {
    setInputText(e);
  }
  function onUploadButtonClicked() {
    setShowLoadIndicator(true);
    // LoadIndicator가 즉각 보이도록 처리하기 위해서 계산량이 많은 다음 과정은 0.5초 후에 시작토록 한다.
    const setIntervalId = setInterval(() => {
      clearInterval(setIntervalId);
      startToAnalyzeText();
    }, 500);
  }

  function startToAnalyzeText() {
    let result = U.decodeFundListInput(inputText);
    // 분석 결과를 message 출력 후 upload를 진행할지 여부 확인 받음
    // return [json, json.length, lossCount, lossId];
    let json = result[0];
    let msg = `${result[1]} rows are processed. Do you want to upload?`;
    let lossCount: number = result[2] as number;
    if (lossCount) {
      msg = `${lossCount} rows including ${result[3]} are not processed. Do you want to upload?`;
    }

    let res = confirm(msg, "Analysis Result");
    res.then(async (dialogResult) => {
      if (dialogResult) {
        // Yes
        await uploadJsonAndAlert("/fund/batchinput", json);
        setShowLoadIndicator(false);
        navigate(-1);
      } else {
        // No
        setShowLoadIndicator(false);
        navigate(-1);
      }
    });
  }
  function onClearButtonClicked() {
    setInputText("");
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>집계항목 입력</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Upload" onClick={onUploadButtonClicked} type="default" icon="upload"></Button>
            </div>
            <div className={"flex-item1"}>
              <Button text="Clear" onClick={onClearButtonClicked} />
            </div>
          </div>
          <div>
            <div className={"flex-containerV"}>
              <LoadIndicator className="button-indicator" visible={showLoadIndicator} height={60} width={60} />
            </div>
            <TextArea height={500} onValueChange={onInputTextAreaValueChanged} value={inputText} placeholder={"년도\t제목\t계정과목List"}></TextArea>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
