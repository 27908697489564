import "./dailyWork.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
import { useDailyWork } from "../../contexts/dailyWork";
// api
import { queryJsonArray } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import PieChart, { Series, Label, Connector, SmallValuesGrouping, Legend, Export } from "devextreme-react/pie-chart";

export default function DailyWork() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userListContext = useUserList();
  const dailyWorkContext = useDailyWork();

  const [userId, setUserId] = useState("");
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [departmentFilterList, setDepartmentFilterList] = useState<Array<string>>();
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [nameFilterList, setNameFilterList] = useState<Array<string>>();
  const [nameFilter, setNameFilter] = useState("");
  const [userIdFilter, setUserIdFilter] = useState("");

  const [dataSource1, setDataSource1] = useState<Array<Object>>();
  const [dataSource2, setDataSource2] = useState<Array<Object>>();
  const [chart1Title, setChart1Title] = useState("");
  const [chart2Title, setChart2Title] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;

      // userList가 이미 다운로드 받은 경우
      initializeQueryList();
      setNameFilter("All");
      setDepartmentFilter("All");
    }
  }, []);

  function initializeQueryList() {
    // userListContext 정보를 분석해서 selectBox의 item list를 초기화한다.
    let l1: Array<string> = ["All"];
    let l2: Array<string> = ["All"];
    for (let i = 0; i < userListContext.names.length; i++) {
      // acc_admin을 검사해서 근태관리 대상인 user에 대해서만 처리한다.
      if (!U.bitAt(userListContext.acc_admins[i], 0)) continue;
      U.addStringArrayIfNotExist(l1, userListContext.departments[i]);
      U.addStringArrayIfNotExist(l2, userListContext.names[i]);
    }
    setDepartmentFilterList(l1);
    setNameFilterList(l2);

    // project, projectTask 정보를 불러온다.
    let query = "SELECT no, name FROM project";
    query += " ORDER BY no Asc";
    queryJsonArray("/project/query", query).then((projectJson: Array<Object>) => {
      let query2 = "SELECT no, name FROM projecttask";
      query2 += " ORDER BY no Asc";
      queryJsonArray("/project/query", query2).then((taskJson: Array<Object>) => {
        dailyWorkContext.projectNos = Array<number>();
        dailyWorkContext.projectNames = Array<string>();
        dailyWorkContext.taskNos = Array<number>();
        dailyWorkContext.taskNames = Array<string>();

        for (let i = 0; i < projectJson.length; i++) {
          dailyWorkContext.projectNos.push(U.intValueFromJson(projectJson[i], "no"));
          dailyWorkContext.projectNames.push(U.stringValueFromJson(projectJson[i], "name"));
        }

        for (let i = 0; i < taskJson.length; i++) {
          dailyWorkContext.taskNos.push(U.intValueFromJson(taskJson[i], "no"));
          dailyWorkContext.taskNames.push(U.stringValueFromJson(taskJson[i], "name"));
        }

        let dateRange = calculateDateRange("1주일");
        if (dateRange) search(dateRange[0], dateRange[1], "All", "", "All");
      });
    });
  }

  function onFromTextBoxValueChanged(e: string) {
    setFilterFrom(e);
  }
  function onToTextBoxValueChanged(e: string) {
    setFilterTo(e);
  }

  function onDateRangeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    calculateDateRange(str);
  }
  function calculateDateRange(str: string) {
    let todayStr: string = U.todayEightDigitStringDate();
    setFilterTo(todayStr);
    let todayYMD = U.todayIntArray();
    let moveY: number = 0,
      moveM: number = 0,
      moveD: number = 0;
    if (str === "1주일") moveD = -6;
    if (str === "1개월") {
      moveM = -1;
      moveD = 1;
    }
    if (str === "2개월") {
      moveM = -2;
      moveD = 1;
    }
    if (str === "6개월") {
      moveM = -6;
      moveD = 1;
    }
    if (str === "1년") {
      moveY = -1;
      moveD = 1;
    }
    if (str === "2년") {
      moveY = -2;
      moveD = 1;
    }
    let startYMD = U.findMovedDate(todayYMD[0], todayYMD[1], todayYMD[2], moveY, moveM, moveD);
    let startStr = U.eightDigitStringDateFromIntArray(startYMD[0], startYMD[1], startYMD[2]);
    setFilterFrom(startStr);
    return [startStr, todayStr];
  }
  function onDepartmentFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setDepartmentFilter(str);
    setNameFilter("All");
    setUserIdFilter("");

    calculateStatistics(str, "", "All");
  }

  function onNameFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setNameFilter(str);
    let userId: string = U.getStringValueFromStringArray(str, userListContext.names, userListContext.ids);
    setUserIdFilter(userId);
    calculateStatistics(departmentFilter, userId, str);
  }

  function onSearchButtonClicked() {
    search(filterFrom, filterTo, departmentFilter, userIdFilter, nameFilter);
  }

  function search(from: string, to: string, departmentToSearch: string, userIdToSearch: string, userNameToSearch: string) {
    if (!U.isProperEightDigitStringDate(from)) {
      alert("Enter from date in proper format!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(to)) {
      alert("Enter to date in proper format!", "Error");
      return;
    }
    let dateFrom: Number = parseInt(from);
    let dateTo: Number = parseInt(to);

    let query = "SELECT * FROM dailywork";
    query += ` WHERE date >= '${dateFrom}'`;
    query += ` AND date <= '${dateTo}'`;
    query += " ORDER BY date Asc";
    queryJsonArray("/dailywork/query", query).then((dailyWorkJson: Array<Object>) => {
      // dailyWorkContext에 query 결과 저장
      dailyWorkContext.userIds = Array<string>();
      dailyWorkContext.dates = Array<number>();
      dailyWorkContext.taskNo1 = Array<number>();
      dailyWorkContext.projectNo1 = Array<number>();
      dailyWorkContext.taskNo2 = Array<number>();
      dailyWorkContext.projectNo2 = Array<number>();
      dailyWorkContext.taskNo3 = Array<number>();
      dailyWorkContext.projectNo3 = Array<number>();
      dailyWorkContext.taskNo4 = Array<number>();
      dailyWorkContext.projectNo4 = Array<number>();
      dailyWorkContext.taskNo5 = Array<number>();
      dailyWorkContext.projectNo5 = Array<number>();
      dailyWorkContext.taskNo6 = Array<number>();
      dailyWorkContext.projectNo6 = Array<number>();
      dailyWorkContext.taskNo7 = Array<number>();
      dailyWorkContext.projectNo7 = Array<number>();
      dailyWorkContext.taskNo8 = Array<number>();
      dailyWorkContext.projectNo8 = Array<number>();
      dailyWorkContext.taskNo9 = Array<number>();
      dailyWorkContext.projectNo9 = Array<number>();
      dailyWorkContext.taskNo10 = Array<number>();
      dailyWorkContext.projectNo10 = Array<number>();

      for (let i = 0; i < dailyWorkJson.length; i++) {
        dailyWorkContext.userIds.push(U.stringValueFromJson(dailyWorkJson[i], "userId"));
        dailyWorkContext.dates.push(U.intValueFromJson(dailyWorkJson[i], "date"));
        dailyWorkContext.taskNo1.push(U.intValueFromJson(dailyWorkJson[i], "taskNo1"));
        dailyWorkContext.projectNo1.push(U.intValueFromJson(dailyWorkJson[i], "projectNo1"));
        dailyWorkContext.taskNo2.push(U.intValueFromJson(dailyWorkJson[i], "taskNo2"));
        dailyWorkContext.projectNo2.push(U.intValueFromJson(dailyWorkJson[i], "projectNo2"));
        dailyWorkContext.taskNo3.push(U.intValueFromJson(dailyWorkJson[i], "taskNo3"));
        dailyWorkContext.projectNo3.push(U.intValueFromJson(dailyWorkJson[i], "projectNo3"));
        dailyWorkContext.taskNo4.push(U.intValueFromJson(dailyWorkJson[i], "taskNo4"));
        dailyWorkContext.projectNo4.push(U.intValueFromJson(dailyWorkJson[i], "projectNo4"));
        dailyWorkContext.taskNo5.push(U.intValueFromJson(dailyWorkJson[i], "taskNo5"));
        dailyWorkContext.projectNo5.push(U.intValueFromJson(dailyWorkJson[i], "projectNo5"));

        dailyWorkContext.taskNo6.push(U.intValueFromJson(dailyWorkJson[i], "taskNo6"));
        dailyWorkContext.projectNo6.push(U.intValueFromJson(dailyWorkJson[i], "projectNo6"));
        dailyWorkContext.taskNo7.push(U.intValueFromJson(dailyWorkJson[i], "taskNo7"));
        dailyWorkContext.projectNo7.push(U.intValueFromJson(dailyWorkJson[i], "projectNo7"));
        dailyWorkContext.taskNo8.push(U.intValueFromJson(dailyWorkJson[i], "taskNo8"));
        dailyWorkContext.projectNo8.push(U.intValueFromJson(dailyWorkJson[i], "projectNo8"));
        dailyWorkContext.taskNo9.push(U.intValueFromJson(dailyWorkJson[i], "taskNo9"));
        dailyWorkContext.projectNo9.push(U.intValueFromJson(dailyWorkJson[i], "projectNo9"));
        dailyWorkContext.taskNo10.push(U.intValueFromJson(dailyWorkJson[i], "taskNo10"));
        dailyWorkContext.projectNo10.push(U.intValueFromJson(dailyWorkJson[i], "projectNo10"));
      }

      if (userNameToSearch.length > 0) calculateStatistics(departmentToSearch, userIdToSearch, userNameToSearch);
    });
  }

  function calculateStatistics(departmentToSearch: string, userIdToSearch: string, userNameToSearch: string) {
    // project, projecttask, dailywork에 대해 미리 검색해 둔 결과를 dailyWorkContext의 값을 바탕으로 주어진 사용자에 대해 통계 계산해서 화면에 표시해준다.
    let projectList = Array<number>();
    let taskList = Array<number>();
    let targetInTitle: string = "";
    let dailyWorkSum: number = 0; // 표시되는 합산 근무시간 (days 단위)
    if (userNameToSearch === "All") {
      if (departmentToSearch === "All") targetInTitle = "All";
      else targetInTitle = departmentToSearch;
    } else {
      targetInTitle = userNameToSearch;
    }
    for (let i = 0; i < dailyWorkContext.userIds.length; i++) {
      // dailyWork 각각의 기록에 대해 filter 설정에 따라 skip 여부를 체크한다.
      let userId = dailyWorkContext.userIds[i];
      if (userNameToSearch === "All") {
        // userNameToSearch == "All"인 경우 departmentToSearch에 대해서 체크한다.
        if (departmentToSearch !== "All") {
          let userDepartment: string = U.getStringValueFromStringArray(userId, userListContext.ids, userListContext.departments);
          if (userDepartment !== departmentToSearch) continue;
        }
      } else {
        // userNameToSearch에 특정 사용자가 선택된 경우에는 departmentToSearch는 고려하지 않는다.
        if (userId !== userIdToSearch) continue;
      }
      ++dailyWorkSum;

      projectList.push(dailyWorkContext.projectNo1[i]);
      projectList.push(dailyWorkContext.projectNo2[i]);
      projectList.push(dailyWorkContext.projectNo3[i]);
      projectList.push(dailyWorkContext.projectNo4[i]);
      projectList.push(dailyWorkContext.projectNo5[i]);
      projectList.push(dailyWorkContext.projectNo6[i]);
      projectList.push(dailyWorkContext.projectNo7[i]);
      projectList.push(dailyWorkContext.projectNo8[i]);
      projectList.push(dailyWorkContext.projectNo9[i]);
      projectList.push(dailyWorkContext.projectNo10[i]);

      taskList.push(dailyWorkContext.taskNo1[i]);
      taskList.push(dailyWorkContext.taskNo2[i]);
      taskList.push(dailyWorkContext.taskNo3[i]);
      taskList.push(dailyWorkContext.taskNo4[i]);
      taskList.push(dailyWorkContext.taskNo5[i]);
      taskList.push(dailyWorkContext.taskNo6[i]);
      taskList.push(dailyWorkContext.taskNo7[i]);
      taskList.push(dailyWorkContext.taskNo8[i]);
      taskList.push(dailyWorkContext.taskNo9[i]);
      taskList.push(dailyWorkContext.taskNo10[i]);
    }

    let projectResult = U.calculateRepeatDensity(projectList); // returned: [numberList, repeatList]
    let taskResult = U.calculateRepeatDensity(taskList);
    let projectNos: Array<number> = projectResult[0];
    let projectDays: Array<number> = projectResult[1]; // 10% 단위 (10이 1 day에 해당)
    let taskNos: Array<number> = taskResult[0];
    let taskDays: Array<number> = taskResult[1]; // 10% 단위 (10이 1 day에 해당)

    // projectName, taskName 검색
    let projectNames: Array<string> = Array<string>();
    for (let i = 0; i < projectNos.length; i++) {
      let projectName: string = U.getStringValueFromNumberArray(projectNos[i], dailyWorkContext.projectNos, dailyWorkContext.projectNames);
      projectNames.push(projectName);
    }
    let taskNames: Array<string> = Array<string>();
    for (let i = 0; i < taskNos.length; i++) {
      let taskName: string = U.getStringValueFromNumberArray(taskNos[i], dailyWorkContext.taskNos, dailyWorkContext.taskNames);
      taskNames.push(taskName);
    }

    // Pie Chart
    // work hour를 percent로 환산해서 pie chart의 dataSource로 입력
    setChart1Title(`Project (${targetInTitle}, ${dailyWorkSum} days)`);
    let newJsonData = new Array<Object>();
    let daysSum: number = 0;
    for (let i = 0; i < projectDays.length; i++) daysSum += projectDays[i];
    if (daysSum > 0) {
      for (let i = 0; i < projectNames.length; i++) {
        let oneRowJson: Object | null = new Object();
        oneRowJson = U.addStringToJson(oneRowJson, "title", projectNames[i]);
        oneRowJson = U.addNumberToJson(oneRowJson, "percent", (100.0 * projectDays[i]) / daysSum);
        if (oneRowJson === null) continue;
        newJsonData.push(oneRowJson);
      }
    } else setChart1Title(`No data!  (${targetInTitle})`);
    setDataSource1(newJsonData);

    setChart2Title(`Task (${targetInTitle}, ${dailyWorkSum} days)`);
    newJsonData = new Array<Object>();
    daysSum = 0;
    for (let i = 0; i < taskDays.length; i++) daysSum += taskDays[i];
    if (daysSum > 0) {
      for (let i = 0; i < taskNames.length; i++) {
        let oneRowJson: Object | null = new Object();
        oneRowJson = U.addStringToJson(oneRowJson, "title", taskNames[i]);
        oneRowJson = U.addNumberToJson(oneRowJson, "percent", (100.0 * taskDays[i]) / daysSum);
        if (oneRowJson === null) continue;
        newJsonData.push(oneRowJson);
      }
    } else setChart2Title(`No data!  (${targetInTitle})`);
    setDataSource2(newJsonData);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>개인별 업무시간2</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                placeholder="From (YYYYMMDD)"
                value={filterFrom}
                valueChangeEvent="keyup"
                onValueChange={onFromTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <TextBox
                placeholder="To (YYYYMMDD)"
                value={filterTo}
                valueChangeEvent="keyup"
                onValueChange={onToTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <SelectBox
                dataSource={["1주일", "1개월", "2개월", "6개월", "1년", "2년"]}
                defaultValue={"1주일"}
                width={120}
                height={40}
                onSelectionChanged={onDateRangeSelectionChanged}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <SelectBox
                label="부서"
                dataSource={departmentFilterList}
                value={departmentFilter}
                width={180}
                height={40}
                onSelectionChanged={onDepartmentFilterSelectionChanged}
              />
            </div>
            <div className={"flex-item1"}>
              <SelectBox
                label="이름"
                dataSource={nameFilterList}
                value={nameFilter}
                width={180}
                height={40}
                onSelectionChanged={onNameFilterSelectionChanged}
              />
            </div>
          </div>

          <div className={"flex-item1"}>
            <PieChart id="pie" dataSource={dataSource1} palette="Bright" title={chart1Title}>
              <Series argumentField="title" valueField="percent">
                <Label visible={true} customizeText={formatLabel} format="fixedPoint">
                  <Connector visible={true} width={0.5} />
                </Label>
                <SmallValuesGrouping threshold={4.5} mode="smallValueThreshold" />
              </Series>
              <Legend horizontalAlignment="center" verticalAlignment="bottom" />
              <Export enabled={true} />
            </PieChart>
          </div>

          <div className={"flex-item1"}>
            <PieChart id="pie" dataSource={dataSource2} palette="Bright" title={chart2Title}>
              <Series argumentField="title" valueField="percent">
                <Label visible={true} customizeText={formatLabel} format="fixedPoint">
                  <Connector visible={true} width={0.5} />
                </Label>
                <SmallValuesGrouping threshold={4.5} mode="smallValueThreshold" />
              </Series>
              <Legend horizontalAlignment="center" verticalAlignment="bottom" />
              <Export enabled={true} />
            </PieChart>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function formatLabel(arg: any) {
  return `${arg.argumentText}: ${arg.valueText}%`;
}
