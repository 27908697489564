import "./stockPricePredict.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUsage } from "../../contexts/usage";
// api
import { queryJsonArray } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";

export default function UsageNew() {
  const { user } = useAuth();
  const usage = useUsage();
  const navigate = useNavigate();

  const [date1, setDate1] = useState("");
  const [stockPrice1, setStockPrice1] = useState("");
  const [tradeStock1, setTradeStock1] = useState("");
  const [date2, setDate2] = useState("");
  const [stockPrice2, setStockPrice2] = useState("");
  const [tradeStock2, setTradeStock2] = useState("");
  const [date3, setDate3] = useState("");
  const [stockPrice3, setStockPrice3] = useState("");
  const [tradeStock3, setTradeStock3] = useState("");

  const [contractDate, setContractDate] = useState("");
  const [cbPrice, setCbPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [before1Day, setBefore1Day] = useState("");
  const [before3Day, setBefore3Day] = useState("");
  const [before1Week, setBefore1Week] = useState("");
  const [before1Month, setBefore1Month] = useState("");
  const [before1Month1Week1Day, setBefore1Month1Week1Day] = useState("");

  useEffect(() => {
    let userId: string = "";
    let userName: string = "";
    if (user) {
      userId = user.id;
      userName = user.name;
    }
    initialize();
  }, []);

  function onDate1TextBoxValueChanged(e: string) {
    setDate1(e);
  }
  function onStockPrice1TextBoxValueChanged(e: string) {
    setStockPrice1(e);
  }
  function onTradeStock1TextBoxValueChanged(e: string) {
    setTradeStock1(e);
  }
  function onDate2TextBoxValueChanged(e: string) {
    setDate2(e);
  }
  function onStockPrice2TextBoxValueChanged(e: string) {
    setStockPrice2(e);
  }
  function onTradeStock2TextBoxValueChanged(e: string) {
    setTradeStock2(e);
  }
  function onDate3TextBoxValueChanged(e: string) {
    setDate3(e);
  }
  function onStockPrice3TextBoxValueChanged(e: string) {
    setStockPrice3(e);
  }
  function onTradeStock3TextBoxValueChanged(e: string) {
    setTradeStock3(e);
  }
  function onContractDateTextBoxValueChanged(e: string) {
    setContractDate(e);
  }

  function initialize() {
    let todayInt: number = U.todayEightDigitIntDate();
    let todayStr: string = U.todayEightDigitStringDate();
    let todayYMD = U.todayIntArray();
    let date3Int: number = U.afterWorkingDateEightDigitIntDate(todayInt, 1);
    let date2Int: number = U.afterWorkingDateEightDigitIntDate(todayInt, 2);
    let date1Int: number = U.afterWorkingDateEightDigitIntDate(todayInt, 3);
    let contractDateInt: number = U.afterWorkingDateEightDigitIntDate(todayInt, 4);
    setDate3(date3Int.toString());
    setDate2(date2Int.toString());
    setDate1(date1Int.toString());
    setContractDate(contractDateInt.toString());
  }

  //////////////// Calculate Button
  async function onCalculateButtonClicked() {
    let date3N: number = parseInt(date3);
    if (Number.isNaN(date3N)) {
      alert("전3거래일에 숫자를 입력하세요!", "Error");
      return;
    }
    let stockPrice3N: number = parseInt(stockPrice3);
    if (Number.isNaN(stockPrice3N)) {
      alert("전3거래일 주가에 숫자를 입력하세요!", "Error");
      return;
    }
    let tradeStock3N: number = parseInt(tradeStock3);
    if (Number.isNaN(tradeStock3N)) {
      alert("전3거래일 거래주식수에 숫자를 입력하세요!", "Error");
      return;
    }

    let date2N: number = parseInt(date2);
    if (Number.isNaN(date2N)) {
      alert("전2거래일에 숫자를 입력하세요!", "Error");
      return;
    }
    let stockPrice2N: number = parseInt(stockPrice2);
    if (Number.isNaN(stockPrice2N)) {
      alert("전2거래일 주가에 숫자를 입력하세요!", "Error");
      return;
    }
    let tradeStock2N: number = parseInt(tradeStock2);
    if (Number.isNaN(tradeStock2N)) {
      alert("전2거래일 거래주식수에 숫자를 입력하세요!", "Error");
      return;
    }

    let date1N: number = parseInt(date1);
    if (Number.isNaN(date1N)) {
      alert("전1거래일에 숫자를 입력하세요!", "Error");
      return;
    }
    let stockPrice1N: number = parseInt(stockPrice1);
    if (Number.isNaN(stockPrice1N)) {
      alert("전1거래일 주가에 숫자를 입력하세요!", "Error");
      return;
    }
    let tradeStock1N: number = parseInt(tradeStock1);
    if (Number.isNaN(tradeStock1N)) {
      alert("전1거래일 거래주식수에 숫자를 입력하세요!", "Error");
      return;
    }

    let todayYMD = U.todayIntArray();
    let todayStr = U.eightDigitStringDateFromIntArray(todayYMD[0], todayYMD[1], todayYMD[2]);
    let startYMD = U.findMovedDate(todayYMD[0], todayYMD[1], todayYMD[2], 0, -2, 0);
    let startStr = U.eightDigitStringDateFromIntArray(startYMD[0], startYMD[1], startYMD[2]);

    let gisanil: number = date1N;
    let gisanilList = U.intListDateFromEightCharacterInt(gisanil);
    if (!gisanilList) {
      return;
    }
    let before1WeekDateList = U.findMovedDate(gisanilList[0], gisanilList[1], gisanilList[2], 0, 0, -6);
    if (!before1WeekDateList) {
      return;
    }
    let before1WeekDate = U.eightDigitIntDateFromIntArray(before1WeekDateList[0], before1WeekDateList[1], before1WeekDateList[2]);
    let before1MonthDateList = U.findMovedDate(gisanilList[0], gisanilList[1], gisanilList[2], 0, -1, 1);
    if (!before1MonthDateList) {
      return;
    }
    let before1MonthDate = U.eightDigitIntDateFromIntArray(before1MonthDateList[0], before1MonthDateList[1], before1MonthDateList[2]);

    let query = "SELECT * FROM stock";
    query += ` WHERE date >= '${startStr}'`;
    query += ` AND date <= '${todayStr}'`;
    query += " ORDER BY date Desc";
    console.log(query);
    queryJsonArray("/stock/query", query).then((stockJsonData: Array<Object>) => {
      // 내림차순 (DESC) 정렬이므로 [0]이 가장 나중 날짜로 기준일의 직전 영업일, 즉 1 거래일전의 날로 기산일에 해당한다.
      let weekStockSum = 0;
      let weekPriceSum = 0;
      let monthStockSum = 0;
      let monthPriceSum = 0;
      for (let i = 0; i < stockJsonData.length; i++) {
        let oneRowJson: Object | null = stockJsonData[i];
        let date: number = U.intValueFromJson(oneRowJson, "date");
        let tradeTotalPrice: number = U.intValueFromJson(oneRowJson, "tradeTotalPrice");
        let tradeStock: number = U.intValueFromJson(oneRowJson, "tradeStock");
        if (date === date1N) continue;
        if (date === date2N) continue;
        if (date === date3N) continue;
        if (before1MonthDate <= date && date <= gisanil) {
          monthStockSum += tradeStock;
          monthPriceSum += tradeTotalPrice;
        }
        if (before1WeekDate <= date && date <= gisanil) {
          weekStockSum += tradeStock;
          weekPriceSum += tradeTotalPrice;
        }
      } // End of for (stockJsonData)

      // 기산일, 2거래일, 3거래일 주가도 합산 계산한다.
      if (before1MonthDate <= date1N && date1N <= gisanil) {
        monthStockSum += tradeStock1N;
        monthPriceSum += stockPrice1N * tradeStock1N;
      }
      if (before1MonthDate <= date2N && date2N <= gisanil) {
        monthStockSum += tradeStock2N;
        monthPriceSum += stockPrice2N * tradeStock2N;
      }
      if (before1MonthDate <= date3N && date3N <= gisanil) {
        monthStockSum += tradeStock3N;
        monthPriceSum += stockPrice3N * tradeStock3N;
      }

      if (before1WeekDate <= date1N && date1N <= gisanil) {
        weekStockSum += tradeStock1N;
        weekPriceSum += stockPrice1N * tradeStock1N;
      }
      if (before1WeekDate <= date2N && date2N <= gisanil) {
        weekStockSum += tradeStock2N;
        weekPriceSum += stockPrice2N * tradeStock2N;
      }
      if (before1WeekDate <= date3N && date3N <= gisanil) {
        weekStockSum += tradeStock3N;
        weekPriceSum += stockPrice3N * tradeStock3N;
      }

      let weekMean: number = 0.0;
      if (weekStockSum !== 0) {
        weekMean = weekPriceSum / weekStockSum;
      }
      let monthMean: number = 0.0;
      if (monthStockSum !== 0) {
        monthMean = monthPriceSum / monthStockSum;
      }
      let monthWeek1DayMean: number = (monthMean + weekMean + stockPrice1N) / 3.0;

      let cb: number = monthWeek1DayMean;
      if (cb < stockPrice1N) cb = stockPrice1N;
      if (cb < stockPrice3N) cb = stockPrice3N;

      let discount: number = cb * 0.9;

      setBefore1Day(stockPrice1N.toString());
      setBefore3Day(stockPrice3N.toString());
      setBefore1Week(weekMean.toFixed(0));
      setBefore1Month(monthMean.toFixed(0));
      setBefore1Month1Week1Day(monthWeek1DayMean.toFixed(0));
      setCbPrice(cb.toFixed(0));
      setDiscountPrice(discount.toFixed(0));
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>3일 후 전환사채 가격 예측</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <TextBox label="전3거래일" value={date3} width={200} readOnly={true} />
              </div>
              <div className={"flex-item4"}>
                <TextBox
                  label="주가"
                  value={stockPrice3}
                  valueChangeEvent="keyup"
                  onValueChange={onStockPrice3TextBoxValueChanged}
                  width={200}
                  showClearButton={true}
                />
              </div>
              <div className={"flex-item4"}>
                <TextBox
                  label="거래주식수"
                  value={tradeStock3}
                  valueChangeEvent="keyup"
                  onValueChange={onTradeStock3TextBoxValueChanged}
                  width={200}
                  showClearButton={true}
                />
              </div>
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <TextBox label="전2거래일" value={date2} width={200} readOnly={true} />
              </div>
              <div className={"flex-item4"}>
                <TextBox
                  label="주가"
                  value={stockPrice2}
                  valueChangeEvent="keyup"
                  onValueChange={onStockPrice2TextBoxValueChanged}
                  width={200}
                  showClearButton={true}
                />
              </div>
              <div className={"flex-item4"}>
                <TextBox
                  label="거래주식수"
                  value={tradeStock2}
                  valueChangeEvent="keyup"
                  onValueChange={onTradeStock2TextBoxValueChanged}
                  width={200}
                  showClearButton={true}
                />
              </div>
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <TextBox label="전1거래일" value={date1} width={200} readOnly={true} />
              </div>
              <div className={"flex-item4"}>
                <TextBox
                  label="주가"
                  value={stockPrice1}
                  valueChangeEvent="keyup"
                  onValueChange={onStockPrice1TextBoxValueChanged}
                  width={200}
                  showClearButton={true}
                />
              </div>
              <div className={"flex-item4"}>
                <TextBox
                  label="거래주식수"
                  value={tradeStock1}
                  valueChangeEvent="keyup"
                  onValueChange={onTradeStock1TextBoxValueChanged}
                  width={200}
                  showClearButton={true}
                />
              </div>
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Calculate" onClick={onCalculateButtonClicked} type="default" />
              </div>
            </div>
          </div>

          <div>
            <div className={"flex-item2"}>
              <TextBox label="청약일" readOnly={true} value={contractDate} width={620} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="전환사채" readOnly={true} value={cbPrice} width={620} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="10% 할인가" readOnly={true} value={discountPrice} width={620} />
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item2"}>
                <TextBox label="최근일" readOnly={true} value={before1Day} width={300} />
              </div>

              <div className={"flex-item2"}>
                <TextBox label="전 3거래일" readOnly={true} value={before3Day} width={300} />
              </div>
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item2"}>
                <TextBox label="일주일 가중평균" readOnly={true} value={before1Week} width={300} />
              </div>

              <div className={"flex-item2"}>
                <TextBox label="한달 가중평균" readOnly={true} value={before1Month} width={300} />
              </div>
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item2"}>
                <TextBox label="최근일, 1주, 1달 평균" readOnly={true} value={before1Month1Week1Day} width={300} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
