import {
  HomePage,
  AttendancePage,
  AttendanceVacationPage,
  AttendanceVacationNewPage,
  AttendanceDashboardPage,
  ComplianceNewPage,
  ComplianceSearchPage,
  GeneralDocumentPage,
  GeneralDocumentNewPage,
  IotDashboardPage,
  IotEditPage,
  EducationPage,
  EducationNewPage,
  PatentPage,
  PatentNewPage,
  TrademarkPage,
  TrademarkNewPage,
  CertificatePage,
  CertificateNewPage,
  ShareholderPage,
  ShareholderBatchInputPage,
  StockPricePage,
  StockPricePredictPage,
  DbUsagePage,
  FundSlipPage,
  FundSlipBatchInputPage,
  FundSetupPage,
  FundSetupBatchInputPage,
  FundStatusPage,
  FundPlanPage,
  ProjectDashboardPage,
  ProjectMeetingPage,
  ProjectNewPage,
  ProjectDescriptionPage,
  ProjectTaskNewPage,
  ProjectMeetingNewPage,
  ProjectDocumentNewPage,
  DailyWorkPage,
  MeetingPage,
  MeetingNewPage,
  ProgressPage,
  ProgressNewPage,
  PurchaseItemPage,
  PurchaseItemNewPage,
  PurchaseRequestPage,
  PurchaseRequestNewPage,
  InventoryPage,
  UsagePage,
  UsageNewPage,
  CsPartPage,
  CsPartNewPage,
  CsPartUsagePage,
  CsPartUsageNewPage,
  CtPartUsagePage,
  CtPartUsageNewPage,
  CsBomPage,
  CtPartPage,
  CtPartNewPage,
  ProfilePage,
} from "./pages";
import { withNavigationWatcher } from "./contexts/navigation";

const routes = [
  {
    path: "/home",
    element: HomePage,
  },
  // 인사총괄
  {
    path: "/attendance/dashboard",
    element: AttendanceDashboardPage,
  },
  {
    path: "/attendance",
    element: AttendancePage,
  },
  {
    path: "/attendance/vacation",
    element: AttendanceVacationPage,
  },
  {
    path: "/attendance/vacation/new",
    element: AttendanceVacationNewPage,
  },
  {
    path: "/compliance/new",
    element: ComplianceNewPage,
  },
  {
    path: "/compliance/search",
    element: ComplianceSearchPage,
  },
  {
    path: "/general/document",
    element: GeneralDocumentPage,
  },
  {
    path: "/general/document/new",
    element: GeneralDocumentNewPage,
  },
  {
    path: "/iot/dashboard",
    element: IotDashboardPage,
  },
  {
    path: "/iot/edit",
    element: IotEditPage,
  },
  {
    path: "/education",
    element: EducationPage,
  },
  {
    path: "/education/new",
    element: EducationNewPage,
  },
  {
    path: "/patent",
    element: PatentPage,
  },
  {
    path: "/patent/new",
    element: PatentNewPage,
  },
  {
    path: "/trademark",
    element: TrademarkPage,
  },
  {
    path: "/trademark/new",
    element: TrademarkNewPage,
  },
  {
    path: "/certificate",
    element: CertificatePage,
  },
  {
    path: "/certificate/new",
    element: CertificateNewPage,
  },
  {
    path: "/shareholder",
    element: ShareholderPage,
  },
  {
    path: "/shareholder/batchInput",
    element: ShareholderBatchInputPage,
  },
  {
    path: "/stockPrice",
    element: StockPricePage,
  },
  {
    path: "/stockPrice/predict",
    element: StockPricePredictPage,
  },
  {
    path: "/dbUsage",
    element: DbUsagePage,
  },
  // 법인자금
  {
    path: "/fund/slip",
    element: FundSlipPage,
  },
  {
    path: "/fund/slipBatchInput",
    element: FundSlipBatchInputPage,
  },
  {
    path: "/fund/setup",
    element: FundSetupPage,
  },
  {
    path: "/fund/setupBatchInput",
    element: FundSetupBatchInputPage,
  },
  {
    path: "/fund/status",
    element: FundStatusPage,
  },
  {
    path: "/fund/plan",
    element: FundPlanPage,
  },

  // 보고
  {
    path: "/project/dashboard",
    element: ProjectDashboardPage,
  },
  {
    path: "/project/meeting",
    element: ProjectMeetingPage,
  },
  {
    path: "/project/new",
    element: ProjectNewPage,
  },
  {
    path: "/project/description",
    element: ProjectDescriptionPage,
  },
  {
    path: "/project/task/new",
    element: ProjectTaskNewPage,
  },
  {
    path: "/project/meeting/new",
    element: ProjectMeetingNewPage,
  },
  {
    path: "/project/document/new",
    element: ProjectDocumentNewPage,
  },
  {
    path: "/dailyWork",
    element: DailyWorkPage,
  },
  {
    path: "/meeting",
    element: MeetingPage,
  },
  {
    path: "/meeting/new",
    element: MeetingNewPage,
  },
  {
    path: "/progress",
    element: ProgressPage,
  },
  {
    path: "/progress/new",
    element: ProgressNewPage,
  },

  // 바이오물품
  {
    path: "/purchase/item",
    element: PurchaseItemPage,
  },
  {
    path: "/purchase/newItem",
    element: PurchaseItemNewPage,
  },
  {
    path: "/purchase/request",
    element: PurchaseRequestPage,
  },
  {
    path: "/purchase/newRequest",
    element: PurchaseRequestNewPage,
  },
  {
    path: "/purchase/inventory",
    element: InventoryPage,
  },
  {
    path: "/purchase/newUsage",
    element: UsageNewPage,
  },
  {
    path: "/purchase/usage",
    element: UsagePage,
  },

  // 셀샷 생산관리
  {
    path: "/cs/part",
    element: CsPartPage,
  },
  {
    path: "/cs/newPart",
    element: CsPartNewPage,
  },
  {
    path: "/cs/partUsage",
    element: CsPartUsagePage,
  },
  {
    path: "/cs/partNewUsage",
    element: CsPartUsageNewPage,
  },
  {
    path: "/cs/bom",
    element: CsBomPage,
  },
  // 카트리지 생산관리
  {
    path: "/ct/part",
    element: CtPartPage,
  },
  {
    path: "/ct/newPart",
    element: CtPartNewPage,
  },
  {
    path: "/ct/partUsage",
    element: CtPartUsagePage,
  },
  {
    path: "/ct/partNewUsage",
    element: CtPartUsageNewPage,
  },

  // Profile
  {
    path: "/profile",
    element: ProfilePage,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
