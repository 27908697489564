import "./educationNew.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// context
import { useAuth } from "../../contexts/auth";
// api
import { uploadEducationWithFile } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";

export default function EducationNew() {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (user) {
  }
  useEffect(() => {}, []);
  const [file, setFile] = useState<File>();
  const [title, setTitle] = useState("");
  const [target, setTarget] = useState("");
  const [cycle, setCycle] = useState("");

  function onTitleTextBoxValueChanged(e: string) {
    setTitle(e);
  }
  function onTargetTextBoxValueChanged(e: string) {
    setTarget(e);
  }
  function onCycleTextBoxValueChanged(e: string) {
    setCycle(e);
  }

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      let f = e.target.files[0];
      setFile(f);
    }
  };

  function onUploadButtonClicked() {
    if (!file) {
      alert("Select file first!", "Error");
      return;
    }
    if (!title || !target || !cycle) {
      alert("Fill in inputs!", "Error");
      return;
    }
    uploadEducationWithFile(title, target, cycle, file);
    navigate(-1);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>교육자료 등록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerV"}>
            <div className={"flex-item2"}>
              <TextBox
                valueChangeEvent="keyup"
                onValueChange={onTitleTextBoxValueChanged}
                width={400}
                height={40}
                placeholder="교육명"
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                valueChangeEvent="keyup"
                onValueChange={onTargetTextBoxValueChanged}
                width={400}
                height={40}
                placeholder="대상자"
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                valueChangeEvent="keyup"
                onValueChange={onCycleTextBoxValueChanged}
                width={400}
                height={40}
                placeholder="교육주기"
                showClearButton={true}
              />
            </div>
            <div className={"flex-item3"}>
              <input type="file" accept=".pdf" onChange={onChangeFile} />
            </div>
            <div className={"flex-item3"}>
              <Button text="Upload" onClick={onUploadButtonClicked} type="default" icon="upload" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
