import "./inventory.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUsage } from "../../contexts/usage";
// api
import { queryJsonArray, resetUsageByIdAndAlert, updateNoteByIdAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { CheckBox } from "devextreme-react/check-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function Inventory() {
  const { user } = useAuth();
  const usage = useUsage();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedId, setSelectedId] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  useState(false);
  const [isUsageVisible, setIsUsageVisible] = useState(false);
  const [isResetVisible, setIsResetVisible] = useState(false);
  const [includeDisabled, setIncludeDisabled] = useState(false);
  const [isNoteGroupVisible, setIsNoteGroupVisible] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [noteId, setNoteId] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
    let acc_purch = 0;
    if (user) {
      setUserId(user.id);
      setUserName(user.name);
      acc_purch = user.acc_purch;
    }
    // (purch) [7] inventory R, [8] usage R, [9] W, [10] D & reset
    if (U.bitAt(acc_purch, 9)) setIsUsageVisible(true);
    else setIsUsageVisible(false);
    if (U.bitAt(acc_purch, 10)) setIsResetVisible(true);
    else setIsResetVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search(false);
    }
  }, []);

  /////////////////////////////// Search Button
  function onSearchButtonClicked() {
    search(includeDisabled);
  }
  function search(includeD: boolean) {
    setSelectedId("");
    setSelectedRow(undefined);
    setIsNoteGroupVisible(false);
    let query = `SELECT * FROM purchaseitem`;
    if (includeD === false) {
      query += " WHERE isDisabled = 0";
    }

    queryJsonArray("/inventory/queryitem", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function copyToUsageContext(obj: Object) {
    usage.date = U.todayEightDigitStringDate();
    usage.purpose = "";
    usage.registrant = userName;
    usage.itemId = U.stringValueFromJson(obj, "id");
    usage.itemName = U.stringValueFromJson(obj, "name");
    usage.category = U.stringValueFromJson(obj, "category");
    usage.purchaseNo = U.stringValueFromJson(obj, "no");
    usage.usageUnit = U.stringValueFromJson(obj, "usageUnit");
    usage.usageUnitPrice = U.stringValueFromJson(obj, "unitPrice");
  }
  function onUseButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToUsageContext(selectedRow);
    usage.type = "사용";
    usage.orderDate = "";
    navigate("/purchase/newUsage");
  }

  function onDestroyButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToUsageContext(selectedRow);
    usage.type = "폐기";
    usage.orderDate = "";
    navigate("/purchase/newUsage");
  }

  function onCheckButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToUsageContext(selectedRow);
    usage.type = "실재고 확인";
    usage.orderDate = "";
    navigate("/purchase/newUsage");
  }

  function onResetButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }

    let res = confirm("안전재고수량과 월간사용량을 초기화하시겠습니까?", selectedId);
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        resetUsageByIdAndAlert("/inventory/resetusage", selectedId).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }
  function onNoteButtonClicked() {
    if (isNoteGroupVisible) {
      setIsNoteGroupVisible(false);
      return;
    }
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    setIsNoteGroupVisible(true);
    let name: string = U.stringValueFromJson(selectedRow, "name");
    setNoteTitle(name);
    let note: string = U.stringValueFromJson(selectedRow, "note");
    setNoteContent(note);
    setNoteId(selectedId);
  }

  function onIncludeDisabledCheckBoxValueChanged(e: boolean) {
    setIncludeDisabled(e);
    search(e);
  }

  function onNoteTitleTextBoxValueChanged(e: string) {
    setNoteTitle(e);
  }
  function onNoteContentTextAreaValueChanged(e: string) {
    setNoteContent(e);
  }

  function onUpdateNoteButtonClicked() {
    updateNoteByIdAndAlert("/inventory/editinventorynote", noteId, noteContent).then(() => {
      onSearchButtonClicked();
    });
  }

  function onDataGridSelectionChanged(e: any) {
    setIsNoteGroupVisible(false);
    if (e.selectedRowsData.length != 0) {
      setSelectedId(e.selectedRowsData[0].id);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>재고현황</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
            <div className={"flex-item1"}>
              <Button text="사용" visible={isUsageVisible} onClick={onUseButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="폐기" visible={isUsageVisible} onClick={onDestroyButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="실재고 확인" visible={isUsageVisible} onClick={onCheckButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="사용량 리셋" visible={isResetVisible} onClick={onResetButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Note" onClick={onNoteButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <CheckBox text="비사용 품목 포함" value={includeDisabled} onValueChange={onIncludeDisabledCheckBoxValueChanged} />
            </div>
          </div>

          <div>
            <div className={"flex-item2"}>
              <TextBox
                label="품명"
                visible={isNoteGroupVisible}
                readOnly={true}
                placeholder="품명"
                value={noteTitle}
                onValueChange={onNoteTitleTextBoxValueChanged}
                valueChangeEvent="keyup"
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextArea
                label="Note"
                placeholder="Note"
                visible={isNoteGroupVisible}
                onValueChange={onNoteContentTextAreaValueChanged}
                value={noteContent}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <Button text="Update Note" visible={isNoteGroupVisible} onClick={onUpdateNoteButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="id" caption="허가품목코드" />
              <Column dataField="name" caption="품명" />
              <Column dataField="quantity" caption="재고수량" dataType="number" format="#,##0" />
              <Column dataField="usageUnit" caption="재고단위" />
              <Column dataField="safetyQuantity" caption="안전재고수량" dataType="number" format="#,##0" />
              <Column dataField="leadTime" caption="안전재고기간 (일)" dataType="number" format="#,##0" />

              <Column dataField="monthlyUsage" caption="월간사용량" dataType="number" format="#,##0" />
              <Column dataField="manager" caption="관리자" />
              <Column dataField="category" caption="분류" />
              <Column dataField="note" caption="Note" />
              <Column dataField="site" caption="사이트" />
              <Column dataField="storage" caption="보관 위치" />
              <Column dataField="catNo" caption="Cat. No." />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="id" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
