import * as U from ".";

export const calculateRepeatDensity = (
  // int 형태의 list 안에서 같은 숫자가 반복되는 갯수를 세서 가장 많이 반복되는 숫자부터 정렬해서
  // return [numberList, repeatList] 로 원본 숫자와 반복 횟수를 반환한다.
  srcList: Array<number>
) => {
  let numberList = Array<number>();
  let repeatList = Array<number>();
  if (!srcList) return [numberList, repeatList];
  if (srcList.length === 0) return [numberList, repeatList];
  for (let i = 0; i < srcList.length; i++) {
    let n: number = srcList[i];
    let index: number = indexInNumberList(n, numberList);
    if (index < 0) {
      numberList.push(n);
      repeatList.push(1);
    } else {
      repeatList[index] += 1;
    }
  }

  // sorting
  let result = descendingSortByX(repeatList, numberList);
  let sortedRepeatList: Array<number> = result[0];
  let sortedNumberList: Array<number> = result[1];
  return [sortedNumberList, sortedRepeatList];
};

export const indexInNumberList = (
  // valueList 안에 valueToFind가 존재하는지 체크해서 존재하면 index를 반환하고 없으면 -1을 반환한다.
  valueToFind: number,
  valueList: Array<number>
) => {
  if (!valueList) return -1;
  if (valueList.length === 0) return -1;
  for (let i = 0; i < valueList.length; i++) {
    if (valueList[i] === valueToFind) return i;
  }
  return -1;
};

export const descendingSortByX = (
  // xList의 값을 기준으로 descending (내림차순) sorting 해서 그 결과를 return [sortedXList, sortedYList] 한다.
  xList: Array<number>,
  yList: Array<number>
) => {
  let sortedXList = Array<number>();
  let sortedYList = Array<number>();
  if (!xList) return [sortedXList, sortedYList];
  if (!yList) return [sortedXList, sortedYList];
  if (xList.length !== yList.length) return [sortedXList, sortedYList];
  let xPool = Array<number>();
  let yPool = Array<number>();
  for (let i = 0; i < xList.length; i++) {
    xPool.push(xList[i]);
    yPool.push(yList[i]);
  }
  while (xPool.length > 0) {
    let maxX: number = xPool[0];
    let maxIndex: number = 0;
    for (let i = 1; i < xPool.length; i++) {
      if (maxX < xPool[i]) {
        maxX = xPool[i];
        maxIndex = i;
      }
    }
    sortedXList.push(xPool[maxIndex]);
    sortedYList.push(yPool[maxIndex]);

    // xPool, yPool에서 maxIndex item을 삭제
    let xPool2 = Array<number>();
    let yPool2 = Array<number>();
    for (let i = 0; i < xPool.length; i++) {
      if (i === maxIndex) continue;
      xPool2.push(xPool[i]);
      yPool2.push(yPool[i]);
    }

    xPool = Array<number>();
    yPool = Array<number>();
    for (let i = 0; i < xPool2.length; i++) {
      xPool.push(xPool2[i]);
      yPool.push(yPool2[i]);
    }
  }

  return [sortedXList, sortedYList];
};

export const calculateStringRepeatDensity = (
  // string 형태의 list 안에서 같은 string이 반복되는 갯수를 세서 가장 많이 반복되는 숫자부터 정렬해서
  // return [stringList, repeatList] 로 원본 숫자와 반복 횟수를 반환한다.
  srcList: Array<string>
) => {
  let stringList = Array<string>();
  let repeatList = Array<number>();
  if (!srcList) return [stringList, repeatList];
  if (srcList.length === 0) return [stringList, repeatList];
  for (let i = 0; i < srcList.length; i++) {
    let s: string = srcList[i];
    let index: number = indexInStringList(s, stringList);
    if (index < 0) {
      stringList.push(s);
      repeatList.push(1);
    } else {
      repeatList[index] += 1;
    }
  }

  // sorting
  let result = descendingSortStringByX(repeatList, stringList);
  let sortedRepeatList = result[0];
  let sortedStringList = result[1];
  return [sortedStringList, sortedRepeatList];
};

export const indexInStringList = (
  // valueList 안에 valueToFind가 존재하는지 체크해서 존재하면 index를 반환하고 없으면 -1을 반환한다.
  valueToFind: string,
  valueList: Array<string>
) => {
  if (!valueList) return -1;
  if (valueList.length === 0) return -1;
  for (let i = 0; i < valueList.length; i++) {
    if (valueList[i] === valueToFind) return i;
  }
  return -1;
};

export const descendingSortStringByX = (
  // xList의 값을 기준으로 descending (내림차순) sorting 해서 그 결과를 return [sortedXList, sortedYList] 한다.
  xList: Array<number>,
  yList: Array<string>
) => {
  let sortedXList = Array<number>();
  let sortedYList = Array<string>();
  if (!xList) return [sortedXList, sortedYList];
  if (!yList) return [sortedXList, sortedYList];
  if (xList.length !== yList.length) return [sortedXList, sortedYList];
  let xPool = Array<number>();
  let yPool = Array<string>();
  for (let i = 0; i < xList.length; i++) {
    xPool.push(xList[i]);
    yPool.push(yList[i]);
  }
  while (xPool.length > 0) {
    let maxX: number = xPool[0];
    let maxIndex: number = 0;
    for (let i = 1; i < xPool.length; i++) {
      if (maxX < xPool[i]) {
        maxX = xPool[i];
        maxIndex = i;
      }
    }
    sortedXList.push(xPool[maxIndex]);
    sortedYList.push(yPool[maxIndex]);

    // xPool, yPool에서 maxIndex item을 삭제
    let xPool2 = Array<number>();
    let yPool2 = Array<string>();
    for (let i = 0; i < xPool.length; i++) {
      if (i === maxIndex) continue;
      xPool2.push(xPool[i]);
      yPool2.push(yPool[i]);
    }

    xPool = Array<number>();
    yPool = Array<string>();
    for (let i = 0; i < xPool2.length; i++) {
      xPool.push(xPool2[i]);
      yPool.push(yPool2[i]);
    }
  }

  return [sortedXList, sortedYList];
};
