import "./home.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";

export default function Home() {
  const { user } = useAuth();

  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
  }, []);

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Home</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>Thanks for using FemtoDB.</p>
        </div>

        <div className="widget-container"></div>
      </div>
    </React.Fragment>
  );
}
