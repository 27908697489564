import React from "react";
import { HashRouter as Router } from "react-router-dom";
import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import { LoadPanel } from "devextreme-react/tree-list";
import "./dx-styles.scss";
import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import UnauthenticatedContent from "./UnauthenticatedContent";

function App() {
  const { user } = useAuth();

  if (user) {
    if (user.id.length > 0) {
      return <Content />;
    }
  }
  return (
    <>
      <UnauthenticatedContent />
      <LoadPanel enabled={true} />
    </>
  );
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
