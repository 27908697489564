import { createContext, useContext } from "react";
export type UserListContextType = {
  isInitialized: boolean; // 초기값은 undefined
  ids: Array<string>;
  names: Array<string>;
  locations: Array<string>;
  departments: Array<string>;
  acc_admins: Array<number>;
  joiningDates: Array<number>;
};
const UserListContext = createContext<UserListContextType>({} as UserListContextType);
const useUserList = () => useContext(UserListContext);
export { useUserList };
