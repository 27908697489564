import "./stockPrice.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { queryJsonArray, downloadAndOpenPdf } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, ColumnFixing, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";
import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  Tick,
  CommonAxisSettings,
  CommonSeriesSettings,
  Export as ExportChart,
  Legend,
  Margin,
  Label,
  Format,
  Crosshair,
  Tooltip,
  Grid,
} from "devextreme-react/chart";

export default function StockPrice() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [chartTitle, setChartTitle] = useState("");
  const [yAxisTitle, setYAxisTitle] = useState("금액 (원)");
  const [series0Title, setSeries0Title] = useState("");
  const [yAxisFormat, setYAxisFormat] = useState("#,##0");
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_purch = 0;
    if (user) {
      setUserId(user.id);
      acc_purch = user.acc_purch;
    }
    // (purch) [11] slip R, [12] slip input & D, [13] fundList setup, [14] fund status
    if (U.bitAt(acc_purch, 12)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      let dateRange = calculateDateRange("1개월");
      if (dateRange) search(dateRange[0], dateRange[1]);
    }
  }, []);

  function onFromTextBoxValueChanged(e: string) {
    setFilterFrom(e);
  }
  function onToTextBoxValueChanged(e: string) {
    setFilterTo(e);
  }

  function onDateSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    calculateDateRange(str);
  }
  function calculateDateRange(str: string) {
    let todayStr: string = U.todayEightDigitStringDate();
    setFilterTo(todayStr);
    let todayYMD = U.todayIntArray();
    let moveY: number = 0,
      moveM: number = 0,
      moveD: number = 0;
    if (str === "1주일") moveD = -6;
    if (str === "1개월") {
      moveM = -1;
      moveD = 1;
    }
    if (str === "2개월") {
      moveM = -2;
      moveD = 1;
    }
    if (str === "6개월") {
      moveM = -6;
      moveD = 1;
    }
    if (str === "1년") {
      moveY = -1;
      moveD = 1;
    }
    if (str === "2년") {
      moveY = -2;
      moveD = 1;
    }
    let startYMD = U.findMovedDate(todayYMD[0], todayYMD[1], todayYMD[2], moveY, moveM, moveD);
    let startStr = U.eightDigitStringDateFromIntArray(startYMD[0], startYMD[1], startYMD[2]);
    setFilterFrom(startStr);
    return [startStr, todayStr];
  }

  function onChartTypeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setChartTitle(str);
    let title: string = "endPrice";
    let format: string = "#,##0";
    setYAxisTitle("금액 (원)");
    if (str === "종가") title = "endPrice";
    if (str === "가중평균가") title = "weightedMeanPrice";
    if (str === "거래주식수") {
      title = "tradeStock";
      setYAxisTitle("주식수");
    }
    if (str === "거래액") {
      title = "tradeTotalPrice";
      format = "";
    }
    if (str === "시가총액") {
      title = "totalPrice";
      format = "";
    }
    if (str === "스톡옵션가") {
      title = "stockOptionPrice";
      alert(
        "행사가격은 자본시장과 금융투자업에 관한 법률 시행령 제176조의7 제3항 제1호 규정을 준용하여 주식매수선택권 부여일 전일부터 과거 1주일, 1개월, 2개월간 각 거래량을 가중치로 하여 가중평균한 가격의 산술평균가격 (원단위이하 절삭)",
        "스톡옵션가"
      );
    }
    if (str === "증발공기준가1") {
      title = "newStockPrice1";
      alert(
        "증발공 규정 5-18조, 발행가액은 청약일전 과거 제3거래일부터 제5거래일까지의 가중산술평균주가를 기준주가로 하여 주권상장법인이 정하는 할인율을 적용하여 산정한다.",
        "증발공기준가1"
      );
    }
    if (str === "증발공기준가2") {
      title = "newStockPrice2";
      alert(
        "증발공 규정 5-18조, 이사회결의일 전일을 기산일로 하여 과거 1개월간의 가중산술평균주가, 1주일간의 가중산술평균주가 및 최근일 가중산술평균주가를 산술평균한 가격과 최근일 가중산술평균주가 중 낮은 가격을 기준주가로 하여 주권상장법인이 정하는 할인율을 적용하여 산정할 수 있다.",
        "증발공기준가2"
      );
    }
    if (str === "전환사채") {
      title = "cbPrice";
      alert(
        "증발공 규정 5-22조, 이사회결의일 전일을 기산일로 하여 다음 각 호의 가액 중 높은 가액 이상으로 한다. 1. 1개월 가중산술평균주가, 1주일 가중산술평균주가 및 최근일 가중산술평균주가를 산술평균한 가액, 2. 최근일 가중산술평균주가, 3. 청약일전 제3거래일 가중산술평균주가",
        "전환사채"
      );
    }
    setSeries0Title(title);
    setYAxisFormat(format);
  }

  function onSearchButtonClicked() {
    search(filterFrom, filterTo);
  }

  function onReferenceButtonClicked() {
    let fileName = "1.pdf";
    downloadAndOpenPdf("/etc/downloadfile", fileName);
  }

  function onPredictButtonClicked() {
    navigate("/stockPrice/predict");
  }

  function search(from: string, to: string) {
    if (!U.isProperEightDigitStringDate(from)) {
      alert("Enter from date in proper format!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(to)) {
      alert("Enter to date in proper format!", "Error");
      return;
    }
    let dateFrom: Number = parseInt(from);
    let dateTo: Number = parseInt(to);

    let query = "SELECT * FROM stock";
    query += ` WHERE date >= '${dateFrom}'`;
    query += ` AND date <= '${dateTo}'`;
    query += " ORDER BY date Desc";
    queryJsonArray("/stock/query", query).then((stockJsonData: Array<Object>) => {
      //let query2 =
      //"SELECT date, stockOptionPrice, newStockPrice1, newStockPrice2, cbPrice, before1DayTo1Month, before1DayTo1Week, before1Day FROM stockanalysis";
      let query2 =
        "SELECT date, stockOptionPrice, newStockPrice1, newStockPrice2, cbPrice, before1DayTo1Month, before1DayTo1Week, before1Day, before3Day, before3DayTo5Day FROM stockanalysis";
      query2 += ` WHERE date >= '${dateFrom}'`;
      query2 += ` AND date <= '${dateTo}'`;
      query2 += " ORDER BY date Desc";
      queryJsonArray("/stock/query", query2).then((stockAnalysisJsonData: Array<Object>) => {
        let jsonData = new Array<Object>();
        // iteration for stockanalysis data
        for (let i = 0; i < stockAnalysisJsonData.length; i++) {
          let oneRowJson: Object | null = stockAnalysisJsonData[i];
          let date1: number = U.intValueFromJson(oneRowJson, "date");
          oneRowJson = U.addDateFromEightDigitIntToJson(oneRowJson, "date2", date1); // chart의 x축 값으로 Date 형식으로 입력
          let newStockPrice1: number = 0;
          let newStockPrice2: number = 0;
          let cbPrice: number = 0;
          let cb1: number = 0; // 전환사채 기준가 3가지 중 1번째 (1달, 1주, 최근일의 평균)
          let before1DayTo1Month: number = 0;
          let before1DayTo1Week: number = 0;
          let before1Day: number = 0;
          if (oneRowJson !== null) {
            newStockPrice1 = U.floatValueFromJson(oneRowJson, "newStockPrice1");
            newStockPrice2 = U.floatValueFromJson(oneRowJson, "newStockPrice2");
            cbPrice = U.floatValueFromJson(oneRowJson, "cbPrice");
            before1DayTo1Month = U.floatValueFromJson(oneRowJson, "before1DayTo1Month");
            before1DayTo1Week = U.floatValueFromJson(oneRowJson, "before1DayTo1Week");
            before1Day = U.floatValueFromJson(oneRowJson, "before1Day");
            cb1 = (before1DayTo1Month + before1DayTo1Week + before1Day) / 3.0;
          }
          // 10% 할인가 추가
          let newStockPrice10percent1: number = newStockPrice1 * 0.9;
          let newStockPrice10percent2: number = newStockPrice2 * 0.9;
          let cbPrice10percent: number = cbPrice * 0.9;
          oneRowJson = U.addNumberToJson(oneRowJson, "newStockPrice10percent1", newStockPrice10percent1);
          oneRowJson = U.addNumberToJson(oneRowJson, "newStockPrice10percent2", newStockPrice10percent2);
          oneRowJson = U.addNumberToJson(oneRowJson, "cbPrice10percent", cbPrice10percent);
          oneRowJson = U.addNumberToJson(oneRowJson, "cb1", cb1);

          //console.log(oneRowJson);
          let searched: boolean = false;
          // iteration for stock data
          for (let j = 0; j < stockJsonData.length; j++) {
            let oneRowJson2: Object = stockJsonData[j];
            let date2: number = U.intValueFromJson(oneRowJson2, "date");
            if (date1 === date2) {
              // stock analysis에 있는 날짜와 같은 날짜의 stock data를 추가
              let startPrice: number = U.intValueFromJson(oneRowJson2, "startPrice");
              let obj: Object | null = U.addNumberToJson(oneRowJson, "startPrice", startPrice);
              if (obj === null) continue;

              let endPrice: number = U.intValueFromJson(oneRowJson2, "endPrice");
              obj = U.addNumberToJson(obj, "endPrice", endPrice);
              if (obj === null) continue;

              let highPrice: number = U.intValueFromJson(oneRowJson2, "highPrice");
              obj = U.addNumberToJson(obj, "highPrice", highPrice);
              if (obj === null) continue;

              let lowPrice: number = U.intValueFromJson(oneRowJson2, "lowPrice");
              obj = U.addNumberToJson(obj, "lowPrice", lowPrice);
              if (obj === null) continue;

              let weightedMeanPrice: number = U.floatValueFromJson(oneRowJson2, "weightedMeanPrice");
              obj = U.addNumberToJson(obj, "weightedMeanPrice", weightedMeanPrice);
              if (obj === null) continue;
              //console.log(weightedMeanPrice);

              let tradeStock: number = U.intValueFromJson(oneRowJson2, "tradeStock");
              obj = U.addNumberToJson(obj, "tradeStock", tradeStock);
              if (obj === null) continue;

              let tradeTotalPrice: number = U.intValueFromJson(oneRowJson2, "tradeTotalPrice");
              obj = U.addNumberToJson(obj, "tradeTotalPrice", tradeTotalPrice);
              if (obj === null) continue;

              let totalStock: number = U.intValueFromJson(oneRowJson2, "totalStock");
              obj = U.addNumberToJson(obj, "totalStock", totalStock);
              if (obj === null) continue;

              let totalPrice: number = U.intValueFromJson(oneRowJson2, "totalPrice");
              obj = U.addNumberToJson(obj, "totalPrice", totalPrice);
              if (obj === null) continue;

              //console.log(obj);

              jsonData.push(obj);
              searched = true;
              break;
            }
          }
          if (!searched) {
            // stockanalysis 에 같은 날짜의 row가 없거나 오류가 생겨서 jsonData에 추가 못했다면
            if (oneRowJson !== null) jsonData.push(oneRowJson);
          }
        }
        setJsonDataSource(jsonData);
      });
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>주가 조회</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                placeholder="From (YYYYMMDD)"
                value={filterFrom}
                valueChangeEvent="keyup"
                onValueChange={onFromTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <TextBox
                placeholder="To (YYYYMMDD)"
                value={filterTo}
                valueChangeEvent="keyup"
                onValueChange={onToTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <SelectBox
                dataSource={["1주일", "1개월", "2개월", "6개월", "1년", "2년"]}
                defaultValue={"1개월"}
                width={120}
                height={40}
                onSelectionChanged={onDateSelectionChanged}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
            <div className={"flex-item1"}>
              <Button text="증발공 법령 (제5-18조)" onClick={onReferenceButtonClicked} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <SelectBox
                dataSource={["종가", "가중평균가", "거래주식수", "거래액", "시가총액", "스톡옵션가", "증발공기준가1", "증발공기준가2", "전환사채"]}
                defaultValue={"종가"}
                width={180}
                height={40}
                onSelectionChanged={onChartTypeSelectionChanged}
              />
            </div>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={jsonDataSource} title={chartTitle}>
              <CommonSeriesSettings argumentField="date2" type="line" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series key={series0Title} valueField={series0Title} name={chartTitle} />
              <Margin bottom={20} />
              <ArgumentAxis
                title="날짜 (청약일)"
                argumentType={"datetime"}
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
              >
                <Label>
                  <Format type="shortDate" />
                </Label>
              </ArgumentAxis>

              <ValueAxis title={yAxisTitle}>
                <Tick visible={true} />
                <Label visible={true} format={yAxisFormat} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494"></Label>
              </Crosshair>
              <Legend visible={false} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div>
            <DataGrid dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="date2" caption="날짜 (청약일)" dataType="date" fixed={true} />
              <Column dataField="endPrice" caption="종가" dataType="number" format="#,##0" />
              <Column dataField="tradeStock" caption="거래주식수" dataType="number" format="#,##0" />
              <Column dataField="tradeTotalPrice" caption="거래액" dataType="number" format="#,##0" />
              <Column dataField="startPrice" caption="시가" dataType="number" format="#,##0" />
              <Column dataField="highPrice" caption="고가" dataType="number" format="#,##0" />
              <Column dataField="lowPrice" caption="저가" dataType="number" format="#,##0" />
              <Column dataField="totalPrice" caption="시가총액" dataType="number" format="#,##0" />
              <Column dataField="totalStock" caption="총주식수" dataType="number" format="#,##0" />
              <Column dataField="weightedMeanPrice" caption="가중평균가" dataType="number" format="#,##0.#" />
              <Column dataField="stockOptionPrice" caption="스톡옵션가" dataType="number" format="#,##0" />
              <Column dataField="newStockPrice1" caption="증발공기준가1" dataType="number" format="#,##0.#" />
              <Column dataField="newStockPrice10percent1" caption="증발공1 (10%할인 적용)" dataType="number" format="#,##0.#" />
              <Column dataField="newStockPrice2" caption="증발공기준가2" dataType="number" format="#,##0.#" />
              <Column dataField="newStockPrice10percent2" caption="증발공2 (10%할인 적용)" dataType="number" format="#,##0.#" />
              <Column dataField="cbPrice" caption="전환사채" dataType="number" format="#,##0.#" />
              <Column dataField="cb1" caption="CB-1" dataType="number" format="#,##0.#" />
              <Column dataField="cbPrice10percent" caption="전환사채 (10%할인 적용)" dataType="number" format="#,##0.#" />
              <Column dataField="before1Day" caption="before1Day" dataType="number" format="#,##0.#" />
              <Column dataField="before3Day" caption="before3Day" dataType="number" format="#,##0.#" />
              <Column dataField="before3DayTo5Day" caption="before3DayTo5Day" dataType="number" format="#,##0.#" />
              <Column dataField="before1DayTo1Week" caption="before1DayTo1Week" dataType="number" format="#,##0.#" />
              <Column dataField="before1DayTo1Month" caption="before1DayTo1Month" dataType="number" format="#,##0.#" />
              <ColumnFixing enabled={true} />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="date" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
