import "./generalDocument.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
import { useGeneralDocument } from "../../contexts/generalDocument";
// api
import { uploadJson, queryJsonArray, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";

export default function GeneralDocument() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userListContext = useUserList();
  const generalDocumentContext = useGeneralDocument();

  const [isNewDocumentButtonVisible, setIsNewDocumentButtonVisible] = useState(false);
  const [isDeleteDocumentButtonVisible, setIsDeleteDocumentButtonVisible] = useState(false);

  const [documentJsonDataSource, setDocumentJsonDataSource] = useState<Array<Object>>();
  const [selectedDocumentNo, setSelectedDocumentNo] = useState(-1);
  const [selectedDocumentName, setSelectedDocumentName] = useState("");
  const [selectedDocumentRow, setSelectedDocumentRow] = useState<Object>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_admin: number = 0;
    let userName: string = "";
    if (user) {
      acc_admin = user.acc_admin;
      userName = user.name;
    }
    // (admin) [17] general R, [18] W [19] D
    // new, edit 권한
    let isEditable: boolean = false;
    if (U.bitAt(acc_admin, 18)) {
      isEditable = true;
    }
    if (isEditable) {
      setIsNewDocumentButtonVisible(true);
    }
    // delete 권한
    let isDeletable: boolean = false;
    if (U.bitAt(acc_admin, 19)) {
      isDeletable = true;
    }
    if (isDeletable) {
      setIsDeleteDocumentButtonVisible(true);
    }
    searchDocument();
  }, []);

  function onSearchDocumentButtonClicked() {
    searchDocument();

    //////////////////////////////////////////////
    let date = new Date();
    let year: number = date.getFullYear();
    let month: number = date.getMonth() + 1; // getMonth(): zero-based value (즉 1월이 0)
    let day: number = date.getDate(); // getDay()는 그 week의 몇쩃날인지 표현, 즉 요일
    let hours: number = date.getHours(); // getDay()는 그 week의 몇쩃날인지 표현, 즉 요일
    console.log(date);
    console.log(year, month, day, hours);
  }

  function searchDocument() {
    let query = "SELECT * FROM generaldocument";
    query += " ORDER BY name Asc";
    queryJsonArray("/general/query", query).then((documentJson: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < documentJson.length; i++) {
        let oneRowJson: Object | null = documentJson[i];
        let created_at: Date = U.dateValueFromJson(oneRowJson, "created_at");
        oneRowJson = U.addStringToJson(oneRowJson, "created_at2", U.stringDateFromDate(created_at));
        if (oneRowJson === null) continue;
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      setDocumentJsonDataSource(newJsonData);
    });
  }

  function onNewDocumentButtonClicked() {
    generalDocumentContext.no = -1;
    navigate("/general/document/new");
  }

  function copyRowToGeneralDocumentContext(row: Object) {
    generalDocumentContext.no = U.intValueFromJson(row, "no");
    generalDocumentContext.name = U.stringValueFromJson(row, "name");
    generalDocumentContext.ext = U.stringValueFromJson(row, "ext");
    generalDocumentContext.writer = U.stringValueFromJson(row, "writer");
    generalDocumentContext.link1 = U.stringValueFromJson(row, "link1");
    generalDocumentContext.link2 = U.stringValueFromJson(row, "link2");
    generalDocumentContext.link3 = U.stringValueFromJson(row, "link3");
    generalDocumentContext.created_at = U.dateValueFromJson(row, "created_at");
    generalDocumentContext.updated_by = U.stringValueFromJson(row, "updated_by");
    generalDocumentContext.updated_at = U.dateValueFromJson(row, "updated_at");
  }

  function onEditDocumentButtonClicked() {
    if (!selectedDocumentRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyRowToGeneralDocumentContext(selectedDocumentRow);
    navigate("/general/document/new");
  }
  function onDeleteDocumentButtonClicked() {
    if (selectedDocumentNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedDocumentName, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/general/deletedocument", selectedDocumentNo).then(() => {
          searchDocument();
        });
      } else {
        // No
      }
    });
  }

  function onFileInfoButtonClicked() {
    if (!selectedDocumentRow) {
      alert("Select row first!", "Error");
      return;
    }
    let link1: string = U.stringValueFromJson(selectedDocumentRow, "link1");
    window.open(link1);
  }
  function onOpenReadOnlyButtonClicked() {
    if (!selectedDocumentRow) {
      alert("Select row first!", "Error");
      return;
    }
    let link2: string = U.stringValueFromJson(selectedDocumentRow, "link2");
    window.open(link2);
  }
  function onOpenWritableButtonClicked() {
    if (!selectedDocumentRow) {
      alert("Select row first!", "Error");
      return;
    }
    let link3: string = U.stringValueFromJson(selectedDocumentRow, "link3");
    window.open(link3);
  }

  function onDocumentDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedDocumentNo(e.selectedRowsData[0].no);
      setSelectedDocumentName(e.selectedRowsData[0].name);
      setSelectedDocumentRow(e.selectedRowsData[0]);
    }
  }

  function onDocumentDataGridCellDbClick(e: any) {
    let link3: string = U.stringValueFromJson(e.row.data, "link3");
    window.open(link3);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>일반관리</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerTitle"}></div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchDocumentButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="읽기 모드로 열기" onClick={onOpenReadOnlyButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="쓰기 모드로 열기" onClick={onOpenWritableButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="File Info" onClick={onFileInfoButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" visible={isNewDocumentButtonVisible} onClick={onNewDocumentButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Edit" visible={isNewDocumentButtonVisible} onClick={onEditDocumentButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" visible={isDeleteDocumentButtonVisible} onClick={onDeleteDocumentButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid
              onCellDblClick={onDocumentDataGridCellDbClick}
              onSelectionChanged={onDocumentDataGridSelectionChanged}
              dataSource={documentJsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={false}
            >
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="name" caption="Document 이름" />
              <Column dataField="created_at2" caption="등록일" />
              <Column dataField="writer" caption="작성자" />
              <Column dataField="ext" caption="파일종류" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="name" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
