import "./projectMeetingNew.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProjectMeeting } from "../../contexts/projectMeeting";
// api
import { uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";

export default function ProjectMeetingNew() {
  const { user } = useAuth();
  const projectMeetingContext = useProjectMeeting();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("회의록");
  const [no, setNo] = useState(-1);
  const [projectNo, setProjectNo] = useState(-1);

  const [date, setDate] = useState<Date>();
  const [name, setName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [registrant, setRegistrant] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    setProjectNo(projectMeetingContext.projectNo);
    setProjectName(projectMeetingContext.projectName);
    setRegistrant(projectMeetingContext.registrant);
    setPageTitle("신규 회의록");
    if (projectMeetingContext.no >= 0) {
      // edit 버튼으로 진입한 경우: context 변수로부터 element에 복사
      setPageTitle("회의록 편집");
      setNo(projectMeetingContext.no);

      setName(projectMeetingContext.name);
      if (U.isProperEightDigitIntDate(projectMeetingContext.date)) setDate(U.startLocalDateFromEigitDigitIntDate(projectMeetingContext.date));
      setProjectName(projectMeetingContext.projectName);
      setContent(projectMeetingContext.content);
    }
  }, []);

  function onDateDateBoxValueChanged(e: any) {
    setDate(e);
  }
  function onNameTextBoxValueChanged(e: string) {
    setName(e);
  }
  function onContentTextAreaValueChanged(e: string) {
    setContent(e);
  }

  ///////////////////////////// Upload Button
  async function onUploadButtonClicked() {
    // DateBox를 선택하지 않으면 초기값으로 undefined로 표시됨.
    if (!date) {
      alert("일자를 입력하세요!", "Error");
      return;
    }
    if (name.length === 0) {
      alert("회의록 제목을 입력하세요!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let dateN: number = U.eightDigitIntDateFromDate(date);

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let name2: string = U.replaceBrace(name);
    let projectName2: string = U.replaceBrace(projectName);
    let content2: string = U.replaceBrace(content);

    let obj: Object = {
      no: no,
      projectNo: projectNo,
      date: dateN,
      name: name2,
      projectName: projectName2,
      registrant: registrant,
      content: content2,
    };

    await uploadJson("/project/uploadmeeting", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        alert("같은 제목의 회의록이 이미 존재합니다!", "Error");
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <DateBox label="일자" type="date" value={date} valueChangeEvent="change" onValueChange={onDateDateBoxValueChanged} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="제목" value={name} valueChangeEvent="keyup" onValueChange={onNameTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Project 이름" readOnly={true} value={projectName} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="등록자" readOnly={true} value={registrant} width={300} showClearButton={true} />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"}>
              <TextArea
                label="내용"
                onValueChange={onContentTextAreaValueChanged}
                value={content}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
