import "./fundSetup.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { queryJsonArray, deleteRowById, deleteRowByYear, uploadNewFundList, updateFundList, swapTurnOfTwoRows, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function FundSetup() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [filterYear, setFilterYear] = useState("");
  const [titleText, setTitleText] = useState("");
  const [accountsText, setAccountsText] = useState("");
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [selectedTurn, setSelectedTurn] = useState<number>(0);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedAccounts, setSelectedAccounts] = useState("");
  const [isRecalculateVisible, setIsRecalculateVisible] = useState(false);

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }
  }, []);

  function onYearTextBoxValueChanged(e: string) {
    setFilterYear(e);
  }
  function onTitleTextBoxValueChanged(e: string) {
    setTitleText(e);
  }
  function onAccountsTextAreaValueChanged(e: string) {
    setAccountsText(e);
  }

  function onSearchButtonClicked() {
    setIsEditVisible(false);
    setSelectedId("");
    setSelectedTurn(0);
    if (!U.isProperFourDigitStringYear(filterYear)) {
      alert("Enter proper year!", "Error");
      return;
    }
    let year = parseInt(filterYear);

    let query = "SELECT * FROM fundlist";
    query += ` WHERE year = ${filterYear}`;
    query += " ORDER BY year Asc, turn Asc";
    queryJsonArray("/fund/query", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
    setIsRecalculateVisible(true);
  }
  async function onUpButtonClicked() {
    setIsEditVisible(false);
    if (selectedId.length === 0) {
      alert("Select row!", "Error");
      return;
    }
    let year: number = selectedYear;
    let turn: number = selectedTurn;
    if (turn === 1) {
      alert("Already top!", "Error");
      return;
    }
    let turnToSwap: number = turn - 1;
    await swapTurnOfTwoRows(year, turn, turnToSwap);
    onSearchButtonClicked();
  }
  async function onDownButtonClicked() {
    setIsEditVisible(false);
    if (selectedId.length === 0) {
      alert("Select row!", "Error");
      return;
    }
    let year: number = selectedYear;
    let turn: number = selectedTurn;
    let turnToSwap: number = turn + 1;
    await swapTurnOfTwoRows(year, turn, turnToSwap);
    onSearchButtonClicked();
  }
  async function onDeleteButtonClicked() {
    if (selectedId.length === 0) {
      alert("Select row!", "Error");
      return;
    }
    let res = confirm(selectedTitle, "Delete?");
    res.then(async (dialogResult) => {
      if (dialogResult) {
        // Yes
        await deleteRowById("/fund/delete", selectedId);
        onSearchButtonClicked();
      } else {
        // No
      }
    });
  }

  async function onDeleteAllButtonClicked() {
    if (!U.isProperFourDigitStringYear(filterYear)) {
      alert("Enter proper year!", "Error");
      return;
    }
    let nYear = parseInt(filterYear);
    let res = confirm(`${nYear}`, "Delete all?");
    res.then(async (dialogResult) => {
      if (dialogResult) {
        // Yes
        await deleteRowByYear("/fund/deleteyear", nYear);
        onSearchButtonClicked();
      } else {
        // No
      }
    });
  }

  function onNewButtonClicked() {
    setIsEditVisible(!isEditVisible);
    setSelectedId("");
    setTitleText("");
    setAccountsText("");
  }
  function onEditButtonClicked() {
    setIsEditVisible(!isEditVisible);
    setTitleText(selectedTitle);
    setAccountsText(selectedAccounts);
  }
  ////////////////// Upload Button
  async function onUploadButtonClicked() {
    if (!U.isProperFourDigitStringYear(filterYear)) {
      alert("Enter proper year!", "Error");
      return;
    }
    let year: number = parseInt(filterYear);
    let turn: number = selectedTurn;
    if (titleText.length === 0) {
      alert("Enter title!", "Error");
      return;
    }
    if (accountsText.length === 0) {
      alert("Enter accounts!", "Error");
      return;
    }

    if (selectedId.length === 0) {
      // new
      let newTurn = turn;
      if (turn !== 0) newTurn = turn + 1; // selectedRow 아래로 들어가도록
      await uploadNewFundList(year, newTurn, titleText, accountsText);
    } else {
      // edit
      await updateFundList(selectedId, titleText, accountsText);
    }

    setTitleText("");
    setAccountsText("");
    setIsEditVisible(false);
    onSearchButtonClicked();
  }

  function onDataGridSelectionChanged(e: any) {
    setIsEditVisible(false);
    if (e.selectedRowsData.length != 0) {
      setSelectedId(e.selectedRowsData[0].id);
      setSelectedYear(e.selectedRowsData[0].year);
      setSelectedTurn(e.selectedRowsData[0].turn);
      setSelectedTitle(e.selectedRowsData[0].title);
      setSelectedAccounts(e.selectedRowsData[0].accounts);
    }
  }

  async function onRecalculateButtonClicked() {
    if (!U.isProperFourDigitStringYear(filterYear)) {
      alert("Enter proper year!", "Error");
      return;
    }
    let year = parseInt(filterYear);

    if (!jsonDataSource) {
      alert("Search list first!", "Error");
      return;
    }
    let dateFrom: number = year * 10000 + 1 * 100 + 1;
    let dateTo: number = year * 10000 + 12 * 100 + 31;
    let query = "SELECT * FROM slip";
    query += ` WHERE date >= '${dateFrom}'`;
    query += ` AND date <= '${dateTo}'`;
    query += " ORDER BY date Asc, resolutionNo Asc, turn Asc";
    await queryJsonArray("/fund/queryslip", query).then((jsonData: Array<Object>) => {
      if (jsonData) {
        // Analyze Slip and FundList data
        let json = U.analyzeSlipAndFundList(jsonDataSource, jsonData);
        uploadJsonAndAlert("/fund/updatecalculationresult", json);
      } else {
        alert("Enter slip first!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>집계설정</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                placeholder="년도 (YYYY)"
                onValueChange={onYearTextBoxValueChanged}
                valueChangeEvent="keyup"
                width={120}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button onClick={onUpButtonClicked} visible={isRecalculateVisible} icon="arrowup" />
            </div>
            <div className={"flex-item1"}>
              <Button onClick={onDownButtonClicked} visible={isRecalculateVisible} icon="arrowdown" />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" visible={isRecalculateVisible} onClick={onDeleteButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete All" visible={isRecalculateVisible} onClick={onDeleteAllButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" visible={isRecalculateVisible} onClick={onNewButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Edit" visible={isRecalculateVisible} onClick={onEditButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Upload" visible={isEditVisible} onClick={onUploadButtonClicked} icon="upload" />
            </div>
          </div>

          <div>
            <div className={"flex-item2"}>
              <TextBox
                placeholder="제목"
                visible={isEditVisible}
                onValueChange={onTitleTextBoxValueChanged}
                valueChangeEvent="keyup"
                height={40}
                value={titleText}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextArea
                placeholder="계정코드 List"
                visible={isEditVisible}
                onValueChange={onAccountsTextAreaValueChanged}
                value={accountsText}
                showClearButton={true}
              ></TextArea>
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="year" caption="년도" />
              <Column dataField="turn" caption="순번" />
              <Column dataField="title" caption="제목" />
              <Column dataField="accounts" caption="계정코드 List" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="year" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div>
            <div className={"flex-item1"}>
              <Button text="연간 자금현황 계산" onClick={onRecalculateButtonClicked} visible={isRecalculateVisible} icon="refresh" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
