import { createContext, useContext } from "react";
export type PurchaseItemContextType = {
  id: string;
  isDisabled: boolean;
  isPeriodic: string;
  isChemical: string;
  name: string;
  catNo: string;
  casNo: string;
  manufacturer: string;
  supplier: string;
  contactPerson: string;
  contact: string;
  usageUnit: string;
  purchaseUnit: string;
  purchaseUnitRatio: string;
  unitPrice: string;
  leadTime: string;
  purpose: string;
  selectionReason: string;
  comparativeEstimate0: string;
  comparativeEstimate1: string;
  comparativeEstimate2: string;
  manager: string;
  category: string;
  site: string;
  storage: string;
  hasMsds: string;
  msdsHazSta: string;
  hazLev: string;
  hazMat: string;
  speHazMat: string;
  worEnvHazFac: string;
  heaCheHazFac: string;
  expStaMat: string;
  quaConHazFac: string;
  repHazMat: string;
  nonSecMat: string;
  chemicalNote: string;
  quantity: string;
  safetyQuantity: string;
  monthlyUsage: string;
  note: string;
};
const PurchaseItemContext = createContext<PurchaseItemContextType>({} as PurchaseItemContextType);
const usePurchaseItem = () => useContext(PurchaseItemContext);
export { usePurchaseItem };
