import "./certificate.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCertificate } from "../../contexts/certificate";
// api
import { queryJsonArray, downloadAndOpenPdf, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function Certificate() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const certificateContext = useCertificate();

  if (user) {
  }
  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [selectedNo, setSelectedNo] = useState(-1);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(true);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
    let acc_admin = 0;
    if (user) {
      setUserId(user.id);
      acc_admin = user.acc_admin;
    }
    // (admin) [14] certificate R, [15] W [16] D
    if (U.bitAt(acc_admin, 15)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_admin, 16)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setSelectedNo(-1);
    setSelectedTitle("");
    let query = `SELECT * FROM certificate`;

    queryJsonArray("/certificate/query", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        // 기존 json row에 몇몇 column을 추가
        let oneRowJson: Object | null = jsonData[i];
        let registrationDate: number = U.intValueFromJson(oneRowJson, "registrationDate");
        oneRowJson = U.addStringToJson(oneRowJson, "registrationDate2", U.stringDateFromEightDigitIntDate(registrationDate));
        if (oneRowJson === null) continue;
        let expireDate: number = U.intValueFromJson(oneRowJson, "expireDate");
        oneRowJson = U.addStringToJson(oneRowJson, "expireDate2", U.stringDateFromEightDigitIntDate(expireDate));
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      setJsonDataSource(newJsonData);
    });
  }

  function onPdfButtonClicked() {
    if (selectedNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let fileName = `${selectedNo}.pdf`;
    downloadAndOpenPdf("/certificate/downloadfile", fileName);
  }

  function onNewButtonClicked() {
    certificateContext.no = -1;
    navigate("/certificate/new");
  }

  function copyRowToCertificateContext(row: Object) {
    certificateContext.no = U.intValueFromJson(row, "no");
    certificateContext.registrationDate = U.intValueFromJson(row, "registrationDate");
    certificateContext.type = U.stringValueFromJson(row, "type");
    certificateContext.title = U.stringValueFromJson(row, "title");
    certificateContext.result = U.stringValueFromJson(row, "result");
    certificateContext.expireDate = U.intValueFromJson(row, "expireDate");
    certificateContext.organization = U.stringValueFromJson(row, "organization");
    certificateContext.cost = U.floatValueFromJson(row, "cost");
    certificateContext.manager = U.stringValueFromJson(row, "manager");
    certificateContext.note = U.stringValueFromJson(row, "note");
    certificateContext.updated_by = U.stringValueFromJson(row, "updated_by");
    certificateContext.updated_at = U.dateValueFromJson(row, "updated_at");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyRowToCertificateContext(selectedRow);
    navigate("/certificate/new");
  }

  function onDeleteButtonClicked() {
    if (selectedNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedTitle, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/certificate/delete", selectedNo);
        setSelectedNo(-1);
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedNo(e.selectedRowsData[0].no);
      setSelectedTitle(e.selectedRowsData[0].title);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>인증 및 성적서</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="Open pdf" onClick={onPdfButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>
          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="registrationDate2" caption="인증일" />
              <Column dataField="type" caption="유형" />
              <Column dataField="title" caption="제목" />
              <Column dataField="result" caption="결과" />
              <Column dataField="expireDate2" caption="만료일" />
              <Column dataField="organization" caption="기관" />
              <Column dataField="cost" caption="비용(원)" dataType="number" format="#,##0" />
              <Column dataField="manager" caption="담당자" />
              <Column dataField="note" caption="Note" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="registrationDate2" summaryType="count" valueFormat="#,##0" />
                <TotalItem column="cost" summaryType="sum" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
