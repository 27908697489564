import { createContext, useContext } from "react";
export type ProjectTaskContextType = {
  no: number;
  projectNo: number;
  name: string;
  status: string;
  progress: number;
  startDate: number;
  scheduledEndDate: number;
  endDate: number;
  participant: string;
  note: string;
  updated_by: string;
  updated_at: Date;
};
const ProjectTaskContext = createContext<ProjectTaskContextType>({} as ProjectTaskContextType);
const useProjectTask = () => useContext(ProjectTaskContext);
export { useProjectTask };
