import "./purchaseRequestNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { usePurchaseItem } from "../../contexts/purchaseItem";
// api
import { uploadJson } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";

export default function PurchaseRequestNew() {
  const { user } = useAuth();
  const purchaseItem = usePurchaseItem();
  const navigate = useNavigate();

  // "L" for Locale 처리된 string (#,##0)
  const [name, setName] = useState("");
  const [purpose, setPurpose] = useState("");
  const [purchaseReason, setPurchaseReason] = useState("");
  const [quantity, setQuantity] = useState("");
  const [purchaseUnit, setPurchaseUnit] = useState("");
  const [unitPriceL, setUnitPriceL] = useState("");
  const [totalPriceL, setTotalPriceL] = useState("");
  const [purchaseUnitRatio, setPurchaseUnitRatio] = useState("");
  const [isProjectBudget, setIsProjectBudget] = useState(false);
  const [usageUnitQuantityL, setUsageUnitQuantityL] = useState("");
  const [usageUnit, setUsageUnit] = useState("");
  const [inventoryQuantity, setInventoryQuantity] = useState("");
  const [monthlyUsage, setMonthlyUsage] = useState("");
  const [estDelDate, setEstDelDate] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [requester, setRequester] = useState("");
  const [id, setId] = useState("");
  const [catNo, setCatNo] = useState("");
  const [supplier, setSupplier] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    let userId: string = "";
    let userName: string = "";
    if (user) {
      userId = user.id;
      userName = user.name;
    }

    if (!purchaseItem.id) return;
    if (purchaseItem.id.length === 0) return;
    // context 변수로부터 element에 복사
    setName(purchaseItem.name);
    setPurpose(purchaseItem.purpose);
    setPurchaseReason("안전재고 확보");
    setQuantity("0");
    setPurchaseUnit(purchaseItem.purchaseUnit);
    let unitPriceF: number = parseFloat(purchaseItem.unitPrice);
    if (Number.isNaN(unitPriceF)) setUnitPriceL("0");
    else setUnitPriceL(unitPriceF.toLocaleString());
    setTotalPriceL("0");
    setPurchaseUnitRatio(purchaseItem.purchaseUnitRatio);
    setIsProjectBudget(false);
    setUsageUnitQuantityL("0");
    setUsageUnit(purchaseItem.usageUnit);
    setInventoryQuantity(purchaseItem.quantity);
    setMonthlyUsage(purchaseItem.monthlyUsage);
    setEstDelDate("");
    setRequestDate(U.todayEightDigitStringDate());
    setRequester(userName);
    setId(purchaseItem.id);
    setCatNo(purchaseItem.catNo);
    setSupplier(purchaseItem.supplier);
    setCategory(purchaseItem.category);
  }, []);

  function onNameTextBoxValueChanged(e: string) {
    setName(e);
  }
  function onPurposeTextBoxValueChanged(e: string) {
    setPurpose(e);
  }
  function onPurchaseReasonTextBoxValueChanged(e: string) {
    setPurchaseReason(e);
  }
  function onQuantityTextBoxValueChanged(e: string) {
    setQuantity(e);
    // 입력한 구매수량에 따라 합계금액, 재고단위 구매수량을 자동계산해서 표시해줌.
    let quantityN: number = parseInt(e);
    setTotalPriceL("0");
    setUsageUnitQuantityL("0");
    if (Number.isNaN(quantityN)) return;
    let unitPriceLStr = unitPriceL;
    unitPriceLStr = unitPriceLStr.replaceAll(",", "");
    let unitPriceF: number = parseFloat(unitPriceLStr);
    if (Number.isNaN(unitPriceF)) return;
    let purchaseUnitRatioN: number = parseInt(purchaseUnitRatio);
    if (Number.isNaN(purchaseUnitRatioN)) return;
    let totalPriceF: number = quantityN * unitPriceF;
    let usageUnitQuantityN: number = quantityN * purchaseUnitRatioN;
    setTotalPriceL(totalPriceF.toLocaleString());
    setUsageUnitQuantityL(usageUnitQuantityN.toLocaleString());
  }
  function onPurchaseUnitTextBoxValueChanged(e: string) {
    setPurchaseUnit(e);
  }
  function onUnitPriceTextBoxValueChanged(e: string) {
    setUnitPriceL(e);
  }
  function onTotalPriceTextBoxValueChanged(e: string) {
    setTotalPriceL(e);
  }
  function onIsProjectBudgetCheckBoxValueChanged(e: boolean) {
    setIsProjectBudget(e);
  }
  function onUsageUnitQuantityTextBoxValueChanged(e: string) {
    setUsageUnitQuantityL(e);
  }
  function onUsageUnitTextBoxValueChanged(e: string) {
    setUsageUnit(e);
  }
  function onInventoryQuantityTextBoxValueChanged(e: string) {
    setInventoryQuantity(e);
  }
  function onMonthlyUsageTextBoxValueChanged(e: string) {
    setMonthlyUsage(e);
  }
  function onEstDelDateTextBoxValueChanged(e: string) {
    setEstDelDate(e);
  }
  function onRequestDateTextBoxValueChanged(e: string) {
    setRequestDate(e);
  }
  function onRequesterTextBoxValueChanged(e: string) {
    setRequester(e);
  }
  function onIdTextBoxValueChanged(e: string) {
    setId(e);
  }
  function onCatNoTextBoxValueChanged(e: string) {
    setCatNo(e);
  }
  function onSupplierTextBoxValueChanged(e: string) {
    setSupplier(e);
  }
  function onCategoryTextBoxValueChanged(e: string) {
    setCategory(e);
  }

  async function onUploadButtonClicked() {
    // 구매수량 0 이상, 납기예정일 입력 체크
    let quantityN: number = parseInt(quantity);
    if (Number.isNaN(quantityN)) {
      alert("Enter proper value to 구매수량!", "Error");
      return;
    }
    if (quantityN < 1) {
      alert("Enter proper value to 구매수량!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(estDelDate)) {
      alert("Enter proper value to 납기예정일!", "Error");
      return;
    }

    // boolean인 column 들에 대해 string에서 int으로 변환 후 upload
    let isProjectBudgetN: number = 0;
    if (isProjectBudget) isProjectBudgetN = 1;

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let purchaseUnitRatioN: number = parseInt(purchaseUnitRatio);
    if (Number.isNaN(purchaseUnitRatioN)) purchaseUnitRatioN = 0;

    let unitPriceStr: string = unitPriceL.replaceAll(",", "");
    let unitPriceF: number = parseFloat(unitPriceStr);
    if (Number.isNaN(unitPriceF)) unitPriceF = 0;

    let totalPriceStr: string = totalPriceL.replaceAll(",", "");
    let totalPriceF: number = parseFloat(totalPriceStr);
    if (Number.isNaN(totalPriceF)) totalPriceF = 0;

    let usageUnitQuantityStr: string = usageUnitQuantityL.replaceAll(",", "");
    let usageUnitQuantityN: number = parseInt(usageUnitQuantityStr);
    if (Number.isNaN(usageUnitQuantityN)) usageUnitQuantityN = 0;

    let monthlyUsageN: number = parseInt(monthlyUsage);
    if (Number.isNaN(monthlyUsageN)) monthlyUsageN = 0;

    let obj: Object = {
      id: id,
      catNo: catNo,
      name: name,
      usageUnit: usageUnit,
      purchaseUnit: purchaseUnit,
      purchaseUnitRatio: purchaseUnitRatioN,
      unitPrice: unitPriceF,
      supplier: supplier,
      category: category,
      quantity: quantityN,
      usageUnitQuantity: usageUnitQuantityN,
      totalPrice: totalPriceF,
      isProjectBudget: isProjectBudgetN,
      monthlyUsage: monthlyUsageN,
      purchaseReason: purchaseReason,
      requester: requester,
      requestDate: requestDate,
      status: "미발주",
      estDelDate: estDelDate,
      //orderDate: 0,
      //deliveryDate: 0,
    };
    await uploadJson("/purchase/uploadrequest", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        // 성공한 경우만 go back
        navigate(-1);
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>구매신청</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="품명"
                readOnly={true}
                value={name}
                valueChangeEvent="keyup"
                onValueChange={onNameTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="사용용도"
                readOnly={true}
                value={purpose}
                valueChangeEvent="keyup"
                onValueChange={onPurposeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="구매사유"
                value={purchaseReason}
                valueChangeEvent="keyup"
                onValueChange={onPurchaseReasonTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <TextBox
                  label="구매수량"
                  value={quantity}
                  valueChangeEvent="keyup"
                  onValueChange={onQuantityTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>

              <div className={"flex-item4"}>
                <TextBox
                  label="구매단위"
                  readOnly={true}
                  value={purchaseUnit}
                  valueChangeEvent="keyup"
                  onValueChange={onPurchaseUnitTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="구매단위단가"
                readOnly={true}
                value={unitPriceL}
                valueChangeEvent="keyup"
                onValueChange={onUnitPriceTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="합계금액"
                readOnly={true}
                value={totalPriceL}
                valueChangeEvent="keyup"
                onValueChange={onTotalPriceTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <CheckBox text="과제비 여부" value={isProjectBudget} onValueChange={onIsProjectBudgetCheckBoxValueChanged} width={300} />
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <TextBox
                  label="재고단위 구매수량"
                  readOnly={true}
                  value={usageUnitQuantityL}
                  valueChangeEvent="keyup"
                  onValueChange={onUsageUnitQuantityTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>

              <div className={"flex-item4"}>
                <TextBox
                  label="재고단위"
                  readOnly={true}
                  value={usageUnit}
                  valueChangeEvent="keyup"
                  onValueChange={onUsageUnitTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <TextBox
                  label="재고수량"
                  readOnly={true}
                  value={inventoryQuantity}
                  valueChangeEvent="keyup"
                  onValueChange={onInventoryQuantityTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>

              <div className={"flex-item4"}>
                <TextBox
                  label="재고단위"
                  readOnly={true}
                  value={usageUnit}
                  valueChangeEvent="keyup"
                  onValueChange={onUsageUnitTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <TextBox
                  label="월간사용량"
                  value={monthlyUsage}
                  valueChangeEvent="keyup"
                  onValueChange={onMonthlyUsageTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>

              <div className={"flex-item4"}>
                <TextBox
                  label="재고단위"
                  readOnly={true}
                  value={usageUnit}
                  valueChangeEvent="keyup"
                  onValueChange={onUsageUnitTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="납기예정일"
                value={estDelDate}
                valueChangeEvent="keyup"
                onValueChange={onEstDelDateTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="요청일"
                readOnly={true}
                value={requestDate}
                valueChangeEvent="keyup"
                onValueChange={onRequestDateTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="요청자"
                readOnly={true}
                value={requester}
                valueChangeEvent="keyup"
                onValueChange={onRequesterTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="허가품목코드"
                readOnly={true}
                value={id}
                valueChangeEvent="keyup"
                onValueChange={onIdTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Cat. No."
                readOnly={true}
                value={catNo}
                valueChangeEvent="keyup"
                onValueChange={onCatNoTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="공급업체"
                readOnly={true}
                value={supplier}
                valueChangeEvent="keyup"
                onValueChange={onSupplierTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="분류"
                readOnly={true}
                value={category}
                valueChangeEvent="keyup"
                onValueChange={onCategoryTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
