import "./projectTaskNew.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProjectTask } from "../../contexts/projectTask";
// api
import { uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";

export default function ProjectTaskNew() {
  const { user } = useAuth();
  const projectTaskContext = useProjectTask();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("Task");
  const [no, setNo] = useState(-1);
  const [projectNo, setProjectNo] = useState(-1);

  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState("0");
  const [startDate, setStartDate] = useState<Date>();
  const [scheduledEndDate, setScheduledEndDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [participant, setParticipant] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    setProjectNo(projectTaskContext.projectNo);
    setPageTitle("New Task");
    if (projectTaskContext.no >= 0) {
      // edit 버튼으로 진입한 경우: context 변수로부터 element에 복사
      setPageTitle("Edit Task");
      setNo(projectTaskContext.no);

      setName(projectTaskContext.name);
      setStatus(projectTaskContext.status);
      setProgress(projectTaskContext.progress.toString());
      if (U.isProperEightDigitIntDate(projectTaskContext.startDate)) setStartDate(U.startLocalDateFromEigitDigitIntDate(projectTaskContext.startDate));
      if (U.isProperEightDigitIntDate(projectTaskContext.scheduledEndDate))
        setScheduledEndDate(U.startLocalDateFromEigitDigitIntDate(projectTaskContext.scheduledEndDate));
      if (U.isProperEightDigitIntDate(projectTaskContext.endDate)) setEndDate(U.startLocalDateFromEigitDigitIntDate(projectTaskContext.endDate));

      setParticipant(projectTaskContext.participant);
      setNote(projectTaskContext.note);
    }
  }, []);

  function onNameTextBoxValueChanged(e: string) {
    setName(e);
  }
  function onStatusSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setStatus(str);
  }
  function onProgressTextBoxValueChanged(e: string) {
    setProgress(e);
  }
  function onStartDateDateBoxValueChanged(e: any) {
    setStartDate(e);
  }
  function onScheduledEndDateDateBoxValueChanged(e: any) {
    setScheduledEndDate(e);
  }
  function onEndDateDateBoxValueChanged(e: any) {
    setEndDate(e);
  }

  function onParticipantTextBoxValueChanged(e: string) {
    setParticipant(e);
  }
  function onNoteTextAreaValueChanged(e: string) {
    setNote(e);
  }

  ///////////////////////////// Upload Button
  async function onUploadButtonClicked() {
    if (name.length === 0) {
      alert("Task 이름을 입력하세요!", "Error");
      return;
    }
    if (status.length === 0) {
      alert("상태를 선택하세요!", "Error");
      return;
    }
    // DateBox를 선택하지 않으면 초기값으로 undefined로 표시됨.
    if (!startDate) {
      alert("시작일을 입력하세요!", "Error");
      return;
    }
    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let progressN: number = U.intFromString(progress, 0);
    let startDateN: number = U.eightDigitIntDateFromDate(startDate);
    let scheduledEndDateN: number = 0;
    if (scheduledEndDate) scheduledEndDateN = U.eightDigitIntDateFromDate(scheduledEndDate);
    let endDateN: number = 0;
    if (endDate) endDateN = U.eightDigitIntDateFromDate(endDate);

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let name2: string = U.replaceBrace(name);
    let participant2: string = U.replaceBrace(participant);
    let note2: string = U.replaceBrace(note);

    let obj: Object = {
      no: no,
      projectNo: projectNo,
      name: name2,
      status: status,
      progress: progressN,
      startDate: startDateN,
      scheduledEndDate: scheduledEndDateN,
      endDate: endDateN,
      participant: participant2,
      note: note2,
    };

    await uploadJson("/project/uploadtask", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        alert("같은 이름의 task가 이미 존재합니다!", "Error");
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="Task 이름" value={name} valueChangeEvent="keyup" onValueChange={onNameTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="실무자"
                value={participant}
                valueChangeEvent="keyup"
                onValueChange={onParticipantTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <SelectBox
                label="상태"
                dataSource={["대기 중", "진행 중", "중단", "종료"]}
                value={status}
                width={300}
                onSelectionChanged={onStatusSelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="진척도 (%)"
                value={progress}
                valueChangeEvent="keyup"
                onValueChange={onProgressTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <DateBox label="시작일" type="date" value={startDate} valueChangeEvent="change" onValueChange={onStartDateDateBoxValueChanged} width={300} />
            </div>

            <div className={"flex-item2"}>
              <DateBox
                label="종료예정일"
                type="date"
                value={scheduledEndDate}
                valueChangeEvent="change"
                onValueChange={onScheduledEndDateDateBoxValueChanged}
                width={300}
              />
            </div>

            <div className={"flex-item2"}>
              <DateBox label="종료일" type="date" value={endDate} valueChangeEvent="change" onValueChange={onEndDateDateBoxValueChanged} width={300} />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"}>
              <TextArea
                label="Note"
                onValueChange={onNoteTextAreaValueChanged}
                value={note}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
