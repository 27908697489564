import "./complianceSearch.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PDFViewer, Document, Page, Text, Font, View, StyleSheet } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
// api
import { queryJsonArray, deleteRowByNo, uploadComplianceWithFile, downloadAndOpenPdf, generateCompliance } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function ComplianceSearch() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userListContext = useUserList();

  const [file, setFile] = useState<File>();
  const [userId, setUserId] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [department, setDepartment] = useState<string>("");

  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [nameFilterList, setNameFilterList] = useState<Array<string>>();
  const [nameFilter, setNameFilter] = useState("");
  const [userIdFilter, setUserIdFilter] = useState("");

  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);

  const [selectedNo, setSelectedNo] = useState(-1);
  const [selectedName, setSelectedName] = useState("");

  const [jsonDataSource1, setJsonDataSource1] = useState<Array<Object>>();
  const [jsonDataSource2, setJsonDataSource2] = useState<Array<Object>>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;
    let acc_admin = 0;
    if (user) {
      acc_admin = user.acc_admin;
      setUserId(user.id);
      setUserName(user.name);
      setDepartment(user.department);
    }
    // (admin) [8] compliance R, [9] D
    if (U.bitAt(acc_admin, 9)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);
    search1();

    initializeQueryList();
    setNameFilter("All");
  }, []);

  function initializeQueryList() {
    // userListContext 정보를 분석해서 selectBox의 item list를 초기화한다.
    let l1: Array<string> = ["All"];
    for (let i = 0; i < userListContext.names.length; i++) {
      if (!U.bitAt(userListContext.acc_admins[i], 7)) continue; // acc_admin을 검사해서 Compliance 업로드 대상인 user에 대해서만 처리한다.
      let todayEightDigitDate = U.todayEightDigitIntDate();
      if (todayEightDigitDate < userListContext.joiningDates[i]) continue; // 아직 입사 전이라면
      U.addStringArrayIfNotExist(l1, userListContext.names[i]);
    }
    setNameFilterList(l1);

    let dateRange = calculateDateRange("1개월");
    if (dateRange) search2(dateRange[0], dateRange[1], "", "All");
  }

  function search1() {
    let query = "SELECT id, name, acc_admin, department, position, lastComplianceUpload_at, joiningDate FROM user";
    query += " ORDER BY department Desc, position ASC, name Asc";
    queryJsonArray("/compliance/query", query).then(
      (userJson: Array<Object>) => {
        let jsonData = new Array<Object>();
        let todayIntArray = U.todayIntArray();
        let cutDayIntArray = U.findMovedDate(todayIntArray[0], todayIntArray[1], todayIntArray[2], 0, -1, 0);
        let cutDayInt = U.eightDigitIntDateFromIntArray(cutDayIntArray[0], cutDayIntArray[1], cutDayIntArray[2]);
        for (let i = 0; i < userJson.length; i++) {
          let oneRowJson: Object | null = userJson[i];
          let lastComplianceUpload_at: Date = U.dateValueFromJson(oneRowJson, "lastComplianceUpload_at");
          let user_acc_admin: number = U.intValueFromJson(oneRowJson, "acc_admin");
          let user_joiningDate: number = U.intValueFromJson(oneRowJson, "joiningDate");
          if (!U.bitAt(user_acc_admin, 7)) continue;
          let todayEightDigitDate = U.todayEightDigitIntDate();
          if (todayEightDigitDate < user_joiningDate) continue; // 아직 입사 전이라면

          oneRowJson = U.addStringToJson(oneRowJson, "lastUpload", U.stringDateFromDate(lastComplianceUpload_at));
          if (oneRowJson === null) continue;

          let isComplianceOk: number = 1;
          let lastDayInt = U.eightDigitIntDateFromDate(lastComplianceUpload_at);
          if (Number.isNaN(lastDayInt)) {
            // NULL Date
            isComplianceOk = 0;
          } else {
            if (lastDayInt < cutDayInt) {
              isComplianceOk = 0;
            }
          }
          oneRowJson = U.addNumberToJson(oneRowJson, "isComplianceOk", isComplianceOk);
          if (oneRowJson !== null) jsonData.push(oneRowJson);
        } // End of for loop (userJson)

        setJsonDataSource1(jsonData);
      } // End of queryJsonArray.then(() => {} function
    );
  }

  function search2(from: string, to: string, userIdToSearch: string, userNameToSearch: string) {
    if (!U.isProperEightDigitStringDate(from)) {
      alert("Enter from date in proper format!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(to)) {
      alert("Enter to date in proper format!", "Error");
      return;
    }
    let dateFrom: number = parseInt(from);
    let dateTo: number = parseInt(to);
    //let startDate = U.startUTCStringDateFromEightDigitIntDate(dateFrom);
    //let endDate = U.endUTCStringDateFromEightDigitIntDate(dateTo);

    let query = "SELECT no, id, name, registrationDate, updated_at FROM compliance";
    query += ` WHERE registrationDate >= '${dateFrom}' AND registrationDate <= '${dateTo}'`;
    if (userIdToSearch.length === 0) {
    } else {
      query += ` AND id = '${userIdToSearch}'`;
    }
    query += " ORDER BY no Desc";
    queryJsonArray("/compliance/query", query).then((complianceJson: Array<Object>) => {
      setJsonDataSource2(complianceJson);
    });
  }

  async function onSearch1ButtonClicked() {
    search1();
  }

  async function onTemplateButtonClicked() {
    let fileName = "2.pdf";
    downloadAndOpenPdf("/etc/downloadfile", fileName);
  }

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      let f = e.target.files[0];
      setFile(f);
    }
  };

  async function onUploadButtonClicked() {
    if (!file) {
      alert("Select file first!", "Error");
      return;
    }
    let registrationDate: Number = U.todayEightDigitIntDate();
    await uploadComplianceWithFile(userId, registrationDate, file).then(() => {
      setFile(undefined);
      search1();
      search2(filterFrom, filterTo, userIdFilter, nameFilter);
    });
  }

  async function onGenerateButtonClicked() {
    //ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);
    //console.log(`${__dirname}/example.pdf`);
    let eightDigitDateString = U.todayEightDigitStringDate();
    generateCompliance(userId, userName, department, eightDigitDateString);
  }

  const onCellPrepared1 = (e: any) => {
    if (e.rowType !== "data") return;

    // 출근 상태가 아니면 (미출근, 퇴근, 연차 등) row 전체를 배경색 회색으로 표시
    if (e.data.isComplianceOk === 0) {
      //e.cellElement.style.cssText = "background-color: #EEEEEE";
      //e.cellElement.style.cssText = "color: red;background-color: #EEEEEE";
      e.cellElement.style.cssText = "color: red";
    }
  };

  function onDataGrid1SelectionChanged(e: any) {}

  function onFromTextBoxValueChanged(e: string) {
    setFilterFrom(e);
  }
  function onToTextBoxValueChanged(e: string) {
    setFilterTo(e);
  }

  function onDateRangeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    calculateDateRange(str);
  }
  function calculateDateRange(str: string) {
    let todayStr: string = U.todayEightDigitStringDate();
    setFilterTo(todayStr);
    let todayYMD = U.todayIntArray();
    let moveY: number = 0,
      moveM: number = 0,
      moveD: number = 0;
    if (str === "1주일") moveD = -6;
    if (str === "1개월") {
      moveM = -1;
      moveD = 1;
    }
    if (str === "2개월") {
      moveM = -2;
      moveD = 1;
    }
    if (str === "6개월") {
      moveM = -6;
      moveD = 1;
    }
    if (str === "1년") {
      moveY = -1;
      moveD = 1;
    }
    if (str === "2년") {
      moveY = -2;
      moveD = 1;
    }
    let startYMD = U.findMovedDate(todayYMD[0], todayYMD[1], todayYMD[2], moveY, moveM, moveD);
    let startStr = U.eightDigitStringDateFromIntArray(startYMD[0], startYMD[1], startYMD[2]);
    setFilterFrom(startStr);
    return [startStr, todayStr];
  }

  function onNameFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setNameFilter(str);
    let userId: string = U.getStringValueFromStringArray(str, userListContext.names, userListContext.ids);
    setUserIdFilter(userId);
  }

  function onSearch2ButtonClicked() {
    search2(filterFrom, filterTo, userIdFilter, nameFilter);
  }

  function onDownload2ButtonClicked() {
    if (selectedNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let fileName = `${selectedNo}.pdf`;
    downloadAndOpenPdf("/compliance/downloadfile", fileName);
  }

  async function onDelete2ButtonClicked() {
    if (selectedNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedName, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/compliance/delete", selectedNo);
        //await deleteRowByNo("/compliance/delete", selectedNo).then(() => {
        search1();
        search2(filterFrom, filterTo, userIdFilter, nameFilter);
        //});

        setSelectedNo(-1);
      } else {
        // No
      }
    });
  }

  function onDataGrid2SelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedNo(e.selectedRowsData[0].no);
      setSelectedName(e.selectedRowsData[0].name);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>임직원 내부통제 준수 확인서 조회</h2>

      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-containerTitle"}>
              <p className={"text-title"}>마지막 등록일 조회</p>
            </div>
            <div className={"flex-item4"}>
              <Button text="Search" onClick={onSearch1ButtonClicked} type="default" icon="download" />
            </div>
          </div>

          <div>
            <DataGrid
              onSelectionChanged={onDataGrid1SelectionChanged}
              dataSource={jsonDataSource1}
              columnAutoWidth={true}
              allowColumnReordering={true}
              onCellPrepared={onCellPrepared1}
            >
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="department" caption="부서" />
              <Column dataField="name" caption="이름" />
              <Column dataField="position" caption="직위" />
              <Column dataField="lastUpload" caption="마지막 등록일" dataType="date" />
              <Summary>
                <TotalItem column="date" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-containerTitle2"}>
            <p className={"text-title"}>pdf 파일 조회</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                placeholder="From (YYYYMMDD)"
                value={filterFrom}
                valueChangeEvent="keyup"
                onValueChange={onFromTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <TextBox
                placeholder="To (YYYYMMDD)"
                value={filterTo}
                valueChangeEvent="keyup"
                onValueChange={onToTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <SelectBox
                dataSource={["1주일", "1개월", "2개월", "6개월", "1년", "2년"]}
                defaultValue={"1개월"}
                width={120}
                height={40}
                onSelectionChanged={onDateRangeSelectionChanged}
              />
            </div>

            <div className={"flex-item1"}>
              <SelectBox
                label="이름"
                dataSource={nameFilterList}
                value={nameFilter}
                width={180}
                height={40}
                onSelectionChanged={onNameFilterSelectionChanged}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearch2ButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="Download" onClick={onDownload2ButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDelete2ButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGrid2SelectionChanged} dataSource={jsonDataSource2} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="updated_at" caption="등록일" dataType="date" />
              <Column dataField="name" caption="이름" />
              <Summary>
                <TotalItem column="updated_at" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
