import { createContext, useContext } from "react";
export type UsageContextType = {
  date: string;
  type: string;
  registrant: string;
  itemId: string;
  itemName: string;
  category: string;
  purchaseNo: string;
  orderDate: string;
  usageUnit: string;
  quantity: string;
  usageUnitPrice: string;
  totalPrice: string;
  lotNo: string;
  purpose: string;
  expDate: string;
  note: string;
  tag: string;
};
const UsageContext = createContext<UsageContextType>({} as UsageContextType);
const useUsage = () => useContext(UsageContext);
export { useUsage };
