import "./certificateNew.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCertificate } from "../../contexts/certificate";
// api
import { uploadCertificateWithFile, overwriteCertificate } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";

export default function CertificateNew() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const certificateContext = useCertificate();

  if (user) {
  }
  const [pageTitle, setPageTitle] = useState("인증 및 성적서");
  const [userName, setUserName] = useState("");
  const [file, setFile] = useState<File>();
  const [hiddenFile, setHiddenFile] = useState<boolean>(true);

  const [no, setNo] = useState(-1);
  const [registrationDate, setRegistrationDate] = useState("");
  const [type, setType] = useState("");
  const [title, setTitle] = useState("");
  const [result, setResult] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [organization, setOrganization] = useState("");
  const [cost, setCost] = useState("");
  const [manager, setManager] = useState("");
  const [note, setNote] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    if (user) {
      setUserName(user.name);
      setManager(user.name);
    }
    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;

      setPageTitle("인증 및 성적서 등록");
      setHiddenFile(false);
      if (certificateContext.no >= 0) {
        setPageTitle("인증 및 성적서 편집");
        setHiddenFile(true);
        // edit 버튼으로 진입한 경우: context 변수로부터 element에 복사
        setNo(certificateContext.no);
        setRegistrationDate(certificateContext.registrationDate.toString());
        setType(certificateContext.type);
        setTitle(certificateContext.title);
        setResult(certificateContext.result);
        setExpireDate(certificateContext.expireDate.toString());
        setOrganization(certificateContext.organization);
        setCost(certificateContext.cost.toString());
        setManager(certificateContext.manager);
        setNote(certificateContext.note);
      }
    }
  }, []);

  function onRegistrationDateTextBoxValueChanged(e: string) {
    setRegistrationDate(e);
  }
  function onTypeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setType(str);
  }
  function onTitleTextBoxValueChanged(e: string) {
    setTitle(e);
  }
  function onResultTextBoxValueChanged(e: string) {
    setResult(e);
  }
  function onExpireDateTextBoxValueChanged(e: string) {
    setExpireDate(e);
  }
  function onOrganizationTextBoxValueChanged(e: string) {
    setOrganization(e);
  }
  function onCostTextBoxValueChanged(e: string) {
    setCost(e);
  }
  function onManagerTextBoxValueChanged(e: string) {
    setManager(e);
  }
  function onNoteTextBoxValueChanged(e: string) {
    setNote(e);
  }

  // pdf file
  const onChangeImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      let f = e.target.files[0];
      setFile(f);
    }
  };

  function onUploadButtonClicked() {
    if (!title || !registrationDate || !type) {
      alert("Fill in inputs!", "Error");
      return;
    }

    // 8 digit date 체크
    if (0 < registrationDate.length) {
      if (!U.isProperEightDigitStringDate(registrationDate)) {
        alert("Enter proper value in Registration Date!", "Error");
        return;
      }
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let registrationDateN = parseInt(registrationDate);
    if (Number.isNaN(registrationDateN)) registrationDateN = 0;

    let expireDateN = parseInt(expireDate);
    if (Number.isNaN(expireDateN)) expireDateN = 0;

    let costN = parseInt(cost);
    if (Number.isNaN(costN)) costN = 0;

    if (no < 0) {
      // New
      if (!file) {
        alert("Select file first!", "Error");
        return;
      }
      uploadCertificateWithFile(registrationDateN, type, title, result, expireDateN, organization, costN, manager, note, file);
    } else {
      // Edit
      overwriteCertificate(no, registrationDateN, type, title, result, expireDateN, organization, costN, manager, note);
    }

    navigate(-1);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerV"}>
            <div className={"flex-item2"}>
              <TextBox
                label="인증일 (YYYYMMDD)"
                placeholder="인증일 (YYYYMMDD)"
                value={registrationDate}
                valueChangeEvent="keyup"
                onValueChange={onRegistrationDateTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <SelectBox
                label="유형"
                dataSource={["인증", "성적서", "수상"]}
                value={type}
                width={400}
                height={40}
                onSelectionChanged={onTypeSelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="제목"
                placeholder="제목"
                value={title}
                valueChangeEvent="keyup"
                onValueChange={onTitleTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="결과"
                placeholder="결과"
                value={result}
                valueChangeEvent="keyup"
                onValueChange={onResultTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="만료일 (YYYYMMDD)"
                placeholder="만료일 (YYYYMMDD)"
                value={expireDate}
                valueChangeEvent="keyup"
                onValueChange={onExpireDateTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="기관"
                placeholder="기관"
                value={organization}
                valueChangeEvent="keyup"
                onValueChange={onOrganizationTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="비용 (원)"
                placeholder="비용 (원)"
                value={cost}
                valueChangeEvent="keyup"
                onValueChange={onCostTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="담당자"
                placeholder="담당자"
                value={manager}
                valueChangeEvent="keyup"
                onValueChange={onManagerTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Note"
                placeholder="Note"
                value={note}
                valueChangeEvent="keyup"
                onValueChange={onNoteTextBoxValueChanged}
                width={400}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-containerH"}>
              <div className={"flex-item1"} hidden={hiddenFile}>
                <p>문서 (pdf)</p>
              </div>
              <div className={"flex-item1"} hidden={hiddenFile}>
                <input type="file" accept=".pdf" onChange={onChangeImg} />
              </div>
            </div>

            <div className={"flex-item3"}>
              <Button text="Upload" onClick={onUploadButtonClicked} type="default" icon="upload" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
