import "./projectDescription.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
import { useProject } from "../../contexts/project";
import { useProjectTask } from "../../contexts/projectTask";
import { useProjectMeeting } from "../../contexts/projectMeeting";
import { useProjectDocument } from "../../contexts/projectDocument";
// api
import { uploadJson, queryJsonArray, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";

export default function ProjectDescription() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userListContext = useUserList();
  const projectContext = useProject();
  const projectTaskContext = useProjectTask();
  const projectMeetingContext = useProjectMeeting();
  const projectDocumentContext = useProjectDocument();

  const [pageTitle, setPageTitle] = useState("");
  const [isProjectDescriptionHidden, setIsProjectDescriptionHidden] = useState(false);
  const [isProjectColumnVisible, setIsProjectColumnVisible] = useState(false);

  const [projectNo, setProjectNo] = useState(-1);
  const [projectName, setProjectName] = useState("");
  const [projectStatus, setProjectStatus] = useState("");
  const [projectProgress, setProjectProgress] = useState("");
  const [projectDepartment, setProjectDepartment] = useState("");
  const [projectManager, setProjectManager] = useState("");
  const [projectStartDate, setProjectStartDate] = useState("");
  const [projectScheduledEndDate, setProjectScheduledEndDate] = useState("");
  const [projectEndDate, setProjectEndDate] = useState("");

  const [isEditProjectButtonVisible, setIsEditProjectButtonVisible] = useState(false);
  const [isProjectDescriptionReadOnly, setIsProjectDescriptionReadOnly] = useState(true);
  const [isUploadProjectButtonVisible, setIsUploadProjectButtonVisible] = useState(false);
  const [projectPurpose, setProjectPurpose] = useState("");
  const [projectAbstract, setProjectAbstract] = useState("");
  const [projectConclusion, setProjectConclusion] = useState("");
  const [projectNote, setProjectNote] = useState("");
  const [projectCloudiumPath, setProjectCloudiumPath] = useState("");

  const [isNewTaskButtonVisible, setIsNewTaskButtonVisible] = useState(false);
  const [isDeleteTaskButtonVisible, setIsDeleteTaskButtonVisible] = useState(false);

  const [isNewMeetingButtonVisible, setIsNewMeetingButtonVisible] = useState(false);
  const [isDeleteMeetingButtonVisible, setIsDeleteMeetingButtonVisible] = useState(false);
  const [isMeetingContentHidden, setIsMeetingContentHidden] = useState(true);
  const [meetingContent, setMeetingContent] = useState("");

  const [isNewDocumentButtonVisible, setIsNewDocumentButtonVisible] = useState(false);
  const [isDeleteDocumentButtonVisible, setIsDeleteDocumentButtonVisible] = useState(false);

  const [taskJsonDataSource, setTaskJsonDataSource] = useState<Array<Object>>();
  const [selectedTaskNo, setSelectedTaskNo] = useState(-1);
  const [selectedTaskName, setSelectedTaskName] = useState("");
  const [selectedTaskRow, setSelectedTaskRow] = useState<Object>();

  const [meetingJsonDataSource, setMeetingJsonDataSource] = useState<Array<Object>>();
  const [selectedMeetingNo, setSelectedMeetingNo] = useState(-1);
  const [selectedMeetingName, setSelectedMeetingName] = useState("");
  const [selectedMeetingRow, setSelectedMeetingRow] = useState<Object>();

  const [documentJsonDataSource, setDocumentJsonDataSource] = useState<Array<Object>>();
  const [selectedDocumentNo, setSelectedDocumentNo] = useState(-1);
  const [selectedDocumentName, setSelectedDocumentName] = useState("");
  const [selectedDocumentRow, setSelectedDocumentRow] = useState<Object>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_proj: number = 0;
    let userName: string = "";
    if (user) {
      acc_proj = user.acc_proj;
      userName = user.name;
    }
    // New, Edit, Delete 버튼은 권한도 있으면서 해당 프로젝트를 생성한 책임자만 활성화됨 (super user 권한이 있으면 책임자 아니라도 활성화됨)
    // (proj) [0] project R (Dash), [1] W, [2] D, [3] super user
    // new, edit 권한
    let isEditable: boolean = false;
    if (U.bitAt(acc_proj, 1)) {
      if (U.bitAt(acc_proj, 3)) isEditable = true;
      else {
        if (projectContext.manager === userName) isEditable = true;
      }
    }
    if (isEditable) {
      setIsNewTaskButtonVisible(true);
      setIsNewMeetingButtonVisible(true);
      setIsNewDocumentButtonVisible(true);
      setIsEditProjectButtonVisible(true);
    }
    // delete 권한
    let isDeletable: boolean = false;
    if (U.bitAt(acc_proj, 2)) {
      if (U.bitAt(acc_proj, 3)) isDeletable = true;
      else {
        if (projectContext.manager === userName) isDeletable = true;
      }
    }
    if (isDeletable) {
      setIsDeleteTaskButtonVisible(true);
      setIsDeleteMeetingButtonVisible(true);
      setIsDeleteDocumentButtonVisible(true);
    }

    // project / Routine Work에 따라 화면에 다르게 표시함
    if (projectContext.status === "Routine") {
      setPageTitle(`${projectContext.name}`);
      setIsProjectColumnVisible(false);
      setIsProjectDescriptionHidden(true);
    } else {
      setPageTitle(`Project: ${projectContext.name}`);
      setIsProjectColumnVisible(true);
      setIsProjectDescriptionHidden(false);
    }

    // projectContext 정보를 화면에  update
    setProjectNo(projectContext.no);
    setProjectName(projectContext.name);
    setProjectStatus(projectContext.status);
    setProjectProgress(projectContext.progress.toString());
    setProjectDepartment(projectContext.department);
    setProjectManager(projectContext.manager);
    setProjectStartDate(U.stringDateFromEightDigitIntDate(projectContext.startDate));
    setProjectScheduledEndDate(U.stringDateFromEightDigitIntDate(projectContext.scheduledEndDate));
    setProjectEndDate(U.stringDateFromEightDigitIntDate(projectContext.endDate));
    setProjectCloudiumPath(projectContext.cloudiumPath);
    setProjectPurpose(projectContext.purpose);
    setProjectAbstract(projectContext.abstract);
    setProjectConclusion(projectContext.conclusion);
    setProjectNote(projectContext.note);

    if (projectContext.no >= 0) {
      searchTask(projectContext.no);
      searchMeeting(projectContext.no);
      searchDocument(projectContext.no);
    }
  }, []);

  function onEditProjectButtonClicked() {
    if (isUploadProjectButtonVisible) {
      setIsUploadProjectButtonVisible(false);
      setIsProjectDescriptionReadOnly(true);
    } else {
      setIsUploadProjectButtonVisible(true);
      setIsProjectDescriptionReadOnly(false);
    }
  }

  async function onUploadProjectButtonClicked() {
    if (projectNo < 0) {
      alert("프로젝트를 선택하세요!", "Error");
      return;
    }

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let projectCloudiumPath2: string = U.replaceBrace(projectCloudiumPath);
    let projectPurpose2: string = U.replaceBrace(projectPurpose);
    let projectAbstract2: string = U.replaceBrace(projectAbstract);
    let projectConclusion2: string = U.replaceBrace(projectConclusion);
    let projectNote2: string = U.replaceBrace(projectNote);

    let obj: Object = {
      no: projectNo,
      cloudiumPath: projectCloudiumPath2,
      purpose: projectPurpose2,
      abstract: projectAbstract2,
      conclusion: projectConclusion2,
      note: projectNote2,
    };

    await uploadJson("/project/uploadprojectdescription", obj).then((response_status: number) => {
      console.log(response_status);
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        onEditProjectButtonClicked();
        //navigate(-1);
        return;
      }
      if (response_status === 20) {
        // 20: Data id does not exist
        alert("프로젝트가 존재하지 않습니다!", "Error");
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  function onProjectCloudiumPathTextAreaValueChanged(e: string) {
    setProjectCloudiumPath(e);
  }
  function onProjectPurposeTextAreaValueChanged(e: string) {
    setProjectPurpose(e);
  }
  function onProjectAbstractTextAreaValueChanged(e: string) {
    setProjectAbstract(e);
  }
  function onProjectConclusionTextAreaValueChanged(e: string) {
    setProjectConclusion(e);
  }
  function onProjectNoteTextAreaValueChanged(e: string) {
    setProjectNote(e);
  }

  function onSearchTaskButtonClicked() {
    if (projectContext.no < 0) {
      alert("프로젝트를 선택하세요.", "Error");
      return;
    }
    searchTask(projectContext.no);
  }
  function searchTask(searchProjectNo: number) {
    let query = "SELECT * FROM projecttask";
    query += ` WHERE projectNo = ${searchProjectNo}`;
    query += " ORDER BY startDate Asc";
    queryJsonArray("/project/query", query).then((projectJson: Array<Object>) => {
      let newJsonData = new Array<Object>();
      // status에 대해 "진행 중" -> "대기 중" -> "중단" -> "종료" 순서로  배치함
      for (let i = 0; i < projectJson.length; i++) {
        let oneRowJson: Object | null = projectJson[i];
        let startDate: number = U.intValueFromJson(oneRowJson, "startDate");
        let scheduledEndDate: number = U.intValueFromJson(oneRowJson, "scheduledEndDate");
        let endDate: number = U.intValueFromJson(oneRowJson, "endDate");
        let paymentDate: number = U.intValueFromJson(oneRowJson, "paymentDate");
        let status: string = U.stringValueFromJson(oneRowJson, "status");
        if (status !== "진행 중") continue;
        let startDate2: string = U.stringDateFromEightDigitIntDate(startDate);
        oneRowJson = U.addStringToJson(oneRowJson, "startDate2", startDate2);
        if (!oneRowJson) continue;
        let scheduledEndDate2: string = U.stringDateFromEightDigitIntDate(scheduledEndDate);
        oneRowJson = U.addStringToJson(oneRowJson, "scheduledEndDate2", scheduledEndDate2);
        if (!oneRowJson) continue;
        let endDate2: string = U.stringDateFromEightDigitIntDate(endDate);
        oneRowJson = U.addStringToJson(oneRowJson, "endDate2", endDate2);
        if (!oneRowJson) continue;
        let paymentDate2: string = U.stringDateFromEightDigitIntDate(paymentDate);
        oneRowJson = U.addStringToJson(oneRowJson, "paymentDate2", paymentDate2);

        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      for (let i = 0; i < projectJson.length; i++) {
        let oneRowJson: Object | null = projectJson[i];
        let startDate: number = U.intValueFromJson(oneRowJson, "startDate");
        let scheduledEndDate: number = U.intValueFromJson(oneRowJson, "scheduledEndDate");
        let endDate: number = U.intValueFromJson(oneRowJson, "endDate");
        let paymentDate: number = U.intValueFromJson(oneRowJson, "paymentDate");
        let status: string = U.stringValueFromJson(oneRowJson, "status");
        if (status !== "대기 중") continue;
        let startDate2: string = U.stringDateFromEightDigitIntDate(startDate);
        oneRowJson = U.addStringToJson(oneRowJson, "startDate2", startDate2);
        if (!oneRowJson) continue;
        let scheduledEndDate2: string = U.stringDateFromEightDigitIntDate(scheduledEndDate);
        oneRowJson = U.addStringToJson(oneRowJson, "scheduledEndDate2", scheduledEndDate2);
        if (!oneRowJson) continue;
        let endDate2: string = U.stringDateFromEightDigitIntDate(endDate);
        oneRowJson = U.addStringToJson(oneRowJson, "endDate2", endDate2);
        if (!oneRowJson) continue;
        let paymentDate2: string = U.stringDateFromEightDigitIntDate(paymentDate);
        oneRowJson = U.addStringToJson(oneRowJson, "paymentDate2", paymentDate2);

        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      for (let i = 0; i < projectJson.length; i++) {
        let oneRowJson: Object | null = projectJson[i];
        let startDate: number = U.intValueFromJson(oneRowJson, "startDate");
        let scheduledEndDate: number = U.intValueFromJson(oneRowJson, "scheduledEndDate");
        let endDate: number = U.intValueFromJson(oneRowJson, "endDate");
        let paymentDate: number = U.intValueFromJson(oneRowJson, "paymentDate");
        let status: string = U.stringValueFromJson(oneRowJson, "status");
        if (status !== "중단") continue;
        let startDate2: string = U.stringDateFromEightDigitIntDate(startDate);
        oneRowJson = U.addStringToJson(oneRowJson, "startDate2", startDate2);
        if (!oneRowJson) continue;
        let scheduledEndDate2: string = U.stringDateFromEightDigitIntDate(scheduledEndDate);
        oneRowJson = U.addStringToJson(oneRowJson, "scheduledEndDate2", scheduledEndDate2);
        if (!oneRowJson) continue;
        let endDate2: string = U.stringDateFromEightDigitIntDate(endDate);
        oneRowJson = U.addStringToJson(oneRowJson, "endDate2", endDate2);
        if (!oneRowJson) continue;
        let paymentDate2: string = U.stringDateFromEightDigitIntDate(paymentDate);
        oneRowJson = U.addStringToJson(oneRowJson, "paymentDate2", paymentDate2);

        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      for (let i = 0; i < projectJson.length; i++) {
        let oneRowJson: Object | null = projectJson[i];
        let startDate: number = U.intValueFromJson(oneRowJson, "startDate");
        let scheduledEndDate: number = U.intValueFromJson(oneRowJson, "scheduledEndDate");
        let endDate: number = U.intValueFromJson(oneRowJson, "endDate");
        let paymentDate: number = U.intValueFromJson(oneRowJson, "paymentDate");
        let status: string = U.stringValueFromJson(oneRowJson, "status");
        if (status !== "종료") continue;
        let startDate2: string = U.stringDateFromEightDigitIntDate(startDate);
        oneRowJson = U.addStringToJson(oneRowJson, "startDate2", startDate2);
        if (!oneRowJson) continue;
        let scheduledEndDate2: string = U.stringDateFromEightDigitIntDate(scheduledEndDate);
        oneRowJson = U.addStringToJson(oneRowJson, "scheduledEndDate2", scheduledEndDate2);
        if (!oneRowJson) continue;
        let endDate2: string = U.stringDateFromEightDigitIntDate(endDate);
        oneRowJson = U.addStringToJson(oneRowJson, "endDate2", endDate2);
        if (!oneRowJson) continue;
        let paymentDate2: string = U.stringDateFromEightDigitIntDate(paymentDate);
        oneRowJson = U.addStringToJson(oneRowJson, "paymentDate2", paymentDate2);

        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      setTaskJsonDataSource(newJsonData);
    });
  }

  function onNewTaskButtonClicked() {
    projectTaskContext.projectNo = projectContext.no;
    projectTaskContext.no = -1;
    navigate("/project/task/new");
  }

  function copyRowToProjectTaskContext(row: Object) {
    projectTaskContext.no = U.intValueFromJson(row, "no");
    projectTaskContext.projectNo = U.intValueFromJson(row, "projectNo");
    projectTaskContext.name = U.stringValueFromJson(row, "name");
    projectTaskContext.status = U.stringValueFromJson(row, "status");
    projectTaskContext.progress = U.intValueFromJson(row, "progress");
    projectTaskContext.startDate = U.intValueFromJson(row, "startDate");
    projectTaskContext.scheduledEndDate = U.intValueFromJson(row, "scheduledEndDate");
    projectTaskContext.endDate = U.intValueFromJson(row, "endDate");
    projectTaskContext.participant = U.stringValueFromJson(row, "participant");
    projectTaskContext.note = U.stringValueFromJson(row, "note");
    projectTaskContext.updated_by = U.stringValueFromJson(row, "updated_by");
    projectTaskContext.updated_at = U.dateValueFromJson(row, "updated_at");
  }

  function onEditTaskButtonClicked() {
    if (!selectedTaskRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyRowToProjectTaskContext(selectedTaskRow);
    navigate("/project/task/new");
  }

  function onDeleteTaskButtonClicked() {
    if (selectedTaskNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedTaskName, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/project/deletetask", selectedTaskNo).then(() => {
          searchTask(projectNo);
        });
      } else {
        // No
      }
    });
  }

  function onSearchMeetingButtonClicked() {
    //setIsMeetingContentHidden(true);
    if (projectContext.no < 0) {
      alert("프로젝트를 선택하세요.", "Error");
      return;
    }
    searchMeeting(projectContext.no);
  }
  function searchMeeting(searchProjectNo: number) {
    let query = "SELECT * FROM projectmeeting";
    query += ` WHERE projectNo = ${searchProjectNo}`;
    query += " ORDER BY date Asc";
    queryJsonArray("/project/query", query).then((projectJson: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < projectJson.length; i++) {
        let oneRowJson: Object | null = projectJson[i];
        let date: number = U.intValueFromJson(oneRowJson, "date");
        let date2: string = U.stringDateFromEightDigitIntDate(date);
        oneRowJson = U.addStringToJson(oneRowJson, "date2", date2);
        //if (!oneRowJson) continue;
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      setMeetingJsonDataSource(newJsonData);
    });
  }

  function onNewMeetingButtonClicked() {
    projectMeetingContext.projectNo = projectContext.no;
    projectMeetingContext.projectName = projectContext.name;
    if (user) projectMeetingContext.registrant = user.name;
    projectMeetingContext.no = -1;
    navigate("/project/meeting/new");
  }

  function copyRowToProjectMeetingContext(row: Object) {
    projectMeetingContext.no = U.intValueFromJson(row, "no");
    projectMeetingContext.projectNo = U.intValueFromJson(row, "projectNo");
    projectMeetingContext.projectName = U.stringValueFromJson(row, "projectName");
    projectMeetingContext.date = U.intValueFromJson(row, "date");
    projectMeetingContext.name = U.stringValueFromJson(row, "name");
    if (user) projectMeetingContext.registrant = user.name;
    projectMeetingContext.content = U.stringValueFromJson(row, "content");
    projectMeetingContext.updated_by = U.stringValueFromJson(row, "updated_by");
    projectMeetingContext.updated_at = U.dateValueFromJson(row, "updated_at");
  }

  function onEditMeetingButtonClicked() {
    if (!selectedMeetingRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyRowToProjectMeetingContext(selectedMeetingRow);
    navigate("/project/meeting/new");
  }

  function onDeleteMeetingButtonClicked() {
    if (selectedMeetingNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedMeetingName, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/project/deletemeeting", selectedMeetingNo).then(() => {
          searchMeeting(projectNo);
        });
      } else {
        // No
      }
    });
  }

  function onSearchDocumentButtonClicked() {
    if (projectContext.no < 0) {
      alert("프로젝트를 선택하세요.", "Error");
      return;
    }
    searchDocument(projectContext.no);
  }

  function searchDocument(searchProjectNo: number) {
    let query = "SELECT * FROM projectdocument";
    query += ` WHERE projectNo = ${searchProjectNo}`;
    query += " ORDER BY created_at Asc";
    queryJsonArray("/project/query", query).then((projectJson: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < projectJson.length; i++) {
        let oneRowJson: Object | null = projectJson[i];
        let registrationDate: number = U.intValueFromJson(oneRowJson, "registrationDate");
        let registrationDate2: string = U.stringDateFromEightDigitIntDate(registrationDate);
        oneRowJson = U.addStringToJson(oneRowJson, "registrationDate2", registrationDate2);
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      setDocumentJsonDataSource(newJsonData);
    });
  }

  function onNewDocumentButtonClicked() {
    projectDocumentContext.projectNo = projectContext.no;
    projectDocumentContext.no = -1;
    navigate("/project/document/new");
  }

  function copyRowToProjectDocumentContext(row: Object) {
    projectDocumentContext.no = U.intValueFromJson(row, "no");
    projectDocumentContext.projectNo = U.intValueFromJson(row, "projectNo");
    projectDocumentContext.name = U.stringValueFromJson(row, "name");
    projectDocumentContext.type = U.stringValueFromJson(row, "type");
    projectDocumentContext.ext = U.stringValueFromJson(row, "ext");
    projectDocumentContext.writer = U.stringValueFromJson(row, "writer");
    projectDocumentContext.approval = U.stringValueFromJson(row, "approval");
    projectDocumentContext.link1 = U.stringValueFromJson(row, "link1");
    projectDocumentContext.link2 = U.stringValueFromJson(row, "link2");
    projectDocumentContext.link3 = U.stringValueFromJson(row, "link3");
    projectDocumentContext.created_at = U.dateValueFromJson(row, "created_at");
    projectDocumentContext.updated_by = U.stringValueFromJson(row, "updated_by");
    projectDocumentContext.updated_at = U.dateValueFromJson(row, "updated_at");
  }

  function onEditDocumentButtonClicked() {
    if (!selectedDocumentRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyRowToProjectDocumentContext(selectedDocumentRow);
    navigate("/project/document/new");
  }
  function onDeleteDocumentButtonClicked() {
    if (selectedDocumentNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedDocumentName, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/project/deletedocument", selectedDocumentNo).then(() => {
          searchDocument(projectNo);
        });
      } else {
        // No
      }
    });
  }

  function onFileInfoButtonClicked() {
    if (!selectedDocumentRow) {
      alert("Select row first!", "Error");
      return;
    }
    let link1: string = U.stringValueFromJson(selectedDocumentRow, "link1");
    window.open(link1);
  }
  function onOpenReadOnlyButtonClicked() {
    if (!selectedDocumentRow) {
      alert("Select row first!", "Error");
      return;
    }
    let link2: string = U.stringValueFromJson(selectedDocumentRow, "link2");
    window.open(link2);
  }
  function onOpenWritableButtonClicked() {
    if (!selectedDocumentRow) {
      alert("Select row first!", "Error");
      return;
    }
    let link3: string = U.stringValueFromJson(selectedDocumentRow, "link3");
    window.open(link3);
  }

  function onTaskDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedTaskNo(e.selectedRowsData[0].no);
      setSelectedTaskName(e.selectedRowsData[0].name);
      setSelectedTaskRow(e.selectedRowsData[0]);
    }
  }

  function onMeetingDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedMeetingNo(e.selectedRowsData[0].no);
      setSelectedMeetingName(e.selectedRowsData[0].name);
      setSelectedMeetingRow(e.selectedRowsData[0]);
      setIsMeetingContentHidden(false);
      setMeetingContent(e.selectedRowsData[0].content);
    }
  }

  function onDocumentDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedDocumentNo(e.selectedRowsData[0].no);
      setSelectedDocumentName(e.selectedRowsData[0].name);
      setSelectedDocumentRow(e.selectedRowsData[0]);
    }
  }

  function onDocumentDataGridCellDbClick(e: any) {
    let link3: string = U.stringValueFromJson(e.row.data, "link3");
    window.open(link3);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerTitle"}>
            <p className={"text-title"} hidden={isProjectDescriptionHidden}>
              Project Description
            </p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"} hidden={isProjectDescriptionHidden}>
              <TextBox label="상태" value={projectStatus} readOnly={true} width={150} showClearButton={true} />
            </div>

            <div className={"flex-item1"} hidden={isProjectDescriptionHidden}>
              <TextBox label="진척도 (%)" value={projectProgress} readOnly={true} width={150} showClearButton={true} />
            </div>

            <div className={"flex-item1"} hidden={isProjectDescriptionHidden}>
              <TextBox label="주관부서" value={projectDepartment} readOnly={true} width={150} showClearButton={true} />
            </div>

            <div className={"flex-item1"} hidden={isProjectDescriptionHidden}>
              <TextBox label="책임자" value={projectManager} readOnly={true} width={150} showClearButton={true} />
            </div>

            <div className={"flex-item1"} hidden={isProjectDescriptionHidden}>
              <TextBox label="시작일" value={projectStartDate} readOnly={true} width={150} showClearButton={true} />
            </div>

            <div className={"flex-item1"} hidden={isProjectDescriptionHidden}>
              <TextBox label="종료예정일" value={projectScheduledEndDate} readOnly={true} width={150} showClearButton={true} />
            </div>

            <div className={"flex-item1"} hidden={isProjectDescriptionHidden}>
              <TextBox label="종료일" value={projectEndDate} readOnly={true} width={150} showClearButton={true} />
            </div>

            <div className={"flex-item1"} hidden={isProjectDescriptionHidden}>
              <Button text="Edit" visible={isEditProjectButtonVisible} width={150} onClick={onEditProjectButtonClicked} />
            </div>

            <div className={"flex-item1"} hidden={isProjectDescriptionHidden}>
              <Button text="Upload" visible={isUploadProjectButtonVisible} width={150} onClick={onUploadProjectButtonClicked} type="default" icon="upload" />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"} hidden={isProjectDescriptionHidden}>
              <TextArea
                label="클라우디움 경로"
                readOnly={isProjectDescriptionReadOnly}
                onValueChange={onProjectCloudiumPathTextAreaValueChanged}
                value={projectCloudiumPath}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"} hidden={isProjectDescriptionHidden}>
              <TextArea
                label="목적"
                readOnly={isProjectDescriptionReadOnly}
                onValueChange={onProjectPurposeTextAreaValueChanged}
                value={projectPurpose}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"} hidden={isProjectDescriptionHidden}>
              <TextArea
                label="개괄"
                readOnly={isProjectDescriptionReadOnly}
                onValueChange={onProjectAbstractTextAreaValueChanged}
                value={projectAbstract}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"} hidden={isProjectDescriptionHidden}>
              <TextArea
                label="결론"
                readOnly={isProjectDescriptionReadOnly}
                onValueChange={onProjectConclusionTextAreaValueChanged}
                value={projectConclusion}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"} hidden={isProjectDescriptionHidden}>
              <TextArea
                label="Note"
                readOnly={isProjectDescriptionReadOnly}
                onValueChange={onProjectNoteTextAreaValueChanged}
                value={projectNote}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div className={"flex-containerTitle2"}>
            <p className={"text-title"}>Task</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchTaskButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" visible={isNewTaskButtonVisible} onClick={onNewTaskButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Edit" visible={isNewTaskButtonVisible} onClick={onEditTaskButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" visible={isDeleteTaskButtonVisible} onClick={onDeleteTaskButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onTaskDataGridSelectionChanged} dataSource={taskJsonDataSource} columnAutoWidth={true} allowColumnReordering={false}>
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="startDate2" caption="시작일" />
              <Column dataField="name" caption="Task 이름" />
              <Column dataField="status" caption="상태" />
              <Column dataField="progress" caption="진척도 (%)" visible={isProjectColumnVisible} />
              <Column dataField="participant" caption="실무자" />
              <Column dataField="scheduledEndDate2" caption="종료예정일" visible={isProjectColumnVisible} />
              <Column dataField="endDate2" caption="종료일" />
              <Column dataField="note" caption="Note" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="startDate2" summaryType="count" valueFormat="#,##0" />
                <TotalItem column="cost" summaryType="sum" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-containerTitle2"}>
            <p className={"text-title"}>진행현황 (회의록)</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchMeetingButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" visible={isNewMeetingButtonVisible} onClick={onNewMeetingButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Edit" visible={isNewMeetingButtonVisible} onClick={onEditMeetingButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" visible={isDeleteMeetingButtonVisible} onClick={onDeleteMeetingButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid
              onSelectionChanged={onMeetingDataGridSelectionChanged}
              dataSource={meetingJsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={false}
            >
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="date2" caption="일자" />
              <Column dataField="registrant" caption="등록자" />
              <Column dataField="name" caption="제목" />
              <Column dataField="content" caption="내용" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="date2" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-stretch"}>
            <div className={"flex-item2"} hidden={isMeetingContentHidden}>
              <TextArea label="내용" value={meetingContent} readOnly={true} autoResizeEnabled={true} maxHeight={800} />
            </div>
          </div>

          <div className={"flex-containerTitle2"}>
            <p className={"text-title"}>Document</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchDocumentButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="읽기 모드로 열기" onClick={onOpenReadOnlyButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="쓰기 모드로 열기" onClick={onOpenWritableButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="File Info" onClick={onFileInfoButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" visible={isNewDocumentButtonVisible} onClick={onNewDocumentButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Edit" visible={isNewDocumentButtonVisible} onClick={onEditDocumentButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" visible={isDeleteDocumentButtonVisible} onClick={onDeleteDocumentButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid
              onCellDblClick={onDocumentDataGridCellDbClick}
              onSelectionChanged={onDocumentDataGridSelectionChanged}
              dataSource={documentJsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={false}
            >
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="created_at" caption="등록일" dataType={"date"} />
              <Column dataField="name" caption="Document 이름" />
              <Column dataField="type" caption="Type" />
              <Column dataField="writer" caption="작성자" />
              <Column dataField="ext" caption="파일종류" />
              <Column dataField="approval" caption="승인내역" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="created_at" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
