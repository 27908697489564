import "./usageNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUsage } from "../../contexts/usage";
// api
import { uploadJson, setDeliveredPurchaseRequest } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";

export default function UsageNew() {
  const { user } = useAuth();
  const usage = useUsage();
  const navigate = useNavigate();

  // "L" for Locale 처리된 string (#,##0)
  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  const [registrant, setRegistrant] = useState("");
  const [itemId, setItemId] = useState("");
  const [itemName, setItemName] = useState("");
  const [category, setCategory] = useState("");
  const [purchaseNo, setPurchaseNo] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [usageUnit, setUsageUnit] = useState("");
  const [quantity, setQuantity] = useState("");
  const [usageUnitPriceL, setUsageUnitPriceL] = useState("");
  const [lotNo, setLotNo] = useState("");
  const [purpose, setPurpose] = useState("");
  const [expDate, setExpDate] = useState("");
  const [note, setNote] = useState("");
  const [tag, setTag] = useState("");
  const [isPurposeHidden, setIsPurposeHidden] = useState(true);
  const [isOrderDateHidden, setIsOrderDateHidden] = useState(true);
  const [isLotNoHidden, setIsLotNoHidden] = useState(true);
  const [isExpDateHidden, setIsExpDateHidden] = useState(true);

  useEffect(() => {
    let userId: string = "";
    let userName: string = "";
    if (user) {
      userId = user.id;
      userName = user.name;
    }

    // context 변수로부터 element에 복사
    setType(usage.type);
    setDate(usage.date);
    setRegistrant(usage.registrant);
    setItemId(usage.itemId);
    setItemName(usage.itemName);
    setCategory(usage.category);
    setPurchaseNo(usage.purchaseNo);
    setOrderDate(usage.orderDate);
    setUsageUnit(usage.usageUnit);
    let usageUnitPriceF: number = parseFloat(usage.usageUnitPrice);
    if (Number.isNaN(usageUnitPriceF)) setUsageUnitPriceL("0");
    else setUsageUnitPriceL(usageUnitPriceF.toLocaleString());
    setQuantity("0");
    setLotNo("");
    setPurpose("");
    setExpDate("");
    setNote("");
    setTag(usage.tag);
    // 입고 / 사용 / 폐기 / 실재고 확인
    if (usage.type === "입고") {
      setIsPurposeHidden(true);
      setIsOrderDateHidden(false);
      setIsLotNoHidden(false);
      setIsExpDateHidden(false);
    }
    if (usage.type === "사용") {
      setIsPurposeHidden(false);
      setIsOrderDateHidden(true);
      setIsLotNoHidden(true);
      setIsExpDateHidden(true);
    }
  }, []);

  function onTypeTextBoxValueChanged(e: string) {
    setType(e);
  }
  function onDateTextBoxValueChanged(e: string) {
    setDate(e);
  }
  function onRegistrantTextBoxValueChanged(e: string) {
    setRegistrant(e);
  }
  function onItemIdTextBoxValueChanged(e: string) {
    setItemId(e);
  }
  function onItemNameTextBoxValueChanged(e: string) {
    setItemName(e);
  }
  function onCategoryTextBoxValueChanged(e: string) {
    setCategory(e);
  }
  function onOrderDateTextBoxValueChanged(e: string) {
    setOrderDate(e);
  }
  function onUsageUnitTextBoxValueChanged(e: string) {
    setUsageUnit(e);
  }
  function onQuantityTextBoxValueChanged(e: string) {
    setQuantity(e);
  }
  function onUsageUnitPriceTextBoxValueChanged(e: string) {
    setUsageUnitPriceL(e);
  }
  function onLotNoTextBoxValueChanged(e: string) {
    setLotNo(e);
  }
  function onPurposeTextBoxValueChanged(e: string) {
    setPurpose(e);
  }
  function onExpDateTextBoxValueChanged(e: string) {
    setExpDate(e);
  }
  function onNoteTextBoxValueChanged(e: string) {
    setNote(e);
  }

  //////////////// Upload Button
  async function onUploadButtonClicked() {
    // 구매수량 0 이상 체크
    let quantityN: number = parseInt(quantity);
    if (Number.isNaN(quantityN)) {
      alert("수량에 숫자를 입력하세요!", "Error");
      return;
    }
    if (quantityN < 1) {
      alert("수량에 숫자를 입력하세요!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string or Localed string에서 int or float으로 변환 후 upload
    let usageUnitPriceStr: string = usageUnitPriceL.replaceAll(",", "");
    let usageUnitPriceF: number = parseFloat(usageUnitPriceStr);
    if (Number.isNaN(usageUnitPriceF)) usageUnitPriceF = 0;

    let dateN: number = parseInt(date);
    if (Number.isNaN(dateN)) dateN = 0;

    let expDateN: any = parseInt(expDate);
    if (Number.isNaN(expDateN)) expDateN = null;

    let totalPriceF: number = quantityN * usageUnitPriceF;
    // type: 입고, 사용, 실재고 확인, 폐기
    if (type === "실재고 확인") totalPriceF = 0;
    let purchaseNoN: number = parseInt(purchaseNo);
    if (Number.isNaN(purchaseNoN)) purchaseNoN = 0;

    let orderDateN: number = parseInt(orderDate);
    if (Number.isNaN(orderDateN)) orderDateN = 0;

    let obj: Object = {
      type: type,
      date: dateN,
      registrant: registrant,
      itemId: itemId,
      itemName: itemName,
      category: category,
      purchaseNo: purchaseNoN,
      orderDate: orderDateN,
      usageUnit: usageUnit,
      quantity: quantityN,
      usageUnitPrice: usageUnitPriceF,
      totalPrice: totalPriceF,
      lotNo: lotNo,
      purpose: purpose,
      expDate: expDateN,
      note: note,
    };
    await uploadJson("/inventory/uploadusage", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        // 입고의 경우 부분입고 / 입고완료 를 purchaseRequest에 표기해줌
        if (type === "입고") {
          setDeliveredPurchaseRequest("/purchase/deliveredrequest", purchaseNoN, tag, U.todayEightDigitIntDate()).then((response_status: number) => {
            // 성공한 경우만 go back
            navigate(-1);
          });
        } else navigate(-1);
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>입출고 등록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="분류"
                readOnly={true}
                value={type}
                valueChangeEvent="keyup"
                onValueChange={onTypeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="품명"
                readOnly={true}
                value={itemName}
                valueChangeEvent="keyup"
                onValueChange={onItemNameTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <TextBox
                  label="수량"
                  value={quantity}
                  valueChangeEvent="keyup"
                  onValueChange={onQuantityTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>
              <div className={"flex-item4"}>
                <TextBox
                  label="재고단위"
                  readOnly={true}
                  value={usageUnit}
                  valueChangeEvent="keyup"
                  onValueChange={onUsageUnitTextBoxValueChanged}
                  width={145}
                  showClearButton={true}
                />
              </div>
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="재고단위단가"
                readOnly={true}
                value={usageUnitPriceL}
                valueChangeEvent="keyup"
                onValueChange={onUsageUnitPriceTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"} hidden={isPurposeHidden}>
              <TextBox
                label="사용목적"
                value={purpose}
                valueChangeEvent="keyup"
                onValueChange={onPurposeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="등록일"
                readOnly={true}
                value={date}
                valueChangeEvent="keyup"
                onValueChange={onDateTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="등록자"
                readOnly={true}
                value={registrant}
                valueChangeEvent="keyup"
                onValueChange={onRegistrantTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="허가품목코드"
                readOnly={true}
                value={itemId}
                valueChangeEvent="keyup"
                onValueChange={onItemIdTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"} hidden={isOrderDateHidden}>
              <TextBox
                label="발주일"
                readOnly={true}
                value={orderDate}
                valueChangeEvent="keyup"
                onValueChange={onOrderDateTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"} hidden={isLotNoHidden}>
              <TextBox label="Lot No." value={lotNo} valueChangeEvent="keyup" onValueChange={onLotNoTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"} hidden={isExpDateHidden}>
              <TextBox
                label="유효기간"
                value={expDate}
                valueChangeEvent="keyup"
                onValueChange={onExpDateTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="비고" value={note} valueChangeEvent="keyup" onValueChange={onNoteTextBoxValueChanged} width={300} showClearButton={true} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
