import "./iotEdit.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useAuth } from "../../contexts/auth";
import { useIotDevice } from "../../contexts/iotDevice";
import { useNavigate } from "react-router-dom";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { alert, confirm } from "devextreme/ui/dialog";
import { uploadJson } from "../../api/auth";
import * as U from "../../utils";

export default function NewTxSample() {
  const { user } = useAuth();
  const iotDeviceContext = useIotDevice();
  const navigate = useNavigate();

  const [serial, setSerial] = useState("");
  const [location, setLocation] = useState("");

  useEffect(() => {
    if (iotDeviceContext) {
      setSerial(iotDeviceContext.serial);
      setLocation(iotDeviceContext.location);
    }
  }, []);

  function onLocationTextBoxValueChanged(e: string) {
    setLocation(e);
  }
  async function onUploadButtonClicked() {
    let obj: Object = {
      serial: serial,
      location: location,
    };

    await uploadJson("/iot/updateiotdevicesetting", obj).then((response_status: number) => {
      console.log(response_status);
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Edit IoT Device</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="Serial" value={serial} readOnly={true} width={300} />
            </div>

            <div className={"flex-containerH2"}>
              <div className={"flex-item4"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={145} type="success" icon="upload" />
              </div>
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="Location"
                value={location}
                valueChangeEvent="keyup"
                onValueChange={onLocationTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
