import { Routes, Route, Navigate } from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import { Footer } from "./components";

export default function Content() {
  // rendering에서 SideNavBarLayout을 호출하면서 title은 appInfo.title을 사용하고 children은 그 아래에 나열한 항목들이 입력 파라미터로 전달한다.
  // 즉, menu의 항목들은 여기 children으로 나열된 element 들이다.
  return (
    <SideNavBarLayout title={appInfo.title}>
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route path="*" element={<Navigate to="/attendance/dashboard" />} />
      </Routes>
      <Footer>
        Copyright © 2011-{new Date().getFullYear()} Femtobiomed Inc.
        <br />
        All rights reserved.
      </Footer>
    </SideNavBarLayout>
  );
}
