import React from "react";
export default function FundPlan() {
  return (
    <React.Fragment>
      <h2 className={"content-block"}>법인자금계획</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <p>main</p>
        </div>
        <div className="widget-container"></div>
      </div>
    </React.Fragment>
  );
}
