import { createContext, useContext } from "react";
export type CsPartUsageContextType = {
  date: string;
  type: string;
  registrant: string;
  itemId: string;
  itemPartNumber: string;
  itemType: string;
  quantity: string;
  note: string;
  tag: string;
};
const CsPartUsageContext = createContext<CsPartUsageContextType>({} as CsPartUsageContextType);
const useCsPartUsage = () => useContext(CsPartUsageContext);
export { useCsPartUsage };
