import "./fundSlip.scss";
import React from "react";
import { useEffect, useState } from "react";
import * as U from "../../utils";
import { useNavigate } from "react-router-dom";
// context
import { useAuth } from "../../contexts/auth";
// api
import { queryJsonArray, deleteRowBetween } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function FundSlip() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(true);

  useEffect(() => {
    let acc_purch = 0;
    if (user) {
      setUserId(user.id);
      acc_purch = user.acc_purch;
    }
    // (purch) [11] slip R, [12] slip input & D
    if (U.bitAt(acc_purch, 12)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);
  }, []);

  function onFromTextBoxValueChanged(e: string) {
    setFilterFrom(e);
  }

  function onToTextBoxValueChanged(e: string) {
    setFilterTo(e);
  }

  function onSearchButtonClicked() {
    if (!U.isProperEightDigitStringDate(filterFrom)) {
      alert("Enter from date in proper format!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(filterTo)) {
      alert("Enter to date in proper format!", "Error");
      return;
    }
    let dateFrom: Number = parseInt(filterFrom);
    let dateTo: Number = parseInt(filterTo);

    let query = "SELECT * FROM slip";
    query += ` WHERE date >= '${dateFrom}'`;
    query += ` AND date <= '${dateTo}'`;
    query += " ORDER BY date Asc, resolutionNo Asc, turn Asc";
    queryJsonArray("/fund/queryslip", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function onBatchInputButtonClicked() {
    navigate("/fund/slipBatchInput");
  }

  function onDeleteSearchedItemsButtonClicked() {
    if (!U.isProperEightDigitStringDate(filterFrom)) {
      alert("Enter from date in proper format!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(filterTo)) {
      alert("Enter to date in proper format!", "Error");
      return;
    }
    let dateFrom: number = parseInt(filterFrom);
    let dateTo: number = parseInt(filterTo);
    let res = confirm(`${dateFrom} ~ ${dateTo}`, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowBetween("/fund/deleteslipbydaterange", dateFrom, dateTo);
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>전표</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                placeholder="From (YYYYMMDD)"
                valueChangeEvent="keyup"
                onValueChange={onFromTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <TextBox
                placeholder="To (YYYYMMDD)"
                valueChangeEvent="keyup"
                onValueChange={onToTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="Batch Input" onClick={onBatchInputButtonClicked} visible={isDeleteButtonVisible} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete searched items" onClick={onDeleteSearchedItemsButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="date" caption="결의일" />
              <Column dataField="resolutionNo" caption="결의No" />
              <Column dataField="turn" caption="순번" />
              <Column dataField="debit" caption="차변" dataType="number" format="#,##0" />
              <Column dataField="accountCode" caption="계정코드" />
              <Column dataField="accountName" caption="계정과목명" />
              <Column dataField="briefs" caption="적요" />
              <Column dataField="credit" caption="대변" dataType="number" format="#,##0" />
              <Column dataField="companyCode" caption="거래처코드" />
              <Column dataField="companyName" caption="거래처명" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="date" summaryType="count" valueFormat="#,##0" />
                <TotalItem column="debit" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="credit" summaryType="sum" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
