import "./complianceNew.scss";
import React from "react";
import { useEffect, useState } from "react";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { generateCompliance } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { CheckBox } from "devextreme-react/check-box";

export default function ComplianceNew() {
  const { user } = useAuth();

  const [userId, setUserId] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [department, setDepartment] = useState<string>("");

  const [yes1, setYes1] = useState(false);
  const [yes2, setYes2] = useState(false);
  const [yes3, setYes3] = useState(false);
  const [yes4, setYes4] = useState(false);
  const [yes5, setYes5] = useState(false);
  const [yes6, setYes6] = useState(false);
  const [yes7, setYes7] = useState(false);
  const [no1, setNo1] = useState(false);
  const [no2, setNo2] = useState(false);
  const [no3, setNo3] = useState(false);
  const [no4, setNo4] = useState(false);
  const [no5, setNo5] = useState(false);
  const [no6, setNo6] = useState(false);
  const [no7, setNo7] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;
    let acc_admin = 0;
    if (user) {
      acc_admin = user.acc_admin;
      setUserId(user.id);
      setUserName(user.name);
      setDepartment(user.department);
    }
  }, []);

  async function onGenerateButtonClicked() {
    if (yes1 && yes2 && no3 && no4 && no5 && no5 && no7) {
      alert("PDF 파일을 생성해서 서버로 업로드하였습니다.", "성공");
      let eightDigitDateString = U.todayEightDigitStringDate();
      generateCompliance(userId, userName, department, eightDigitDateString);
    } else {
      alert("체크리스트를 다시 확인하세요!", "오류");
    }
  }

  function onYes1CheckBoxValueChanged(e: boolean) {
    setYes1(e);
    if (e === true) setNo1(false);
  }
  function onYes2CheckBoxValueChanged(e: boolean) {
    setYes2(e);
    if (e === true) setNo2(false);
  }
  function onYes3CheckBoxValueChanged(e: boolean) {
    setYes3(e);
    if (e === true) setNo3(false);
  }
  function onYes4CheckBoxValueChanged(e: boolean) {
    setYes4(e);
    if (e === true) setNo4(false);
  }
  function onYes5CheckBoxValueChanged(e: boolean) {
    setYes5(e);
    if (e === true) setNo5(false);
  }
  function onYes6CheckBoxValueChanged(e: boolean) {
    setYes6(e);
    if (e === true) setNo6(false);
  }
  function onYes7CheckBoxValueChanged(e: boolean) {
    setYes7(e);
    if (e === true) setNo7(false);
  }

  function onNo1CheckBoxValueChanged(e: boolean) {
    setNo1(e);
    if (e === true) setYes1(false);
  }
  function onNo2CheckBoxValueChanged(e: boolean) {
    setNo2(e);
    if (e === true) setYes2(false);
  }
  function onNo3CheckBoxValueChanged(e: boolean) {
    setNo3(e);
    if (e === true) setYes3(false);
  }
  function onNo4CheckBoxValueChanged(e: boolean) {
    setNo4(e);
    if (e === true) setYes4(false);
  }
  function onNo5CheckBoxValueChanged(e: boolean) {
    setNo5(e);
    if (e === true) setYes5(false);
  }
  function onNo6CheckBoxValueChanged(e: boolean) {
    setNo6(e);
    if (e === true) setYes6(false);
  }
  function onNo7CheckBoxValueChanged(e: boolean) {
    setNo7(e);
    if (e === true) setYes7(false);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>임직원 내부통제 준수 확인서</h2>

      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>본인은 다음과 같이 확인합니다. </p>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>1. 법, 규정 준수 </p>
          </div>
          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>업무를 수행하면서 관련 법규, 약관 혹은 내부규정 등을 준수하고 위반사항이 있을 경우 수시 보고하고 있습니까? </p>
          </div>
          <div className={"flex-containerH"}>
            <div className={"flex-item3"}>
              <CheckBox text="예" value={yes1} onValueChange={onYes1CheckBoxValueChanged} width={300} />
            </div>
            <div className={"flex-item3"}>
              <CheckBox text="아니오" value={no1} onValueChange={onNo1CheckBoxValueChanged} width={300} />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>2. 업무의 공정성 유지 </p>
          </div>
          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>업무를 수행함에 있어 개인적인 이해관계에 얽매이지 않고 공정하게 업무 처리를 하고 있습니까? </p>
          </div>
          <div className={"flex-containerH"}>
            <div className={"flex-item3"}>
              <CheckBox text="예" value={yes2} onValueChange={onYes2CheckBoxValueChanged} width={300} />
            </div>
            <div className={"flex-item3"}>
              <CheckBox text="아니오" value={no2} onValueChange={onNo2CheckBoxValueChanged} width={300} />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>3. 비밀정보 무단 유출 제한 </p>
          </div>
          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>업무 처리 중 취득한 회사 및 고객에 대한 비밀 정보를 외부에 유출한 적이 있습니까?</p>
          </div>
          <div className={"flex-containerH"}>
            <div className={"flex-item3"}>
              <CheckBox text="예" value={yes3} onValueChange={onYes3CheckBoxValueChanged} width={300} />
            </div>
            <div className={"flex-item3"}>
              <CheckBox text="아니오" value={no3} onValueChange={onNo3CheckBoxValueChanged} width={300} />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>4. 미공개중요정보 제공 금지 </p>
          </div>
          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>회사내 미공개중요정보라고 의심되는 정보를 사전 승인없이 제3자에게 제공한 적이 있습니까?</p>
          </div>
          <div className={"flex-containerH"}>
            <div className={"flex-item3"}>
              <CheckBox text="예" value={yes4} onValueChange={onYes4CheckBoxValueChanged} width={300} />
            </div>
            <div className={"flex-item3"}>
              <CheckBox text="아니오" value={no4} onValueChange={onNo4CheckBoxValueChanged} width={300} />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>5. 재산상 이익의 제공 및 수령 제한</p>
          </div>
          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>업무 수행과 관련하여 재산상 이익을 제공 또는 수령한 적이 있습니까?</p>
          </div>
          <div className={"flex-containerH"}>
            <div className={"flex-item3"}>
              <CheckBox text="예" value={yes5} onValueChange={onYes5CheckBoxValueChanged} width={300} />
            </div>
            <div className={"flex-item3"}>
              <CheckBox text="아니오" value={no5} onValueChange={onNo5CheckBoxValueChanged} width={300} />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>6. 업무관련 대외활동 제한</p>
          </div>
          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>회사에 사전 신고 없이 언론매체의 인터뷰에 개인적으로 응하거나, 투고 또는 외부 강연, 교육 등을 한 적이 있습니까?</p>
          </div>
          <div className={"flex-containerH"}>
            <div className={"flex-item3"}>
              <CheckBox text="예" value={yes6} onValueChange={onYes6CheckBoxValueChanged} width={300} />
            </div>
            <div className={"flex-item3"}>
              <CheckBox text="아니오" value={no6} onValueChange={onNo6CheckBoxValueChanged} width={300} />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>7. 임직원의 미공개중요정보 이용한 개인투자 금지</p>
          </div>
          <div className={"flex-containerTitle"}>
            <p className={"text-medium"}>회사내 미공개중요정보를 활용하여 주식 또는 주식관련 금융상품에 투자한 경우가 있습니까?</p>
          </div>
          <div className={"flex-containerH"}>
            <div className={"flex-item3"}>
              <CheckBox text="예" value={yes7} onValueChange={onYes7CheckBoxValueChanged} width={300} />
            </div>
            <div className={"flex-item3"}>
              <CheckBox text="아니오" value={no7} onValueChange={onNo7CheckBoxValueChanged} width={300} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item3"}>
              <Button text="PDF 생성 및 업로드" onClick={onGenerateButtonClicked} type="default" icon="upload" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
