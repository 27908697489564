export default {
  avatarUrl: "",
  affiliation: "",
  id: "",
  name: "",
  department: "",
  position: "",
  email: "",
  location: "",
  acc_admin: 0,
  acc_purch: 0,
  acc_cs: 0,
  acc_proj: 0,
};
