import "./projectDocumentNew.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProjectDocument } from "../../contexts/projectDocument";
// api
import { uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";

export default function ProjectDocumentNew() {
  const { user } = useAuth();
  const projectDocumentContext = useProjectDocument();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("Document");
  const [no, setNo] = useState(-1);
  const [projectNo, setProjectNo] = useState(-1);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [ext, setExt] = useState("");
  const [writer, setWriter] = useState("");
  const [approval, setApproval] = useState("");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");
  const [linkClipBoard, setLinkClipBoard] = useState("");

  useEffect(() => {
    setPageTitle("New Document");
    setProjectNo(projectDocumentContext.projectNo);
    if (projectDocumentContext.no >= 0) {
      // edit 버튼으로 진입한 경우: context 변수로부터 element에 복사
      setPageTitle("Edit Document");
      setNo(projectDocumentContext.no);

      setName(projectDocumentContext.name);
      setType(projectDocumentContext.type);
      setExt(projectDocumentContext.ext);
      setWriter(projectDocumentContext.writer);
      setApproval(projectDocumentContext.approval);
      setLink1(projectDocumentContext.link1);
      setLink2(projectDocumentContext.link2);
      setLink3(projectDocumentContext.link3);
    }
  }, []);

  function onNameTextBoxValueChanged(e: string) {
    setName(e);
  }
  function onTypeTextBoxValueChanged(e: string) {
    setType(e);
  }
  function onExtSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setExt(str);
  }
  function onWriterTextBoxValueChanged(e: string) {
    setWriter(e);
  }
  function onApprovalTextBoxValueChanged(e: string) {
    setApproval(e);
  }
  function onLink1TextBoxValueChanged(e: string) {
    setLink1(e);
  }
  function onLink2TextBoxValueChanged(e: string) {
    setLink2(e);
  }
  function onLink3TextBoxValueChanged(e: string) {
    setLink3(e);
  }
  function onLinkClipBoardTextAreaValueChanged(e: string) {
    setLinkClipBoard(e);
  }

  ///////////////////////////// Upload Button
  async function onUploadButtonClicked() {
    if (name.length === 0) {
      alert("Task 이름을 입력하세요!", "Error");
      return;
    }
    if (type.length === 0) {
      alert("Type을 입력하세요!", "Error");
      return;
    }
    if (ext.length === 0) {
      alert("파일종류를 입력하세요!", "Error");
      return;
    }
    if (writer.length === 0) {
      alert("작성자를 입력하세요!", "Error");
      return;
    }

    // 링크 분석
    let l1: string = "";
    let l2: string = "";
    let l3: string = "";

    if (no === -1) {
      // new 인 경우
      if (linkClipBoard.length === 0) {
        alert("Link를 입력하세요!", "Error");
        return;
      }

      if (ext === "Google Docs") {
        l1 = linkClipBoard;
        l2 = l1;
        l3 = l1;
      } else {
        // 클라우디움의 경우 clipBoard를 분석해서 link 3개를 추출한다.
        // analyze cloudium link string
        let result = U.analyzeCloudiumLink(linkClipBoard);
        let isLinkOk: boolean = result[0] as boolean;
        if (!isLinkOk) {
          alert("적절한 클라우디움 link를 입력하세요!", "Error");
          return;
        }
        l1 = result[1] as string;
        l2 = result[2] as string;
        l3 = result[3] as string;
      }
    } else {
      // edit인 경우 clipBoard에 내용이 있으면 분석하고 없으면 기존 link를 그대로 사용하도록 넘어간다.
      if (linkClipBoard.length > 0) {
        if (ext === "Google Docs") {
          l1 = linkClipBoard;
          l2 = l1;
          l3 = l1;
        } else {
          // analyze cloudium link string
          let result = U.analyzeCloudiumLink(linkClipBoard);
          let isLinkOk: boolean = result[0] as boolean;
          if (!isLinkOk) {
            alert("적절한 클라우디움 link를 입력하세요!", "Error");
            return;
          }
          l1 = result[1] as string;
          l2 = result[2] as string;
          l3 = result[3] as string;
        }
      } else {
        // linkClipBoard가 비어 있다면 기존의 정보를 그대로 유지
        l1 = projectDocumentContext.link1;
        l2 = projectDocumentContext.link2;
        l3 = projectDocumentContext.link3;
      }
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let name2: string = U.replaceBrace(name);
    let type2: string = U.replaceBrace(type);
    let ext2: string = U.replaceBrace(ext);
    let writer2: string = U.replaceBrace(writer);
    let approval2: string = U.replaceBrace(approval);

    let obj: Object = {
      no: no,
      projectNo: projectNo,
      name: name2,
      type: type2,
      ext: ext2,
      writer: writer2,
      approval: approval2,
      link1: l1,
      link2: l2,
      link3: l3,
    };

    await uploadJson("/project/uploaddocument", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        // 22: Same id exists when upload new item
        alert("같은 이름의 document가 이미 존재합니다!", "Error");
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="Document 이름"
                value={name}
                valueChangeEvent="keyup"
                onValueChange={onNameTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Type" value={type} valueChangeEvent="keyup" onValueChange={onTypeTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <SelectBox
                label="파일종류"
                dataSource={["Word", "Excel", "PowerPoint", "PDF", "한글", "Image", "Movie", "Google Docs"]}
                value={ext}
                width={300}
                onSelectionChanged={onExtSelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="작성자" value={writer} valueChangeEvent="keyup" onValueChange={onWriterTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="승인내역"
                value={approval}
                valueChangeEvent="keyup"
                onValueChange={onApprovalTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"}>
              <TextArea
                label="Link (클라우디움의 내부 배포용 URL 을 복사해서 붙여넣기)"
                onValueChange={onLinkClipBoardTextAreaValueChanged}
                value={linkClipBoard}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
