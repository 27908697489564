import * as U from "../utils";

export const decodeSlipInput = (text: string) => {
  let id: string = "";
  let date: number = 0;
  let resolutionNo: number = 0;
  let turn: number = 0;
  let companyCode: number = 0;
  let companyName: string = "";
  let debit: number = 0;
  let credit: number = 0;
  let accountCode: number = 0;
  let accountName: string = "";
  let briefs: string = "";

  let json = new Array<Object>();

  let lines = text.split("\n");
  console.log("lines N:", lines.length);
  let totalN = lines.length; // block 갯수가 작은 줄은 제외하고 계산
  let lossId = "";
  lines.map((line, idx) => {
    // JSON.parse 과정에서 오류를 일으키는 문자들을 미리 제거
    line = line.replaceAll("\\", "원화"); // 원화
    line = line.replaceAll("¥", "엔화"); // 엔화, 위안 (기호 같음)
    line = line.replaceAll("$", "달러"); // Dollar
    line = line.replaceAll("€", "유로"); // Euro
    line = line.replaceAll("£", "파운드"); // Pound
    line = line.replaceAll('"', ""); // "
    // (초기형식)             [0]결의일 [1]결의No [2]순번 [3]기표일자 [4]기표      [5]구분 [6]거래처코드 [7]거래처명    [8]차변          [9]계정코드 [10]계정과목명 [11]적요 [12]대변
    // (2024년 이후 형식) [0]결의일 [1]결의No [2]순번 [3]기표일자 [4]기표번호 [5]구분 [6]계정코드    [7]계정과목명 [8]거래처코드 [9]거래처명 [10]적요          [11]차변 [12]대변
    // (초기형식) line: 2022/01/01	1	1	2022/01/01	1	대체	99708	기업비씨[7992]	400	83101	지급수수료_금융수수료	[기업 7992]SMS 이용료(12월분)
    let blocks = line.split("\t");
    if (blocks.length < 13) {
      console.log("short string at ", idx);
      totalN--;
      return;
    }
    let dateStr = blocks[0];
    let tmpId = blocks[0] + "-" + blocks[1] + "-" + blocks[2];
    date = U.eightDigitIntDateFromString(blocks[0]); // 2022/01/01
    if (date === 0) {
      console.log(tmpId);
      lossId = tmpId;
      return;
    }
    resolutionNo = parseInt(blocks[1]);
    if (Number.isNaN(resolutionNo)) {
      console.log(tmpId);
      lossId = tmpId;
      return;
    }
    turn = parseInt(blocks[2]);
    if (Number.isNaN(turn)) {
      console.log(tmpId);
      lossId = tmpId;
      return;
    }
    id = `${date}-${resolutionNo}-${turn}`;

    accountCode = parseInt(blocks[6]);
    if (Number.isNaN(accountCode)) accountCode = 0;

    accountName = blocks[7];

    companyCode = parseInt(blocks[8]);
    if (Number.isNaN(companyCode)) companyCode = 0;

    companyName = blocks[9];

    briefs = blocks[10];

    if (blocks[11].length === 0) debit = 0;
    else debit = parseFloat(blocks[11].replaceAll(",", ""));
    if (Number.isNaN(debit)) {
      console.log(tmpId);
      lossId = tmpId;
      return;
    }

    if (blocks[12].length === 0) credit = 0;
    else credit = parseFloat(blocks[12].replaceAll(",", ""));
    if (Number.isNaN(credit)) {
      console.log(tmpId);
      lossId = tmpId;
      return;
    }

    let obj = slipObject(id, date, resolutionNo, turn, companyCode, companyName, debit, credit, accountCode, accountName, briefs);
    if (obj) json.push(obj);
    else {
      console.log(tmpId);
      lossId = tmpId;
    }
  });

  console.log("json N:", json.length);
  let lossCount: number = totalN - json.length;
  console.log("loss N: ", lossCount);

  return [json, json.length, lossCount, lossId];
};

const slipObject = (
  id: string,
  date: number,
  resolutionNo: number,
  turn: number,
  companyCode: number,
  companyName: string,
  debit: number,
  credit: number,
  accountCode: number,
  accountName: string,
  briefs: string
) => {
  let str = `{"id":"${id}","date":${date},"resolutionNo":${resolutionNo},"turn":${turn},"companyCode":${companyCode},"companyName":"${companyName}","debit":${debit},"credit":${credit},"accountCode":${accountCode},"accountName":"${accountName}","briefs":"${briefs}"}`;
  //console.log(str);
  let obj: Object = new Object();
  let success = false;
  try {
    obj = JSON.parse(str);
    success = true;
  } catch (err) {}
  if (success) return obj;
  // 실패하면 적요 항목에 특수문자로 인한 확율이 크므로 적요를 없애서 재시도한다.
  console.log("second try: ", id);
  str = `{"id":"${id}","date":${date},"resolutionNo":${resolutionNo},"turn":${turn},"companyCode":${companyCode},"companyName":"${companyName}","debit":${debit},"credit":${credit},"accountCode":${accountCode},"accountName":"${accountName}","briefs":""}`;
  try {
    obj = JSON.parse(str);
    success = true;
  } catch (err) {}
  if (success) return obj;
  console.log("failed to convert to json: ", str);
  return null;
};

export const decodeShareholderInput = (text: string) => {
  let listSubstantialClassification: number = 0;
  let whetherToSum: number = 0;
  let companyNumber: number = 0;
  let rightsBaseYearMonthDate: number = 0;
  let shareholderNumber: string = "";
  let shareholderClassificationNumber: number = 0;
  let realAccountNumber: string = "";
  let shareholderName: string = "";
  let shareholderClassification: number = 0;
  let countryCode: number = 0;
  let zipCode: string = "";
  let shareholderAddress: string = "";
  let numberOfConfirmedShares: number = 0;
  let commonStock: number = 0;
  let preferredStock: number = 0;
  let twoPreferredStock: number = 0;

  let json = new Array<Object>();

  let lines = text.split("\n");
  console.log("lines N:", lines.length);
  let totalN = lines.length; // block 갯수가 작은 줄은 제외하고 계산

  console.log("totalN: ", totalN);
  lines.map((line, idx) => {
    console.log(idx, line);
    // JSON.parse 과정에서 오류를 일으키는 문자들을 미리 제거
    line = line.replaceAll("\\", "원화"); // 원화
    line = line.replaceAll("¥", "엔화"); // 엔화, 위안 (기호 같음)
    line = line.replaceAll("$", "달러"); // Dollar
    line = line.replaceAll("€", "유로"); // Euro
    line = line.replaceAll("£", "파운드"); // Pound
    // line: 2022/01/01	1	1	2022/01/01	1	대체	99708	기업비씨[7992]	400	83101	지급수수료_금융수수료	[기업 7992]SMS 이용료(12월분)
    let blocks = line.split("\t");
    console.log(blocks.length);
    console.log(blocks);
    if (blocks.length < 16) {
      console.log("short string at ", idx);
      totalN--;
      return;
    }

    listSubstantialClassification = U.intFromString(blocks[0], 0);
    whetherToSum = U.intFromString(blocks[1], 0);
    companyNumber = U.intFromString(blocks[2], 0);
    rightsBaseYearMonthDate = parseInt(blocks[3]);
    if (Number.isNaN(rightsBaseYearMonthDate)) {
      console.log(blocks[3]);
      return;
    }
    if (!U.isProperEightDigitIntDate(rightsBaseYearMonthDate)) {
      console.log(blocks[3]);
      return;
    }

    shareholderNumber = blocks[4];
    shareholderClassificationNumber = U.intFromString(blocks[5], 0);
    realAccountNumber = blocks[6];
    shareholderName = blocks[7];
    shareholderClassification = U.intFromString(blocks[8], 0);
    countryCode = U.intFromString(blocks[9], 0);
    zipCode = blocks[10];
    shareholderAddress = blocks[11];
    numberOfConfirmedShares = U.intFromString(blocks[12], 0);
    commonStock = U.intFromString(blocks[13], 0);
    preferredStock = U.intFromString(blocks[14], 0);
    twoPreferredStock = U.intFromString(blocks[15], 0);
    let obj = shareholderObject(
      listSubstantialClassification,
      whetherToSum,
      companyNumber,
      rightsBaseYearMonthDate,
      shareholderNumber,
      shareholderClassificationNumber,
      realAccountNumber,
      shareholderName,
      shareholderClassification,
      countryCode,
      zipCode,
      shareholderAddress,
      numberOfConfirmedShares,
      commonStock,
      preferredStock,
      twoPreferredStock
    );
    if (obj) json.push(obj);
    else {
      console.log("Failed to generate json jboect: ", idx);
    }
  });

  console.log("json N:", json.length);
  let lossCount = totalN - json.length;
  console.log("loss N: ", lossCount);

  return [json, json.length, lossCount];
};

const shareholderObject = (
  listSubstantialClassification: number,
  whetherToSum: number,
  companyNumber: number,
  rightsBaseYearMonthDate: number,
  shareholderNumber: string,
  shareholderClassificationNumber: number,
  realAccountNumber: string,
  shareholderName: string,
  shareholderClassification: number,
  countryCode: number,
  zipCode: string,
  shareholderAddress: string,
  numberOfConfirmedShares: number,
  commonStock: number,
  preferredStock: number,
  twoPreferredStock: number
) => {
  // ,"string":"${string}"
  // ,"number":${number}
  // ,"shareholderAddress":"${shareholderAddress}"
  // ,"twoPreferredStock":${twoPreferredStock}
  let str = `{"listSubstantialClassification":${listSubstantialClassification},"whetherToSum":${whetherToSum},"companyNumber":${companyNumber},"rightsBaseYearMonthDate":${rightsBaseYearMonthDate},"shareholderNumber":"${shareholderNumber}","shareholderClassificationNumber":${shareholderClassificationNumber},"realAccountNumber":"${realAccountNumber}","shareholderName":"${shareholderName}","shareholderClassification":${shareholderClassification},"countryCode":${countryCode},"zipCode":"${zipCode}","shareholderAddress":"${shareholderAddress}","numberOfConfirmedShares":${numberOfConfirmedShares},"commonStock":${commonStock},"preferredStock":${preferredStock},"twoPreferredStock":${twoPreferredStock}}`;
  let obj: Object = new Object();
  let success = false;
  try {
    obj = JSON.parse(str);
    success = true;
  } catch (err) {}
  if (success) return obj;
  return null;
};

export const analyzeSlipAndFundList = (listJson: Array<Object>, slipJson: Array<Object>) => {
  let listN: number = listJson.length;
  let slipN: number = slipJson.length;

  // slip으로부터 집계한 값을 기록할 어레이 생성
  let sum: number[][] = new Array(listN);
  for (let row = 0; row < listN; row++) {
    sum[row] = new Array(13); // [0] sum, [1] jan, [2] feb, ... , [12] dec
    for (let col = 0; col < 13; col++) sum[row][col] = 0;
  }

  // fundList로부터 accounts를 떼어내서 accounts[][] 배열에 기록
  // 예시 "D+801,C-51103"
  let accounts: string[][] = new Array(listN);
  for (let row = 0; row < listN; row++) {
    accounts[row] = new Array();
    let accountsStr: string = U.stringValueFromJson(listJson[row], "accounts");
    let splitted = accountsStr.split(",");
    for (let splittedI = 0; splittedI < splitted.length; splittedI++) {
      let account: string = splitted[splittedI];
      if (account.length !== 0) accounts[row].push(account);
    }
  }

  // slip 으로부터 차변 or 대변을 집계해서 sum[][]에 기록한다.
  for (let slipI = 0; slipI < slipN; slipI++) {
    // date (결의일, YYYYMMDD), debit (차변), credit (대변), briefs (적요), accountCode (계정코드)
    let slipDate: number = U.intValueFromJson(slipJson[slipI], "date");
    let slipDebit: number = U.floatValueFromJson(slipJson[slipI], "debit");
    let slipCredit: number = U.floatValueFromJson(slipJson[slipI], "credit");
    let slipBriefs: string = U.stringValueFromJson(slipJson[slipI], "briefs"); // 적요
    let slipAccount: number = U.intValueFromJson(slipJson[slipI], "accountCode");
    let slipMonth: number = slipDate % 10000;
    let slipDay: number = slipDate % 100;
    slipMonth = (slipMonth - slipDay) / 100; // 1 ~ 12
    if (!Number.isInteger(slipMonth)) continue;
    if (slipMonth < 1 || slipMonth > 12) continue;

    for (let listI = 0; listI < listN; listI++) {
      // 집계설정의 각 account 별로 loop (가령 D+149,D+146,...)
      for (let accountI = 0; accountI < accounts[listI].length; accountI++) {
        // 주어진 slip ([slipI])의 계정코드가 fundList 각 항목 ([listI][accountI])에 속하는지 체크
        let belongs: boolean = false;
        let valueToAdd: number = 0.0;

        let listAccountStr: string = accounts[listI][accountI]; // "D+102"
        if (listAccountStr.length < 5) continue;
        let firstChar: string = listAccountStr.substring(0, 1).toUpperCase(); // "D" or "C"
        let secondChar: string = listAccountStr.substring(1, 2); // "+" or "-"
        let numberPartStr: string = listAccountStr.substring(2, listAccountStr.length);
        let listAccount: number = parseInt(numberPartStr);
        if (listAccount < 1000) {
          // account가 3자리 숫자인 경우
          let startListAccount: number = listAccount * 100;
          let endListAccount: number = startListAccount + 99;
          if (startListAccount <= slipAccount && slipAccount <= endListAccount) {
            belongs = true;
            if (firstChar === "C") valueToAdd = slipCredit;
            else valueToAdd = slipDebit;
            if (secondChar === "-") valueToAdd = -1.0 * valueToAdd;
          }
        } else {
          // account가 5자리 숫자인 경우
          if (listAccount === slipAccount) {
            belongs = true;
            if (firstChar === "C") valueToAdd = slipCredit;
            else valueToAdd = slipDebit;
            if (secondChar === "-") valueToAdd = -1.0 * valueToAdd;
          }
        }

        // 합산하기 전에 예외경우에 해당하는지 체크
        // 코드 = D+82312 & (적요문구 contains 주식매수선택권 or 스톡옵션 회계처리)
        if (belongs) {
          if (slipAccount === 82312) {
            if (slipBriefs.indexOf("주식매수선택권") >= 0) {
              console.log(slipAccount, slipBriefs, valueToAdd);
              belongs = false;
            }
            if (slipBriefs.indexOf("스톡옵션 회계처리") >= 0) {
              if (belongs) console.log(slipAccount, slipBriefs, valueToAdd);
              belongs = false;
            }
          }
        }

        // 합산
        if (belongs) {
          sum[listI][0] += valueToAdd;
          sum[listI][slipMonth] += valueToAdd;
        }
      }
    }
  }

  // 계산 결과를 json 형태로 반환
  let json = new Array<Object>();
  for (let listI = 0; listI < listN; listI++) {
    // id, sum, jan, feb, ...., dec
    let id: string = U.stringValueFromJson(listJson[listI], "id");
    let str = `{"id":"${id}","sum":${sum[listI][0]},"jan":${sum[listI][1]},"feb":${sum[listI][2]},"mar":${sum[listI][3]},"apr":${sum[listI][4]},"may":${sum[listI][5]},"jun":${sum[listI][6]},"jul":${sum[listI][7]},"aug":${sum[listI][8]},"sep":${sum[listI][9]},"oct":${sum[listI][10]},"nov":${sum[listI][11]},"dec":${sum[listI][12]}}`;
    let obj: Object = new Object();
    let success: boolean = false;
    try {
      obj = JSON.parse(str);
      success = true;
    } catch (err) {}
    if (success) json.push(obj);
  }
  return json;
};

export const decodeFundListInput = (text: string) => {
  let year: number = 0;
  let title: string = "";
  let accounts: string = "";

  let json = new Array<Object>();

  let lines = text.split("\n");
  console.log("lines N:", lines.length);
  let totalN = lines.length; // block 갯수가 작은 줄은 제외하고 계산
  let lossId = "";
  let lossCount: number = 0;
  lines.map((line, idx) => {
    // JSON.parse 과정에서 오류를 일으키는 문자들을 미리 제거
    line = line.replaceAll(" ", ""); // space 제거
    line = line.replaceAll("\\", "원화"); // 원화
    line = line.replaceAll("¥", "엔화"); // 엔화, 위안 (기호 같음)
    line = line.replaceAll("$", "달러"); // Dollar
    line = line.replaceAll("€", "유로"); // Euro
    line = line.replaceAll("£", "파운드"); // Pound
    let blocks = line.split("\t");
    if (blocks.length < 3) {
      console.log("short string at ", idx);
      totalN--;
      return;
    }
    let yearStr = blocks[0];
    let tmpId = blocks[0] + "-" + blocks[1] + "-" + blocks[2];
    if (!U.isProperFourDigitStringYear(yearStr)) {
      console.log(tmpId);
      lossId = tmpId;
      return;
    }
    year = parseInt(yearStr);
    title = blocks[1];
    accounts = blocks[2];
    let str = `{"year":"${year}","title":"${title}","accounts":"${accounts}"}`;
    let obj: Object = new Object();
    let success = false;
    try {
      obj = JSON.parse(str);
      success = true;
    } catch (err) {}
    if (success) json.push(obj);
  });

  console.log("json N:", json.length);
  lossCount = totalN - json.length;
  console.log("loss N: ", lossCount);

  return [json, json.length, lossCount, lossId];
};
