import "./csPartNew.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCsPart } from "../../contexts/csPart";
// api
import { uploadJson, uploadJsonAndAlert, uploadCsPartWithOrWithoutFile, overwriteCsPartWithOrWithoutFile } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";

export default function CsPartNew() {
  const { user } = useAuth();
  const csPart = useCsPart();
  const navigate = useNavigate();

  const [userId, setUserId] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [pageTitle, setPageTitle] = useState("");
  const [id, setId] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [type, setType] = useState("구매품");
  const [partNumber, setPartNumber] = useState("");
  const [isPartNumberReadOnly, setIsPartNumberReadOnly] = useState(false);
  const [partName, setPartName] = useState("");
  const [spec, setSpec] = useState("");
  const [site, setSite] = useState("");
  const [storage, setStorage] = useState("");
  const [price, setPrice] = useState("0");
  const [supplier, setSupplier] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contact, setContact] = useState("");
  const [hyperlink, setHyperlink] = useState("");
  const [manager, setManager] = useState("");
  const [note, setNote] = useState("");
  const [purpose, setPurpose] = useState("");
  const [file, setFile] = useState<File>();

  useEffect(() => {
    let userName: string = "";
    if (user) {
      setUserId(user.id);
      setUserName(user.name);
      userName = user.name;
    }
    if (csPart.id.length === 0) {
      // new 버튼으로 진입한 경우
      setPageTitle("셀샷부품 등록");
      setIsPartNumberReadOnly(false);
      setManager(userName);
    } else {
      // edit 버튼으로 진입한 경우: context 변수로부터 element에 복사
      setPageTitle("셀샷부품 편집");
      setIsPartNumberReadOnly(true);
      setId(csPart.id);
      setIsDisabled(csPart.isDisabled);
      setType(csPart.type);
      setPartNumber(csPart.partNumber);
      setPartName(csPart.partName);
      setSpec(csPart.spec);
      setSite(csPart.site);
      setStorage(csPart.storage);
      setPrice(csPart.price.toLocaleString());
      setSupplier(csPart.supplier);
      setContactPerson(csPart.contactPerson);
      setContact(csPart.contact);
      setHyperlink(csPart.hyperlink);
      setManager(csPart.manager);
      setNote(csPart.note);
      setPurpose(csPart.purpose);
    }
  }, []);

  function onIsDisabledCheckBoxValueChanged(e: boolean) {
    setIsDisabled(e);
  }
  function onTypeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setType(str);
  }
  function onPartNumberTextBoxValueChanged(e: string) {
    setPartNumber(e);
  }
  function onPartNameTextBoxValueChanged(e: string) {
    setPartName(e);
  }
  function onSpecTextBoxValueChanged(e: string) {
    setSpec(e);
  }
  function onSiteTextBoxValueChanged(e: string) {
    setSite(e);
  }
  function onStorageTextBoxValueChanged(e: string) {
    setStorage(e);
  }
  function onPriceTextBoxValueChanged(e: string) {
    setPrice(e);
  }
  function onSupplierTextBoxValueChanged(e: string) {
    setSupplier(e);
  }
  function onContactPersonTextBoxValueChanged(e: string) {
    setContactPerson(e);
  }
  function onContactTextBoxValueChanged(e: string) {
    setContact(e);
  }
  function onHyperlinkTextBoxValueChanged(e: string) {
    setHyperlink(e);
  }
  function onNoteTextBoxValueChanged(e: string) {
    setNote(e);
  }
  function onPurposeTextBoxValueChanged(e: string) {
    setPurpose(e);
  }

  // png file
  const onChangeImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      let f = e.target.files[0];
      setFile(f);
    }
  };

  function onUploadButtonClicked() {
    if (partNumber.length === 0) {
      alert("Part Number에 적절한 값을 입력하세요!", "Error");
      return;
    }
    if (id.length === 0) uploadNewPart();
    else uploadEditPart();
  }

  async function uploadNewPart() {
    let idDate: string = U.todayEightDigitStringDate();
    let priceF: number = U.floatFromString(price, -1);
    if (priceF < 0) {
      alert("단가에 적절한 값을 입력하세요!", "Error");
      return;
    }

    uploadCsPartWithOrWithoutFile(
      idDate,
      isDisabled,
      type,
      partNumber,
      partName,
      spec,
      site,
      storage,
      priceF,
      supplier,
      contactPerson,
      contact,
      hyperlink,
      manager,
      note,
      purpose,
      file
    );
    navigate(-1);
  }

  async function uploadEditPart() {
    let priceF: number = U.floatFromString(price, -1);
    if (priceF < 0) {
      alert("단가에 적절한 값을 입력하세요!", "Error");
      return;
    }
    overwriteCsPartWithOrWithoutFile(
      id,
      isDisabled,
      type,
      partNumber,
      partName,
      spec,
      site,
      storage,
      priceF,
      supplier,
      contactPerson,
      contact,
      hyperlink,
      manager,
      note,
      purpose,
      file
    );
    navigate(-1);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <SelectBox
                label="Type"
                dataSource={["구매품", "전자회로", "CNC", "사출품", "3D프린팅"]}
                value={type}
                width={300}
                onSelectionChanged={onTypeSelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Part Number"
                value={partNumber}
                readOnly={isPartNumberReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onPartNumberTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Part Name"
                value={partName}
                valueChangeEvent="keyup"
                onValueChange={onPartNameTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Spec" value={spec} valueChangeEvent="keyup" onValueChange={onSpecTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <CheckBox text="비사용" value={isDisabled} onValueChange={onIsDisabledCheckBoxValueChanged} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Site" value={site} valueChangeEvent="keyup" onValueChange={onSiteTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="보관위치"
                value={storage}
                valueChangeEvent="keyup"
                onValueChange={onStorageTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="단가 (원)" value={price} valueChangeEvent="keyup" onValueChange={onPriceTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="공급업체"
                value={supplier}
                valueChangeEvent="keyup"
                onValueChange={onSupplierTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="업체담당자"
                value={contactPerson}
                valueChangeEvent="keyup"
                onValueChange={onContactPersonTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="업체연락처"
                value={contact}
                valueChangeEvent="keyup"
                onValueChange={onContactTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Hyperlink"
                value={hyperlink}
                valueChangeEvent="keyup"
                onValueChange={onHyperlinkTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="사내담당자" value={manager} readOnly={true} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="사용용도"
                value={purpose}
                valueChangeEvent="keyup"
                onValueChange={onPurposeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Note" value={note} valueChangeEvent="keyup" onValueChange={onNoteTextBoxValueChanged} width={300} showClearButton={true} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <p>이미지 (png)</p>
            </div>
            <div className={"flex-item1"}>
              <input type="file" accept=".png" onChange={onChangeImg} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
