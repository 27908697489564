import "./projectDashboard.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
import { useProject } from "../../contexts/project";
// api
import { uploadJson, queryJsonArray, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
//import QRCode from "react-qr-code";
//import { Html5QrcodeScanner } from "html5-qrcode";

export default function ProjectDashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const projectContext = useProject();
  const userListContext = useUserList();

  const [isNewProjectButtonVisible, setIsNewProjectButtonVisible] = useState(false);
  const [isDeleteProjectButtonVisible, setIsDeleteProjectButtonVisible] = useState(false);

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedNo, setSelectedNo] = useState(-1);
  const [selectedName, setSelectedName] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();

  // DataGrid
  const [jsonDataSource2, setJsonDataSource2] = useState<Array<Object>>();
  const [selectedNo2, setSelectedNo2] = useState(-1);
  const [selectedName2, setSelectedName2] = useState("");
  const [selectedRow2, setSelectedRow2] = useState<Object>();

  const [jsonDataSource3, setJsonDataSource3] = useState<Array<Object>>();
  const [selectedNo3, setSelectedNo3] = useState(-1);
  const [selectedName3, setSelectedName3] = useState("");
  const [selectedRow3, setSelectedRow3] = useState<Object>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_proj = 0;
    if (user) {
      acc_proj = user.acc_proj;
    }
    // (proj) [0] project R (Dash), [1] W, [2] D
    if (U.bitAt(acc_proj, 1)) setIsNewProjectButtonVisible(true);
    if (U.bitAt(acc_proj, 2)) setIsDeleteProjectButtonVisible(true);
    search();
    search2();
    search3();
  }, []);

  function onSearchButtonClicked() {
    search();
  }
  function search() {
    let query = "SELECT * FROM project";
    query += " WHERE status = '진행 중'";
    query += " ORDER BY startDate Asc";
    queryJsonArray("/project/query", query).then((projectJson: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < projectJson.length; i++) {
        let oneRowJson: Object | null = projectJson[i];
        let startDate: number = U.intValueFromJson(oneRowJson, "startDate");
        let startDate2: string = U.stringDateFromEightDigitIntDate(startDate);
        oneRowJson = U.addStringToJson(oneRowJson, "startDate2", startDate2);
        if (!oneRowJson) continue;
        let scheduledEndDate: number = U.intValueFromJson(oneRowJson, "scheduledEndDate");
        let scheduledEndDate2: string = U.stringDateFromEightDigitIntDate(scheduledEndDate);
        oneRowJson = U.addStringToJson(oneRowJson, "scheduledEndDate2", scheduledEndDate2);
        if (!oneRowJson) continue;
        let endDate: number = U.intValueFromJson(oneRowJson, "endDate");
        let endDate2: string = U.stringDateFromEightDigitIntDate(endDate);
        oneRowJson = U.addStringToJson(oneRowJson, "endDate2", endDate2);
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      setJsonDataSource(newJsonData);
    });
  }
  function onNewButtonClicked() {
    projectContext.no = -1;
    navigate("/project/new");
  }

  function copyRowToProjectContext(row: Object) {
    projectContext.no = U.intValueFromJson(row, "no");
    projectContext.name = U.stringValueFromJson(row, "name");
    projectContext.status = U.stringValueFromJson(row, "status");
    projectContext.progress = U.intValueFromJson(row, "progress");
    projectContext.department = U.stringValueFromJson(row, "department");
    projectContext.manager = U.stringValueFromJson(row, "manager");
    projectContext.startDate = U.intValueFromJson(row, "startDate");
    projectContext.scheduledEndDate = U.intValueFromJson(row, "scheduledEndDate");
    projectContext.endDate = U.intValueFromJson(row, "endDate");
    projectContext.purpose = U.stringValueFromJson(row, "purpose");
    projectContext.abstract = U.stringValueFromJson(row, "abstract");
    projectContext.conclusion = U.stringValueFromJson(row, "conclusion");
    projectContext.cloudiumPath = U.stringValueFromJson(row, "cloudiumPath");
    projectContext.note = U.stringValueFromJson(row, "note");
    projectContext.updated_by = U.stringValueFromJson(row, "updated_by");
    projectContext.updated_at = U.dateValueFromJson(row, "updated_at");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyRowToProjectContext(selectedRow);
    navigate("/project/new");
  }

  function onDeleteButtonClicked() {
    if (selectedNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedName, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/project/deleteproject", selectedNo).then(() => {
          search();
        });
      } else {
        // No
      }
    });
  }
  function onDetailButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyRowToProjectContext(selectedRow);
    navigate("/project/description");
  }

  function onSearch2ButtonClicked() {
    search2();
  }
  function search2() {
    let query = "SELECT * FROM project";
    query += " WHERE status != '진행 중'";
    query += " AND status != 'Routine'";
    query += " ORDER BY endDate Asc";
    queryJsonArray("/project/query", query).then((projectJson: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < projectJson.length; i++) {
        let oneRowJson: Object | null = projectJson[i];
        let startDate: number = U.intValueFromJson(oneRowJson, "startDate");
        let startDate2: string = U.stringDateFromEightDigitIntDate(startDate);
        oneRowJson = U.addStringToJson(oneRowJson, "startDate2", startDate2);
        if (!oneRowJson) continue;
        let scheduledEndDate: number = U.intValueFromJson(oneRowJson, "scheduledEndDate");
        let scheduledEndDate2: string = U.stringDateFromEightDigitIntDate(scheduledEndDate);
        oneRowJson = U.addStringToJson(oneRowJson, "scheduledEndDate2", scheduledEndDate2);
        if (!oneRowJson) continue;
        let endDate: number = U.intValueFromJson(oneRowJson, "endDate");
        let endDate2: string = U.stringDateFromEightDigitIntDate(endDate);
        oneRowJson = U.addStringToJson(oneRowJson, "endDate2", endDate2);
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      setJsonDataSource2(newJsonData);
    });
  }
  function onEdit2ButtonClicked() {
    if (!selectedRow2) {
      alert("Select row first!", "Error");
      return;
    }

    copyRowToProjectContext(selectedRow2);
    navigate("/project/new");
  }
  function onDelete2ButtonClicked() {
    if (selectedNo2 < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedName2, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/project/deleteproject", selectedNo2).then(() => {
          search2();
        });
      } else {
        // No
      }
    });
  }
  function onDetail2ButtonClicked() {
    if (!selectedRow2) {
      alert("Select row first!", "Error");
      return;
    }
    copyRowToProjectContext(selectedRow2);
    navigate("/project/description");
  }

  function onSearch3ButtonClicked() {
    search3();
  }
  function search3() {
    let query = "SELECT * FROM project";
    query += " WHERE status = 'Routine'";
    query += " ORDER BY startDate Asc, department desc";
    queryJsonArray("/project/query", query).then((projectJson: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < projectJson.length; i++) {
        let oneRowJson: Object | null = projectJson[i];
        let startDate: number = U.intValueFromJson(oneRowJson, "startDate");
        let startDate2: string = U.stringDateFromEightDigitIntDate(startDate);
        oneRowJson = U.addStringToJson(oneRowJson, "startDate2", startDate2);
        if (!oneRowJson) continue;
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }

      setJsonDataSource3(newJsonData);
    });
  }

  function onDetail3ButtonClicked() {
    if (!selectedRow3) {
      alert("Select row first!", "Error");
      return;
    }
    copyRowToProjectContext(selectedRow3);
    navigate("/project/description");
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedNo(e.selectedRowsData[0].no);
      setSelectedName(e.selectedRowsData[0].name);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  function onDataGridCellDbClick(e: any) {
    copyRowToProjectContext(e.row.data);
    navigate("/project/description");
  }

  function onDataGrid2SelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedNo2(e.selectedRowsData[0].no);
      setSelectedName2(e.selectedRowsData[0].name);
      setSelectedRow2(e.selectedRowsData[0]);
    }
  }

  function onDataGrid2CellDbClick(e: any) {
    copyRowToProjectContext(e.row.data);
    navigate("/project/description");
  }

  function onDataGrid3SelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedNo3(e.selectedRowsData[0].no);
      setSelectedName3(e.selectedRowsData[0].name);
      setSelectedRow3(e.selectedRowsData[0]);
    }
  }

  function onDataGrid3CellDbClick(e: any) {
    copyRowToProjectContext(e.row.data);
    navigate("/project/description");
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>프로젝트 현황</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerTitle"}>
            <p className={"text-title"}>진행 중인 프로젝트</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" visible={isNewProjectButtonVisible} onClick={onNewButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Edit" visible={isNewProjectButtonVisible} onClick={onEditButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" visible={isDeleteProjectButtonVisible} onClick={onDeleteButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="상세정보" onClick={onDetailButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid
              onCellDblClick={onDataGridCellDbClick}
              onSelectionChanged={onDataGridSelectionChanged}
              dataSource={jsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={false}
            >
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="startDate2" caption="시작일" />
              <Column dataField="name" caption="프로젝트 이름" />
              <Column dataField="manager" caption="책임자" />
              <Column dataField="department" caption="주관부서" />
              <Column dataField="progress" caption="진척도 (%)" />
              <Column dataField="scheduledEndDate2" caption="종료예정일" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="name" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-containerTitle2"}>
            <p className={"text-title"}>부서별 일상업무</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearch3ButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="상세정보" onClick={onDetail3ButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid
              onCellDblClick={onDataGrid3CellDbClick}
              onSelectionChanged={onDataGrid3SelectionChanged}
              dataSource={jsonDataSource3}
              columnAutoWidth={true}
              allowColumnReordering={false}
            >
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="name" caption="이름" />
              <Column dataField="manager" caption="책임자" />
            </DataGrid>
          </div>

          <div className={"flex-containerTitle2"}>
            <p className={"text-title"}>중단된 프로젝트</p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearch2ButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="Edit" visible={isNewProjectButtonVisible} onClick={onEdit2ButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" visible={isDeleteProjectButtonVisible} onClick={onDelete2ButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="상세정보" onClick={onDetail2ButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid
              onCellDblClick={onDataGrid2CellDbClick}
              onSelectionChanged={onDataGrid2SelectionChanged}
              dataSource={jsonDataSource2}
              columnAutoWidth={true}
              allowColumnReordering={false}
            >
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="endDate2" caption="종료일" />
              <Column dataField="name" caption="프로젝트 이름" />
              <Column dataField="status" caption="상태" />
              <Column dataField="progress" caption="진척도 (%)" />
              <Column dataField="manager" caption="책임자" />
              <Column dataField="department" caption="주관부서" />
              <Column dataField="startDate2" caption="시작일" />
              <Column dataField="scheduledEndDate2" caption="종료예정일" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="name" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
