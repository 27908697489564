import "./fundStatus.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { queryJsonArray, uploadNewFundBalance } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { DataGrid, Column, ColumnFixing, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";
import {
  Chart,
  VisualRange,
  Series,
  ArgumentAxis,
  ValueAxis,
  Tick,
  CommonAxisSettings,
  CommonSeriesSettings,
  Export as ExportChart,
  Legend,
  Margin,
  Title,
  Label,
  Format,
  Crosshair,
  Tooltip,
  Grid,
  ZoomAndPan,
} from "devextreme-react/chart";
import PieChart, { Connector, SmallValuesGrouping } from "devextreme-react/pie-chart";

export default function FundSetup() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [chartJsonDataSource, setChartJsonDataSource] = useState<Array<Object>>();
  const [balanceJsonDataSource, setBalanceJsonDataSource] = useState<Array<Object>>();
  const [pieChartJsonDataSource, setPieChartJsonDataSource] = useState<Array<Object>>();
  const [pieChartPalette, setPieChartPalette] = useState<Array<string>>(["#6D9CC6", "#FF0000", "#F8BA63", "#B273CE"]);
  const [filterYear, setFilterYear] = useState("");

  const [isBalanceButtonVisible, setIsBalanceButtonVisible] = useState<boolean>(false);
  const [isBalanceInputVisible, setIsBalanceInputVisible] = useState<boolean>(false);
  const [monthText, setMonthText] = useState("");
  const [dayText, setDayText] = useState("");
  const [balanceText, setBalanceText] = useState("");

  const [chartTitle, setChartTitle] = useState("2023년 월말 잔고");
  const [pieChartTitle, setPieChartTitle] = useState("2023년 누적 비용");

  // 월별 지출 차트
  const [chart2JsonDataSource, setChart2JsonDataSource] = useState<Array<Object>>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    if (user) {
      setUserId(user.id);
      // (purch) [11] slip R, [12] slip input & D, [13] fundList setup, [14] fund status
      if (U.bitAt(user.acc_purch, 13)) setIsBalanceButtonVisible(true);
      if (!isUseEffectExecuted) {
        // 페이지 새로 고침한 이후 1번만 실행되도록 강제
        isUseEffectExecuted = true;
        let currentYear = U.todayFourDigitIntYear();
        let currentYearText: string = currentYear.toString();
        setFilterYear(currentYearText);
        search(currentYearText);
      }
    }
  }, []);

  function onYearTextBoxValueChanged(e: string) {
    setFilterYear(e);
  }
  function onSearchButtonClicked() {
    search(filterYear);
  }

  function search(yearText: string) {
    setIsBalanceInputVisible(false);
    if (!U.isProperFourDigitStringYear(yearText)) {
      alert("Enter proper year!", "Error");
      return;
    }
    let year = parseInt(yearText);
    setChartTitle(`${yearText}년 잔고`);
    setPieChartTitle(`${yearText}년 누적 지출`);

    let query = "SELECT * FROM fundlist";
    query += ` WHERE year = ${yearText}`;
    query += " ORDER BY year Asc, turn Asc";
    queryJsonArray("/fund/query", query).then((jsonData: Array<Object>) => {
      // 월별 지출을 테이블에 표현
      setJsonDataSource(jsonData);

      // jsonData의 데이터로부터 월별 지출을 수입, 고정비, 인건비, 그외 지출로 나눠서 그래프로 표현
      let newJsonArray2 = new Array<Object>();
      let newChart2JsonArray = new Array<Object>();
      let newChart3JsonArray = new Array<Object>();

      // 월별 합계액
      let incomes: number[] = new Array(12);
      let fixedCosts: number[] = new Array(12);
      let personnelCosts: number[] = new Array(12);
      let etcCosts: number[] = new Array(12);
      let accountCosts: number[] = new Array(12);
      for (let i = 0; i < 12; i++) {
        incomes[i] = 0;
        fixedCosts[i] = 0;
        personnelCosts[i] = 0;
        etcCosts[i] = 0;
        accountCosts[i] = 0;
      }

      for (let rowI = 0; rowI < jsonData.length; rowI++) {
        let title: string = U.stringValueFromJson(jsonData[rowI], "title");
        accountCosts[0] = U.floatValueFromJson(jsonData[rowI], "jan");
        accountCosts[1] = U.floatValueFromJson(jsonData[rowI], "feb");
        accountCosts[2] = U.floatValueFromJson(jsonData[rowI], "mar");
        accountCosts[3] = U.floatValueFromJson(jsonData[rowI], "apr");
        accountCosts[4] = U.floatValueFromJson(jsonData[rowI], "may");
        accountCosts[5] = U.floatValueFromJson(jsonData[rowI], "jun");
        accountCosts[6] = U.floatValueFromJson(jsonData[rowI], "jul");
        accountCosts[7] = U.floatValueFromJson(jsonData[rowI], "aug");
        accountCosts[8] = U.floatValueFromJson(jsonData[rowI], "sep");
        accountCosts[9] = U.floatValueFromJson(jsonData[rowI], "oct");
        accountCosts[10] = U.floatValueFromJson(jsonData[rowI], "nov");
        accountCosts[11] = U.floatValueFromJson(jsonData[rowI], "dec");

        if (title.indexOf("인건비") === 0) {
          for (let i = 0; i < 12; i++) {
            personnelCosts[i] += accountCosts[i];
          }
          continue;
        }

        if (title.indexOf("고정비") === 0) {
          for (let i = 0; i < 12; i++) {
            fixedCosts[i] += accountCosts[i];
          }
          continue;
        }

        if (title.indexOf("매출") === 0 || title.indexOf("영업외수익") === 0) {
          for (let i = 0; i < 12; i++) {
            incomes[i] += accountCosts[i];
          }
          continue;
        }

        for (let i = 0; i < 12; i++) {
          etcCosts[i] += accountCosts[i];
        }
      }

      let incomeSum = 0,
        fixedCostSum: number = 0,
        personnelCostSum = 0,
        etcCostSum = 0;
      for (let monthI = 0; monthI < 12; monthI++) {
        let oneRowJson2: Object | null = new Object();
        oneRowJson2 = U.addNumberToJson(oneRowJson2, "month", monthI + 1);
        oneRowJson2 = U.addNumberToJson(oneRowJson2, "income", (-1.0 * incomes[monthI]) / 1.0e8); // 수입은 들어온 돈을 +로 표시 (나머지는 지출을 +로 표시)
        oneRowJson2 = U.addNumberToJson(oneRowJson2, "fixedCost", (-1.0 * fixedCosts[monthI]) / 1.0e8);
        oneRowJson2 = U.addNumberToJson(oneRowJson2, "personnelCost", (-1.0 * personnelCosts[monthI]) / 1.0e8);
        oneRowJson2 = U.addNumberToJson(oneRowJson2, "etcCost", (-1.0 * etcCosts[monthI]) / 1.0e8);
        incomeSum += (-1.0 * incomes[monthI]) / 1.0e8;
        fixedCostSum += fixedCosts[monthI] / 1.0e8;
        personnelCostSum += personnelCosts[monthI] / 1.0e8;
        etcCostSum += etcCosts[monthI] / 1.0e8;
        if (oneRowJson2 !== null) newChart2JsonArray.push(oneRowJson2);
      }

      let oneRowJson3: Object | null = new Object();
      if (incomeSum >= 0) {
        oneRowJson3 = U.addStringToJson(oneRowJson3, "title", "수입");
        oneRowJson3 = U.addNumberToJson(oneRowJson3, "cost", incomeSum);
        if (oneRowJson3 !== null) newChart3JsonArray.push(oneRowJson3);
      }

      if (fixedCostSum >= 0) {
        oneRowJson3 = new Object();
        oneRowJson3 = U.addStringToJson(oneRowJson3, "title", "고정비");
        oneRowJson3 = U.addNumberToJson(oneRowJson3, "cost", fixedCostSum);
        if (oneRowJson3 !== null) newChart3JsonArray.push(oneRowJson3);
      }

      if (personnelCostSum >= 0) {
        oneRowJson3 = new Object();
        oneRowJson3 = U.addStringToJson(oneRowJson3, "title", "인건비");
        oneRowJson3 = U.addNumberToJson(oneRowJson3, "cost", personnelCostSum);
        if (oneRowJson3 !== null) newChart3JsonArray.push(oneRowJson3);
      }

      if (etcCostSum >= 0) {
        oneRowJson3 = new Object();
        oneRowJson3 = U.addStringToJson(oneRowJson3, "title", "기타 지출");
        oneRowJson3 = U.addNumberToJson(oneRowJson3, "cost", etcCostSum);
        if (oneRowJson3 !== null) newChart3JsonArray.push(oneRowJson3);
      }

      setPieChartJsonDataSource(newChart3JsonArray);
      setChart2JsonDataSource(newChart2JsonArray);

      // DB로부터 일별 잔고 데이터를 조회하고 그로부터 월말 잔고를 계산해서 차트에 표시
      let query2 = "SELECT * FROM fundbalance";
      query2 += ` WHERE year = ${yearText}`;
      query2 += " ORDER BY month Asc, day Asc";
      queryJsonArray("/fund/query", query2).then((balanceJsonData: Array<Object>) => {
        //setChartJsonDataSource(balanceJsonData);
        // balanceJsonData 로부터 월별 월말 잔고에 해당하는 json을 새로 생성한다.
        let newJsonArray = new Array<Object>();
        let monthlyBalanceJsonArray = new Array<Object>();
        let newChartJsonArray = new Array<Object>();

        let oneRowJson: Object | null = new Object();
        oneRowJson = U.addStringToJson(oneRowJson, "제목", "월말 잔고 (억원)");

        // 같은 month 중에서 가장 day가 큰 row의 정보를 월말잔고로 판단해서 json에 추가한다.
        let maxMonth = 0,
          maxDay = 0,
          prevBalance = 0,
          prevId = "",
          prevYear = 0,
          prevMonth = 0,
          prevDate = new Date();

        let prevBalanceForUsage = 0;
        for (let i = 0; i < balanceJsonData.length; i++) {
          let id: string = U.stringValueFromJson(balanceJsonData[i], "id");
          let year: number = U.intValueFromJson(balanceJsonData[i], "year");
          let month: number = U.intValueFromJson(balanceJsonData[i], "month");
          let day: number = U.intValueFromJson(balanceJsonData[i], "day");
          let balance: number = U.floatValueFromJson(balanceJsonData[i], "balance");
          let balance2 = balance / 1e8;
          let balanceText = balance2.toLocaleString();
          let date: Date = new Date(year, month - 1, day);

          // dailyBalance 추가
          let oneRowDailyBalanceJson: Object | null = new Object();
          oneRowDailyBalanceJson = U.addDateToJson(oneRowDailyBalanceJson, "date", date);
          oneRowDailyBalanceJson = U.addNumberToJson(oneRowDailyBalanceJson, "dailyBalance", balance2);
          if (oneRowDailyBalanceJson !== null) newChartJsonArray.push(oneRowDailyBalanceJson);

          // 월말 데이터 (monthlyBalance, monthlyUsage) 지점을 찾기 위해서는 balanceJsonData를 i index로 차례대로 검색하다가 month가 변경되는 시점에
          // 직전 데이터를 월말로 판단한다.
          if (i === 0) {
            // 그 해의 첫 데이터는 chart에 추가
            maxMonth = month;
            maxDay = day;
            prevId = id;
            prevBalance = balance2;
            prevDate = date;
            prevYear = year;
            prevMonth = month;
            prevBalanceForUsage = balance2;

            // monthlyBalance 추가 (monthlyBalance 값도 그 해 첫날의 데이터는 차트의 시작점으로 표시한다)
            let oneRowMonthlyBalanceJson: Object | null = new Object();
            oneRowMonthlyBalanceJson = U.addDateToJson(oneRowMonthlyBalanceJson, "date", prevDate);
            oneRowMonthlyBalanceJson = U.addNumberToJson(oneRowMonthlyBalanceJson, "monthlyBalance", prevBalance);
            if (oneRowMonthlyBalanceJson !== null) newChartJsonArray.push(oneRowMonthlyBalanceJson);
          }

          if (month === maxMonth) {
            // 직전과 같은 달인 경우
            maxDay = day;
            prevId = id;
            prevBalance = balance2;
            prevDate = date;
            prevYear = year;
            prevMonth = month;
          } else {
            // 달이 변경된 경우, 즉 직전 값을 직전 달의 마지막 날로 json에 추가해야 함.
            // monthlyBalance 추가
            let oneRowMonthlyBalanceJson: Object | null = new Object();
            oneRowMonthlyBalanceJson = U.addDateToJson(oneRowMonthlyBalanceJson, "date", prevDate);
            oneRowMonthlyBalanceJson = U.addNumberToJson(oneRowMonthlyBalanceJson, "monthlyBalance", prevBalance);
            if (oneRowMonthlyBalanceJson !== null) newChartJsonArray.push(oneRowMonthlyBalanceJson);

            // monthlyUsage 추가
            let oneRowMonthlyUsageJson: Object | null = new Object();
            oneRowMonthlyUsageJson = U.addDateToJson(oneRowMonthlyUsageJson, "date", prevDate);
            let monthlyUsage = prevBalanceForUsage - prevBalance;
            // 잔고 기반의 월지출 계산에서 유상증자 금액은 빼고 계산
            // 2022.5.27 (잔고 기입에는 6월에 반영되어 있음) 40억
            // 2023.9.15 20억
            if (prevYear === 2022 && prevMonth === 6) monthlyUsage += 40;
            if (prevYear === 2023 && prevMonth === 9) monthlyUsage += 20;
            oneRowMonthlyUsageJson = U.addNumberToJson(oneRowMonthlyUsageJson, "monthlyUsage", monthlyUsage);
            prevBalanceForUsage = prevBalance;
            if (oneRowMonthlyUsageJson !== null) newChartJsonArray.push(oneRowMonthlyUsageJson);

            // 월말 잔고 DataGrid 에 row 추가
            oneRowJson = U.addStringToJson(oneRowJson, prevId, U.fixedPointLocaleStringFromFloat(prevBalance, 2));

            maxMonth = month;
            maxDay = day;
            prevId = id;
            prevBalance = balance2;
            prevDate = date;
            prevYear = year;
            prevMonth = month;
          }
        }

        // monthlyBalance 추가
        if (maxMonth !== 0) {
          let oneRowMonthlyBalanceJson: Object | null = new Object();
          oneRowMonthlyBalanceJson = U.addDateToJson(oneRowMonthlyBalanceJson, "date", prevDate);
          oneRowMonthlyBalanceJson = U.addNumberToJson(oneRowMonthlyBalanceJson, "monthlyBalance", prevBalance);
          if (oneRowMonthlyBalanceJson !== null) newChartJsonArray.push(oneRowMonthlyBalanceJson);

          // 월말 잔고 DataGrid 에 row 추가
          oneRowJson = U.addStringToJson(oneRowJson, prevId, U.fixedPointLocaleStringFromFloat(prevBalance, 2));
        }

        if (oneRowJson !== null) newJsonArray.push(oneRowJson);
        setBalanceJsonDataSource(newJsonArray);

        setChartJsonDataSource(newChartJsonArray);
      });
    });
  }

  function onBalanceButtonClicked() {
    if (isBalanceInputVisible) setIsBalanceInputVisible(false);
    else setIsBalanceInputVisible(true);
  }

  function onMonthTextBoxValueChanged(e: string) {
    setMonthText(e);
  }

  function onDayTextBoxValueChanged(e: string) {
    setDayText(e);
  }

  function onBalanceTextBoxValueChanged(e: string) {
    setBalanceText(e);
  }

  async function onUploadButtonClicked() {
    if (!U.isProperFourDigitStringYear(filterYear)) {
      alert("Enter proper year!", "Error");
      return;
    }
    if (!U.isProperStringMonth(monthText)) {
      alert("Enter proper month!", "Error");
      return;
    }
    let day = parseInt(dayText);
    if (Number.isNaN(day)) {
      alert("Enter day!", "Error");
      return;
    }
    let year = parseInt(filterYear);
    let month = parseInt(monthText);
    let maxDay = U.maxDay(year, month);
    if (day < 1 || maxDay < day) {
      alert("Enter proper day!", "Error");
      return;
    }

    let balanceText2 = balanceText.replaceAll(",", "");
    let balance = parseFloat(balanceText2);

    await uploadNewFundBalance(year, month, day, balance);
  }

  function onDataGridSelectionChanged(e: any) {}

  return (
    <React.Fragment>
      <h2 className={"content-block"}>법인자금현황</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                placeholder="년도 (YYYY)"
                onValueChange={onYearTextBoxValueChanged}
                value={filterYear}
                valueChangeEvent="keyup"
                width={150}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="잔고 입력" visible={isBalanceButtonVisible} onClick={onBalanceButtonClicked} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                label={"month"}
                visible={isBalanceInputVisible}
                onValueChange={onMonthTextBoxValueChanged}
                valueChangeEvent="keyup"
                height={40}
                width={150}
                value={monthText}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item1"}>
              <TextBox
                label={"day"}
                visible={isBalanceInputVisible}
                onValueChange={onDayTextBoxValueChanged}
                valueChangeEvent="keyup"
                height={40}
                width={150}
                value={dayText}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item1"}>
              <TextBox
                label={"계좌 잔고"}
                visible={isBalanceInputVisible}
                onValueChange={onBalanceTextBoxValueChanged}
                valueChangeEvent="keyup"
                height={40}
                width={200}
                value={balanceText}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Upload" visible={isBalanceInputVisible} onClick={onUploadButtonClicked} type="default" icon="upload" />
            </div>
          </div>

          <div className={"flex-item1"}>
            <Chart palette="Violet" dataSource={chartJsonDataSource} title={chartTitle}>
              <CommonSeriesSettings argumentField="date" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series valueField={"dailyBalance"} name={"잔고"} axis={"balance"} type={"line"}></Series>
              <Series valueField={"monthlyUsage"} name={"월지출"} axis={"usage"} type={"bar"} color="#FF0000">
                <Label visible={true} format={"#,#0.0"} />
              </Series>
              <Margin bottom={20} />
              <ArgumentAxis
                title="날짜"
                argumentType={"datetime"}
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
                visualRangeUpdateMode={"reset"}
              >
                <Label>
                  <Format type="shortDate" />
                </Label>
              </ArgumentAxis>

              <ValueAxis name={"balance"} title={"잔고 (억원)"}>
                <Tick visible={true} />
                <Label visible={true} format={"#,#0.0#"} />
              </ValueAxis>

              <ValueAxis name={"usage"} title={"월지출(억원)"} position={"right"}>
                <VisualRange startValue={0} />
                <Tick visible={true} />
                <Label visible={true} format={"#,##0.#"} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494"></Label>
              </Crosshair>
              <Legend visible={true} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div>
            <DataGrid
              onSelectionChanged={onDataGridSelectionChanged}
              dataSource={balanceJsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={false}
              showBorders={true}
            >
              <LoadPanel enabled />
              <Selection mode="single" />
            </DataGrid>
          </div>

          <div className={"flex-item1"}>
            <Chart dataSource={chart2JsonDataSource} title={"월별 지출 (전표 기반)"}>
              <CommonSeriesSettings argumentField="month" />
              <CommonAxisSettings>
                <Grid visible={true} />
              </CommonAxisSettings>
              <Series valueField={"income"} name={"수입"} type={"line"} color="#6D9CC6" />
              <Series valueField={"fixedCost"} name={"고정비"} type={"line"} color="#FF0000" />
              <Series valueField={"personnelCost"} name={"인건비"} type={"line"} color="#F8BA63" />
              <Series valueField={"etcCost"} name={"기타 지출"} type={"line"} color="#B273CE" />
              <Margin bottom={20} />
              <ArgumentAxis
                title="월"
                valueMarginsEnabled={false}
                allowDecimals={false}
                discreteAxisDivisionMode={"crossLabels"}
                axisDivisionFactor={60}
                visualRangeUpdateMode={"reset"}
              ></ArgumentAxis>

              <ValueAxis title={"금액(억원)"} position={"left"}>
                <Tick visible={true} />
                <Label visible={true} format={"#,##0.#"} />
              </ValueAxis>

              <Crosshair enabled={true} color="#949494" width={2} dashStyle="dot">
                <Label visible={true} backgroundColor="#949494"></Label>
              </Crosshair>
              <Legend visible={true} verticalAlignment="top" horizontalAlignment="right" />
              <Tooltip enabled={false} />
              <ExportChart enabled={true} />
            </Chart>
          </div>

          <div className={"flex-item1"}>
            <PieChart id="pie" dataSource={pieChartJsonDataSource} palette={pieChartPalette} title={pieChartTitle} startAngle={90}>
              <Series argumentField="title" valueField="cost">
                <Label visible={true} customizeText={formatLabel} format="fixedPoint">
                  <Connector visible={true} width={0.5} />
                </Label>
              </Series>
              <Legend horizontalAlignment="center" verticalAlignment="bottom" />
              <Export enabled={true} />
            </PieChart>
          </div>

          <div>
            <p className={"text-title"}>월별 지출 (전표 기반)</p>
            <DataGrid
              onSelectionChanged={onDataGridSelectionChanged}
              dataSource={jsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={false}
              showBorders={true}
            >
              <Title text={"전표 기반 월별 지출"} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="title" caption="제목" fixed={true} />
              <Column dataField="sum" caption="합계 (원)" dataType="number" format="#,##0" />
              <Column dataField="jan" caption="1월" dataType="number" format="#,##0" />
              <Column dataField="feb" caption="2월" dataType="number" format="#,##0" />
              <Column dataField="mar" caption="3월" dataType="number" format="#,##0" />
              <Column dataField="apr" caption="4월" dataType="number" format="#,##0" />
              <Column dataField="may" caption="5월" dataType="number" format="#,##0" />
              <Column dataField="jun" caption="6월" dataType="number" format="#,##0" />
              <Column dataField="jul" caption="7월" dataType="number" format="#,##0" />
              <Column dataField="aug" caption="8월" dataType="number" format="#,##0" />
              <Column dataField="sep" caption="9월" dataType="number" format="#,##0" />
              <Column dataField="oct" caption="10월" dataType="number" format="#,##0" />
              <Column dataField="nov" caption="11월" dataType="number" format="#,##0" />
              <Column dataField="dec" caption="12월" dataType="number" format="#,##0" />
              <ColumnFixing enabled={true} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="title" summaryType="count" valueFormat="#,##0" />
                <TotalItem column="sum" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="jan" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="feb" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="mar" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="apr" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="may" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="jun" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="jul" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="aug" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="sep" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="oct" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="nov" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="dec" summaryType="sum" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function formatLabel(arg: any) {
  return `${arg.argumentText}: ${arg.value.toFixed(1)}억원`;
}
