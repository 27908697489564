import "./csBom.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCsPart } from "../../contexts/csPart";
// api
import { queryJsonArray, deleteRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { CheckBox } from "devextreme-react/check-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function SystemItem() {
  const { user } = useAuth();
  const csPart = useCsPart();
  const navigate = useNavigate();

  if (user) {
  }
  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedId, setSelectedId] = useState("");
  const [selectedHyperlink, setSelectedHyperlink] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [type, setType] = useState("구매품");
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);
  const [includeDisabled, setIncludeDisabled] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
    let acc_cs = 0;
    if (user) {
      setUserId(user.id);
      acc_cs = user.acc_cs;
    }
    // (cs) [0] part R, [1] W, [2] D
    if (U.bitAt(acc_cs, 1)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_cs, 2)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      //search(false, "구매품");
    }
  }, []);

  function onTypeSelectionChanged(e: any) {
    //let str: string = e.selectedItem;
    //setType(str);
    //search(includeDisabled, str);
  }

  function onSearchButtonClicked() {
    //search(includeDisabled, type);
  }

  function search(includeD: boolean, typeToSearch: string) {
    /*setSelectedId("");
    setSelectedRow(undefined);
    let query = `SELECT * FROM cspart`;
    query += ` WHERE type = '${typeToSearch}'`;
    if (includeD === false) {
      query += " AND isDisabled = 0";
    }

    queryJsonArray("/cs/querypart", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });*/
  }

  function onNewButtonClicked() {
    //csPart.id = "";
    //navigate("/cs/newPart");
  }

  function copyToCsPartContext(obj: Object) {
    /*csPart.id = U.stringValueFromJson(obj, "id");
    csPart.isDisabled = U.booleanValueFromJson(obj, "isDisabled");
    csPart.type = U.stringValueFromJson(obj, "type");
    csPart.name = U.stringValueFromJson(obj, "name");
    csPart.purpose = U.stringValueFromJson(obj, "purpose");
    csPart.supplier = U.stringValueFromJson(obj, "supplier");
    csPart.contactPerson = U.stringValueFromJson(obj, "contactPerson");
    csPart.contact = U.stringValueFromJson(obj, "contact");
    csPart.hyperlink = U.stringValueFromJson(obj, "hyperlink");
    csPart.manager = U.stringValueFromJson(obj, "manager");
    csPart.site = U.stringValueFromJson(obj, "site");
    csPart.storage = U.stringValueFromJson(obj, "storage");
    csPart.note = U.stringValueFromJson(obj, "note");*/
  }

  function onDeleteButtonClicked() {
    /*if (selectedId.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedId, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/cs/deletepart", selectedId).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });*/
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedId(e.selectedRowsData[0].id);
      setSelectedHyperlink(e.selectedRowsData[0].hyperlink);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>CellShot BOM (Under Construction...)</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>
          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="id" caption="ID" />
              <Column dataField="note" caption="Note" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="id" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
