import "./shareholder.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { queryJsonArray } from "../../api/auth";
// devextreme
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function Shareholder() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [disableRightDate, setDisableRightDate] = useState(true);
  const [filterRightDate, setFilterRightDate] = useState("");
  const [selectedNo, setSelectedNo] = useState(-1);
  const [selectedShareholderName, setSelectedShareholderName] = useState("");
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
    let acc_admin = 0;
    if (user) {
      setUserId(user.id);
      acc_admin = user.acc_admin;
    }
    // (admin) [0] education W & D, [1] patent R & W, [2] D, [3] shareholder R, [4] shareholder input & D, [5] stock price R
    if (U.bitAt(acc_admin, 4)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  function onRightDateCheckBoxValueChanged(e: boolean) {
    setDisableRightDate(!e);
  }

  function onRightDateBoxValueChanged(e: string) {
    setFilterRightDate(e);
  }

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setSelectedNo(-1);
    setSelectedShareholderName("");
    let query = `SELECT * FROM shareholder`;
    if (disableRightDate === false) {
      query += ` WHERE rightsBaseYearMonthDate = '${filterRightDate}'`;
    }

    query += " ORDER BY rightsBaseYearMonthDate Desc, shareholderName Asc";

    queryJsonArray("/shareholder/query", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function onBatchInputButtonClicked() {
    navigate("/shareholder/batchInput");
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedNo(e.selectedRowsData[0].no);
      setSelectedShareholderName(e.selectedRowsData[0].shareholderName);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>주주명부</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <CheckBox onValueChange={onRightDateCheckBoxValueChanged} />
            </div>
            <div className={"flex-item1"}>
              <TextBox
                disabled={disableRightDate}
                valueChangeEvent="keyup"
                onValueChange={onRightDateBoxValueChanged}
                width={250}
                height={40}
                placeholder="권리기준년월일 (YYYYMMDD)"
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="Batch Input" onClick={onBatchInputButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>
          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="rightsBaseYearMonthDate" caption="권리기준년월일" alignment={"center"} />
              <Column dataField="shareholderName" caption="주주명" />
              <Column dataField="shareholderNumber" caption="주주번호" />
              <Column dataField="numberOfConfirmedShares" caption="권리확정주식수" dataType="number" format="#,##0" />
              <Column dataField="commonStock" caption="보통구주" dataType="number" format="#,##0" />
              <Column dataField="preferredStock" caption="우선구주" dataType="number" format="#,##0" />
              <Column dataField="twoPreferredStock" caption="2우선구주" dataType="number" format="#,##0" />
              <Column dataField="realAccountNumber" caption="실질계좌번호" />
              <Column dataField="shareholderClassificationNumber" caption="주주구분번호" />
              <Column dataField="shareholderClassification" caption="주주구분" />
              <Column dataField="countryCode" caption="국가코드" />
              <Column dataField="zipCode" caption="우편번호" />
              <Column dataField="shareholderAddress" caption="주주주소" />
              <Column dataField="turn" caption="순번" />
              <Column dataField="listSubstantialClassification" caption="명부실질구분" />
              <Column dataField="whetherToSum" caption="합산여부" />
              <Column dataField="companyNumber" caption="회사번호" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="rightsBaseYearMonthDate" summaryType="count" valueFormat="#,##0" />
                <TotalItem column="numberOfConfirmedShares" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="commonStock" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="preferredStock" summaryType="sum" valueFormat="#,##0" />
                <TotalItem column="twoPreferredStock" summaryType="sum" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
