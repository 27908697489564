import "./attendanceDashboard.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
// api
import { uploadJson, queryJsonArray, deleteRowByIdBetween, deleteRowByDateAndId, downloadAndOpenPdf } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import DateBox from "devextreme-react/date-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function AttendanceDashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userListContext = useUserList();

  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedId, setSelectedId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [userName, setUserName] = useState<string>("");
  //const [userLocation, setUserLocation] = useState<string>("");
  const [userStatus, setUserStatus] = useState<string>("미출근");
  const [lastComplianceUpload, setLastComplianceUpload] = useState<string>("");
  const [newStatus, setNewStatus] = useState<string>("");
  const [statusTime, setStatusTime] = useState<Date>();
  const [statusList, setStatusList] = useState<Array<string>>();
  const [vacationDate, setVacationDate] = useState<Date>();
  const [vacationTypeList, setVacationTypeList] = useState<Array<string>>([
    "연차",
    "경조휴가",
    "보건휴가",
    "반차",
    "출장",
    "연차 (예비군 등/차감X)",
    "반차 (생일자 등/차감X)",
  ]);
  const [vacationType, setVacationType] = useState<string>("");

  const [startDate, setStartDate] = useState<Date>();
  const [departmentFilterList, setDepartmentFilterList] = useState<Array<string>>();
  const [departmentFilter, setDepartmentFilter] = useState<string>("");
  const [nameFilterList, setNameFilterList] = useState<Array<string>>();
  const [nameFilter, setNameFilter] = useState<string>("");
  const [statusFilterList, setStatusFilterList] = useState<Array<string>>();
  const [statusFilter, setStatusFilter] = useState<string>("");

  const [businessTrip, setBusinessTrip] = useState<string>("0");
  const [outside, setOutside] = useState<string>("0");
  const [telecommuting, setTelecommuting] = useState<string>("0");
  const [vacation, setVacation] = useState<string>("0");
  const [absent, setAbsent] = useState<string>("0");
  const [allUser, setAllUser] = useState<string>("0");

  const [isUploadDivHidden, setIsUploadDivHidden] = useState(true);
  const [isQueryDivHidden, setIsQueryDivHidden] = useState(true);
  const [isVacationDivHidden, setIsVacationDivHidden] = useState(true);
  const [isComplianceTitleDivHidden, setIsComplianceTitleDivHidden] = useState(true);
  const [isComplianceStatusDivHidden, setIsComplianceStatusDivHidden] = useState(true);
  const [isSearchComplianceDivHidden, setIsSearchComplianceDivHidden] = useState(true);

  // Edit Status
  const [isEditStatusButtonVisible, setIsEditStatusButtonVisible] = useState(false);
  const [isUploadStatusButtonVisible, setIsUploadStatusButtonVisible] = useState(false);
  const [uploadStatusDate, setUploadStatusDate] = useState<string>("");
  const [uploadStatusId, setUploadStatusId] = useState<string>("");
  const [uploadStatusName, setUploadStatusName] = useState<string>("");
  const [uploadStatusCurrentStatus, setUploadStatusCurrentStatus] = useState<string>("");
  const [uploadStatusTypeList, setUploadStatusTypeList] = useState<Array<string>>();
  const [uploadStatusType, setUploadStatusType] = useState<string>("");
  const [uploadStatusNewDate, setUploadStatusNewDate] = useState<Date>();

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_admin = 0;
    if (user) {
      acc_admin = user.acc_admin;
      setUserName(user.name);
    }
    // (admin) [0] attendance W, [1] Query, [2] E & D, [7] compliance W (target), [8] R, [9] D
    if (U.bitAt(acc_admin, 0)) setIsUploadDivHidden(false);
    else setIsUploadDivHidden(true);
    if (U.bitAt(acc_admin, 1)) setIsQueryDivHidden(false);
    else setIsQueryDivHidden(true);
    if (U.bitAt(acc_admin, 2)) {
      setIsEditStatusButtonVisible(true);
    } else {
      setIsEditStatusButtonVisible(false);
    }
    if (U.bitAt(acc_admin, 7)) {
      setIsComplianceTitleDivHidden(false);
      setIsComplianceStatusDivHidden(false);
      getMyComplianceStatus();
    }
    if (U.bitAt(acc_admin, 8)) {
      setIsComplianceTitleDivHidden(false);
      setIsSearchComplianceDivHidden(false);
    }

    setVacationDate(new Date(U.todayStartUTCStringDate()));
    setStartDate(new Date(U.todayStartUTCStringDate()));

    // 서버와의 통신은 비동기 함수이다 보니 송신 완료 후 다음 함수를 실행해야 하는 경우 주의해서 코드를 배치해야 한다.
    // userList를 다 다운로드 받은 후에 userList 정보를 이용하는 함수들을 실행토록 한다.
    if (!userListContext.isInitialized) {
      initializeUserList();
    } else {
      // userList가 이미 다운로드 받은 경우
      if (user) {
        if (U.bitAt(user.acc_admin, 0)) calculateMyAttendanceStatus();
        if (U.bitAt(user.acc_admin, 1)) {
          initializeQueryList();
          calculateAllAttendanceStatus();
        }
      }
    }
  }, []);

  function initializeUserList() {
    // userList 받아서 userListContext에 기록
    let query = "SELECT id, name, location, department, acc_admin, joiningDate FROM user";
    query += " ORDER BY department Desc, name Asc";
    userListContext.isInitialized = true;
    queryJsonArray("/attendance/query", query).then(
      (userJson: Array<Object>) => {
        userListContext.ids = [];
        userListContext.names = [];
        userListContext.locations = [];
        userListContext.departments = [];
        userListContext.acc_admins = [];
        userListContext.joiningDates = [];
        for (let i = 0; i < userJson.length; i++) {
          let id = U.stringValueFromJson(userJson[i], "id");
          let name = U.stringValueFromJson(userJson[i], "name");
          let location = U.stringValueFromJson(userJson[i], "location");
          let department = U.stringValueFromJson(userJson[i], "department");
          let acc_admin = U.intValueFromJson(userJson[i], "acc_admin");
          let joiningDate = U.intValueFromJson(userJson[i], "joiningDate");
          userListContext.ids.push(id);
          userListContext.names.push(name);
          userListContext.locations.push(location);
          userListContext.departments.push(department);
          userListContext.acc_admins.push(acc_admin);
          userListContext.joiningDates.push(joiningDate);
        } // End of for loop (userJson)

        if (user) {
          if (U.bitAt(user.acc_admin, 0)) calculateMyAttendanceStatus();
          if (U.bitAt(user.acc_admin, 1)) {
            initializeQueryList();
            calculateAllAttendanceStatus();
          }
        }
      } // End of queryJsonArray.then(() => {} function
    );
  }

  function getMyComplianceStatus() {
    let userId: string = "";
    if (user) {
      userId = user.id;
    }
    let query = `SELECT * FROM user WHERE id = '${userId}'`;
    queryJsonArray("/attendance/query", query).then((userJson: Array<Object>) => {
      for (let i = 0; i < userJson.length; i++) {
        let id = U.stringValueFromJson(userJson[i], "id");
        if (id === userId) {
          let lastComplianceUpload_at: Date = U.dateValueFromJson(userJson[i], "lastComplianceUpload_at");
          setLastComplianceUpload(U.stringDateFromDate(lastComplianceUpload_at));
          let todayIntArray = U.todayIntArray();
          let todayYear: number = todayIntArray[0];
          let todayMonth: number = todayIntArray[1];
          //let cutDayIntArray = U.findMovedDate(todayIntArray[0], todayIntArray[1], todayIntArray[2], 0, -1, 0);
          //let cutDayInt = U.eightDigitIntDateFromIntArray(cutDayIntArray[0], cutDayIntArray[1], cutDayIntArray[2]);
          let lastDayInt = U.eightDigitIntDateFromDate(lastComplianceUpload_at);
          let lastDayIntArray = U.intArrayFromDate(lastComplianceUpload_at);
          let isAlarmOn: boolean = false;
          if (Number.isNaN(lastDayInt)) {
            isAlarmOn = true;
          } else {
            let lastDayYear: number = lastDayIntArray[0];
            let lastDayMonth: number = lastDayIntArray[1];
            if (todayYear === lastDayYear && todayMonth === lastDayMonth) isAlarmOn = false;
            else isAlarmOn = true;
          }
          if (isAlarmOn) {
            let res = confirm("이번 달 임직원 내부통제 준수 확인서 등록을 하지 않았습니다. 지금 등록하시겠습니까?", "알림");
            res.then((dialogResult) => {
              if (dialogResult) {
                // Yes
                navigate("/compliance/new");
              }
            });
          }
        }
      }
    });
  }

  function calculateMyAttendanceStatus() {
    // 현재 상태 표시는 시작은 "출근" / "퇴근" / "미출근" 으로 시작하며 연차 정보는 "(연차)" 형태로 표시하되 반차는 출퇴근 정보 끝에 "출근: 분당 510호 (반차)" 형태로 추가한다.
    let userId: string = "";
    let userLocation: string = "";
    if (user) {
      userId = user.id;
      userLocation = user.location;
    }
    let todayStartDate = U.todayStartUTCStringDate();
    let todayEndDate = U.todayEndUTCStringDate();
    let eigitDigitDate: number = U.todayEightDigitIntDate();

    let query = "SELECT * FROM attendance";
    query += ` WHERE time >= '${todayStartDate}' AND time < '${todayEndDate}'`;
    query += " ORDER BY time Asc";
    queryJsonArray("/attendance/query", query).then((attendanceJson: Array<Object>) => {
      query = "SELECT * FROM attendancevacation";
      query += ` WHERE date = '${eigitDigitDate}'`;
      query += ` AND id = '${userId}'`;
      queryJsonArray("/attendance/query", query).then((vacationJson: Array<Object>) => {
        let vacationType: string = "";
        if (vacationJson.length !== 0) {
          vacationType = U.stringValueFromJson(vacationJson[0], "type");
        }

        // 현재 상태 찾기
        let status: string = "미출근";
        let list: Array<string> = [];
        for (let i = 0; i < attendanceJson.length; i++) {
          let id: string = U.stringValueFromJson(attendanceJson[i], "id");
          if (userId !== id) continue;
          let type: string = U.stringValueFromJson(attendanceJson[i], "type");
          // "출근: 분당 510호", "출근: 외근", "퇴근: 분당 510호", "퇴근: 외근"
          status = type;
          let time: Date = U.dateValueFromJson(attendanceJson[i], "time");
          setStatusTime(time);
        }
        if (vacationType.length !== 0) {
          if (vacationType.indexOf("반차") === 0) {
            // "미출근 (반차)", "출근: 분당 510호 (반차)"
            status = `${status} (${vacationType})`;
          } else {
            // "연차", "출장", ...
            status = vacationType;
          }
        }
        setUserStatus(status);

        // 현재 상태를 기반으로 selectbox의 item 설정
        if (status.indexOf("출근") === 0) {
          if (status.indexOf("출근: 외근") === 0) {
            // 출근 & 출근: 외근 상태라면
            list.push("퇴근: 외근");
            setStatusList(list);
          } else {
            if (status.indexOf("출근: 재택") === 0) {
              // 출근 & 출근: 재택 상태라면
              list.push("퇴근: 재택");
              setStatusList(list);
            } else {
              // 출근 & 출근지에 출근한 상태라면
              if (userLocation.length > 0) list.push(`퇴근: ${userLocation}`);
              setStatusList(list);
            }
          }
        } else {
          // 출근 상태가 아니라면
          if (status.indexOf("퇴근") === 0) {
            // 퇴근 상태라면 더 이상 입력할 수 없게 한다.
            setStatusList([]);
          } else {
            if (status.indexOf("미출근") === 0) {
              // 미출근 상태라면
              if (userLocation.length > 0) list.push(`출근: ${userLocation}`);
              list.push("출근: 외근");
              list.push("출근: 재택");
              setStatusList(list);
            }
            // 기타 (연차, 출장 등) 상태에서는 statusList를 empty 상태로 둬서 출퇴근을 입력할 수 없게 한다.
          }
        }
      });
    });
  }

  function initializeQueryList() {
    // userListContext 정보를 분석해서 selectBox의 item list를 초기화한다.
    let l1: Array<string> = [""];
    let l2: Array<string> = [""];
    let l3: Array<string> = ["", "출근", "퇴근", "미출근"];
    for (let i = 0; i < userListContext.names.length; i++) {
      // acc_admin을 검사해서 근태관리 대상인 user에 대해서만 처리한다.
      if (!U.bitAt(userListContext.acc_admins[i], 0)) continue;
      U.addStringArrayIfNotExist(l1, userListContext.departments[i]);
      U.addStringArrayIfNotExist(l2, userListContext.names[i]);
    }
    setDepartmentFilterList(l1);
    setNameFilterList(l2);
    setStatusFilterList(l3);
  }

  function calculateAllAttendanceStatus() {
    if (!userListContext.ids) {
      alert("No user list!", "Error");
      return;
    }
    let dateToSearch: Date = new Date(Date.now());
    if (startDate) dateToSearch = startDate;
    let startStringDate = U.startUTCStringDate(dateToSearch);
    let endStringDate = U.endUTCStringDate(dateToSearch);
    let eightDigitIntDate = U.eightDigitIntDateFromDate(dateToSearch);
    let query = "SELECT * FROM attendance";
    query += ` WHERE time >= '${startStringDate}' AND time < '${endStringDate}'`;
    query += " ORDER BY time Asc";
    queryJsonArray("/attendance/query", query).then(
      (attendanceJson: Array<Object>) => {
        query = "SELECT * FROM attendancevacation";
        query += ` WHERE date = '${eightDigitIntDate}'`;
        queryJsonArray("/attendance/query", query).then((vacationJson: Array<Object>) => {
          calculateAttendanceStatus(dateToSearch, attendanceJson, vacationJson);
        });
      } // End of queryJsonArray.then(() => {}
    );
  }

  function calculateAttendanceStatus(dateToSearch: Date, attendanceJson: Array<Object>, vacationJson: Array<Object>) {
    let newJsonArray = new Array<Object>();
    let attendedN: number = 0;
    let notAttendedN: number = 0;
    let vacationN: number = 0; // 연차
    let businessTripN: number = 0; // 출장
    let absentN: number = 0; // 결근
    let leavedN: number = 0; // 퇴근
    let allUserN: number = 0;
    // user table의 location 값을 보고 근무지별로 정원 집계
    // 근무지별로 현원 체크는 user_status를 분석한 후 집계한다.
    let businessTripAttendN = 0; // 출장
    let businessTripName = "출장";
    let outsideAttendN = 0; // 외근
    let outsideName = "외근";
    let telecommutingAttendN = 0; // 재택
    let telecommutingName = "재택";

    // user 한 명씩에 대해 attendance를 분석 -> newJsonArray에 one row (상태, 출근시각, 퇴근시각, 초과근무시간 등)를 추가해서 이후 table에 표시한다.
    for (let i = 0; i < userListContext.ids.length; i++) {
      let user_id = userListContext.ids[i];
      let user_name = userListContext.names[i];
      //let user_location = userListContext.locations[i];
      let user_department = userListContext.departments[i];
      let user_acc_admin = userListContext.acc_admins[i];
      let user_joiningDate = userListContext.joiningDates[i];
      // 집계하지 않는 경우인지 체크한다.
      if (!U.bitAt(user_acc_admin, 0)) continue;
      let todayEightDigitDate = U.todayEightDigitIntDate();
      if (todayEightDigitDate < user_joiningDate) continue; // 아직 입사 전이라면

      if (departmentFilter) {
        if (departmentFilter !== user_department) continue;
      }
      //console.log(user_name);
      if (nameFilter) {
        if (nameFilter !== user_name) continue;
      }

      // user table의 location 값을 보고 근무지별로 정원 집계
      allUserN++;

      let oneRowJson: Object | null = new Object();
      let user_status: string = "미출근";
      let user_startTime = ""; // 00:00
      let user_endTime = "";
      let user_startDate: Date | undefined;
      let user_endDate: Date | undefined;

      // user_id에 대해 attendanceJson[]에서의 상태를 검색한다.
      for (let j = 0; j < attendanceJson.length; j++) {
        let att_id: string = U.stringValueFromJson(attendanceJson[j], "id");
        if (user_id !== att_id) continue;
        let att_type: string = U.stringValueFromJson(attendanceJson[j], "type");
        // "출근: 분당 510호", "출근: 외근", "퇴근: 분당 510호", "퇴근: 외근"
        user_status = att_type;
        let att_time: Date = U.dateValueFromJson(attendanceJson[j], "time");
        if (user_status.length !== 0) {
          if (user_status.indexOf("출근") === 0) {
            user_startDate = att_time;
            user_startTime = U.fourDigitClockStringFromDate(att_time);
            user_endTime = "";
          }
          if (user_status.indexOf("퇴근") === 0) {
            user_endDate = att_time;
            user_endTime = U.fourDigitClockStringFromDate(att_time);
          }
        }
      } // End of for loop (attendanceJson)

      // user_id에 대해 vacationJson[]에서의 상태를 검색한다.
      let vacationType: string = "";
      let isHalfOff: boolean = false; // 반차인 경우 (나중에 overTime 계산에서 활용함)
      for (let j = 0; j < vacationJson.length; j++) {
        let vac_id: string = U.stringValueFromJson(vacationJson[j], "id");
        if (user_id !== vac_id) continue;
        vacationType = U.stringValueFromJson(vacationJson[j], "type");
        break;
      }

      // user_status에 vacation 상태 표시
      if (vacationType.length !== 0) {
        if (vacationType.indexOf("반차") === 0) {
          // "미출근 (반차)", "출근: 분당 510호 (반차)", "퇴근: 분당 510호 (반차)", ...
          user_status = `${user_status} (반차)`;
          isHalfOff = true;
        } else {
          // "연차", "출장", ...
          // "연차 (...)" 는 "연차"로만 표시함
          if (vacationType.indexOf("연차") === 0) {
            user_status = "연차";
          } else {
            user_status = vacationType;
          }
        }
      }

      if (statusFilter) {
        if (user_status.indexOf(statusFilter) !== 0) continue;
      }

      // 출근지 별로 사람 수 집계
      if (user_status.length !== 0) {
        if (user_status.indexOf("출근") === 0) {
          if (user_status.length >= 5) {
            let statusLocation = user_status.substring(4);
            if (statusLocation === businessTripName) businessTripAttendN++;
            if (statusLocation === outsideName) outsideAttendN++;
            if (statusLocation === telecommutingName) telecommutingAttendN++;
          }
          attendedN++;
        } else {
          if (user_status.indexOf("퇴근") === 0) {
            leavedN++;
          } else {
            // 출근 또는 퇴근 으로 시작하지 않는 경우: 미출근, 연차, 출장
            notAttendedN++;
            if (user_status.indexOf("연차") === 0) {
              vacationN++;
            }
            if (user_status.indexOf("출장") === 0) {
              businessTripN++;
            }
            if (user_status.indexOf("미출근") === 0) {
              absentN++;
            }
          }
        }
      }

      // overTime 계산
      let overTime: string = "";
      if (user_startDate !== undefined && user_endDate !== undefined) {
        let s = Math.round((user_endDate.getTime() - user_startDate.getTime()) / 1000);
        let refTime_s = 9 * 3600;
        if (isHalfOff) refTime_s = 4 * 3600; // 반차는 기준 근로시간이 4시간
        if (s >= refTime_s) {
          // 기준 근로시간 이상인 경우
          let s2 = s - refTime_s;
          let h = Math.floor(s2 / 3600);
          let s3 = s2 - h * 3600;
          let m = Math.round(s3 / 60);
          let hh: string = `${h}`;
          if (hh.length < 2) hh = "0" + hh;
          let mm: string = `${m}`;
          if (mm.length < 2) mm = "0" + mm;
          overTime = `${hh}:${mm}`;
        } else {
          // 기준 근로시간 이하인 경우
          let s2 = Math.abs(s - refTime_s);
          let h = Math.floor(s2 / 3600);
          let s3 = s2 - h * 3600;
          let m = Math.round(s3 / 60);
          let hh: string = `${h}`;
          if (hh.length < 2) hh = "0" + hh;
          let mm: string = `${m}`;
          if (mm.length < 2) mm = "0" + mm;
          overTime = `-${hh}:${mm}`;
        }
      }

      if (vacationType.length !== 0) {
        if (vacationType.indexOf("반차") === 0) {
          // "미출근 (반차)", "출근: 분당 510호 (반차)", "퇴근: 분당 510호 (반차)", ...
          if (user_endTime.length !== 0) user_endTime = `${user_endTime} (반차)`;
        } else {
          // "연차", "출장", ...
          user_startTime = "";
          user_endTime = "";
          overTime = "";
        }
      }

      oneRowJson = U.addDateToJson(oneRowJson, "date", dateToSearch);
      oneRowJson = U.addStringToJson(oneRowJson, "day", U.dayString(dateToSearch));
      oneRowJson = U.addStringToJson(oneRowJson, "id", user_id);
      oneRowJson = U.addStringToJson(oneRowJson, "name", user_name);
      oneRowJson = U.addStringToJson(oneRowJson, "department", user_department);
      oneRowJson = U.addStringToJson(oneRowJson, "status", user_status);
      oneRowJson = U.addStringToJson(oneRowJson, "startTime", user_startTime);
      oneRowJson = U.addStringToJson(oneRowJson, "endTime", user_endTime);
      oneRowJson = U.addStringToJson(oneRowJson, "overTime", overTime);
      if (oneRowJson !== null) newJsonArray.push(oneRowJson);
    } // End of for loop (userJson)
    setJsonDataSource(newJsonArray);
    let allN: number = attendedN + leavedN + notAttendedN;
    setAllUser(`${attendedN} / ${allUserN}`);
    setAbsent(`${absentN}`);
    setBusinessTrip(`${businessTripAttendN}`);
    setOutside(`${outsideAttendN}`);
    setTelecommuting(`${telecommutingAttendN}`);
    setVacation(`${vacationN}`);
  }

  function onMyStatusSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setNewStatus(str);
  }

  async function onUploadMyStatusButtonClicked() {
    let userId: string = "";
    if (user) userId = user.id;
    if (newStatus.length === 0) {
      alert("Select new status!", "Error");
      return;
    }
    if (userId.length === 0) {
      alert("No user id!", "Error");
      return;
    }

    let obj: Object = {
      id: userId,
      type: newStatus,
    };
    await uploadJson("/attendance/upload", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        // 성공한 경우만 refresh
        calculateMyAttendanceStatus();
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  function onVacationButtonClicked() {
    if (isVacationDivHidden) {
      setIsVacationDivHidden(false);
    } else {
      setIsVacationDivHidden(true);
    }
  }
  function onDailyWorkButtonClicked() {
    setIsVacationDivHidden(true);
  }

  function onVacationDateDateBoxValueChanged(e: Date) {
    setVacationDate(e);
  }
  function onVacationTypeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setVacationType(str);
  }
  async function onUploadVacationButtonClicked() {
    setIsVacationDivHidden(true);

    let userId: string = "";
    if (user) userId = user.id;
    if (vacationType.length === 0) {
      alert("Select type!", "Error");
      return;
    }
    if (!vacationDate) {
      alert("Select date!", "Error");
      return;
    }
    if (userId.length === 0) {
      alert("No user id!", "Error");
      return;
    }
    let date: number = U.eightDigitIntDateFromDate(vacationDate);
    let obj: Object = {
      id: userId,
      date: date,
      type: vacationType,
    };
    await uploadJson("/attendance/uploadvacation", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        // 성공한 경우만 refresh
        //calculateMyAttendanceStatus();
        return;
      } else {
        if (response_status === 22) {
          alert("이미 등록된 일정이 있습니다!", "Error");
          return;
        } else {
          alert("Failed to upload!", "Error");
          return;
        }
      }
    });
  }

  function onStartDateDateBoxValueChanged(e: Date) {
    setStartDate(e);
  }

  function onDepartmentFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setDepartmentFilter(str);
  }

  function onNameFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setNameFilter(str);
  }

  function onStatusFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setStatusFilter(str);
  }

  async function onSearchButtonClicked() {
    calculateAllAttendanceStatus();
    setSelectedStatus("");
    setIsUploadStatusButtonVisible(false);
  }

  function onDetailButtonClicked() {
    navigate("/attendance");
  }

  function onEditStatusButtonClicked() {
    if (isUploadStatusButtonVisible) {
      setIsUploadStatusButtonVisible(false);
    } else {
      // Enable edit status
      if (!selectedDate || selectedStatus.length === 0) {
        alert("Select row first!", "Error");
        return;
      }
      // selectedRow의 정보로부터 uploadStatus로 복사함
      setUploadStatusDate(U.eightDigitStringDateFromDate(new Date(selectedDate)));
      setUploadStatusId(selectedId);
      setUploadStatusName(selectedName);
      setUploadStatusCurrentStatus(selectedStatus);
      let list: Array<string> = [];
      if (selectedStatus.indexOf("미출근") === 0) {
        list.push("출근: 분당 510호");
        list.push("출근: 분당 503호");
        list.push("출근: 분당 407호");
        list.push("출근: 판교 704호");
        list.push("출근: 서울 성모병원");
        list.push("출근: 재택");
        list.push("출근: 외근");
      }
      if (selectedStatus.indexOf("출근") === 0) {
        list.push("퇴근: 분당 510호");
        list.push("퇴근: 분당 503호");
        list.push("퇴근: 분당 407호");
        list.push("퇴근: 판교 704호");
        list.push("퇴근: 서울 성모병원");
        list.push("퇴근: 재택");
        list.push("퇴근: 외근");
      }
      setUploadStatusTypeList(list);
      setIsUploadStatusButtonVisible(true);
    }
  }

  function onUploadStatusTypeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setUploadStatusType(str);
  }

  function onUploadStatusNewDateDateBoxValueChanged(e: Date) {
    setUploadStatusNewDate(e);
  }

  async function onUploadStatusButtonClicked() {
    // date와 newDate가 같은 날인지 체크
    if (!uploadStatusNewDate) {
      alert("Select proper date!", "Error");
      return;
    }
    let date: Date = uploadStatusNewDate;
    let newType: string = uploadStatusType;
    let newUserId: string = uploadStatusId;
    let eigitDightStringDate = U.eightDigitStringDateFromDate(date);
    if (uploadStatusDate !== eigitDightStringDate) {
      alert("같은 날짜를 선택하세요!", "Error");
      return;
    }

    if (newType.length === 0) {
      alert("새로운 상태를 선택하세요!", "Error");
      return;
    }

    let obj: Object = {
      id: newUserId,
      type: newType,
      time: date,
    };
    await uploadJson("/attendance/edit", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        // 성공한 경우만 refresh
        calculateAllAttendanceStatus();
        setIsUploadStatusButtonVisible(false);
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }
  async function onResetStatusButtonClicked() {
    //uploadStatusDate
    let newType: string = uploadStatusType;
    let newUserId: string = uploadStatusId;
    // 해당 날짜를 Date로 시작시간과 종료시간으로 구함
    let startLocalDate: Date = U.startLocalDateFromEigitDigitStringDate(uploadStatusDate);
    let endLocalDate: Date = U.endLocalDateFromEigitDigitStringDate(uploadStatusDate);
    if (uploadStatusCurrentStatus === "미출근") {
      alert("이미 미출근 상태입니다!", "Error");
      return;
    }

    let res = confirm(`[${uploadStatusName}-${uploadStatusDate}]을 미출근으로 초기화하겠습니까?`, "확인");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByIdBetween("/attendance/deletebyidbetween", newUserId, startLocalDate, endLocalDate);
        setIsUploadStatusButtonVisible(false);
      }
    });
  }

  async function onDeleteDailyWorkButtonClicked() {
    let userId: string = uploadStatusId;
    if (userId.length === 0) {
      alert("적절한 사용자를 선택하세요!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(uploadStatusDate)) {
      alert("적절한 날짜를 선택하세요!", "Error");
      return;
    }
    let date: number = parseInt(uploadStatusDate);

    let res = confirm(`[${userId}-${date}]의 근무시간 기록을 삭제하시겠습니까?`, "확인");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByDateAndId("/dailywork/delete", date, userId);
        setIsUploadStatusButtonVisible(false);
      }
    });
  }

  async function onNewComplianceButtonClicked() {
    navigate("/compliance/new");
  }
  async function onSearchComplianceButtonClicked() {
    navigate("/compliance/search");
  }

  const onCellPrepared = (e: any) => {
    if (e.rowType !== "data") return;

    // 출근 상태가 아니면 (미출근, 퇴근, 연차 등) row 전체를 배경색 회색으로 표시
    if (e.data.status.indexOf("출근") !== 0) {
      e.cellElement.style.cssText = "background-color: #EEEEEE";
    }

    // 초과근무시간이 음수이면 red로 표시
    if (e.column.caption === "초과근무시간") {
      if (e.data.overTime.length > 0) {
        if (e.data.overTime[0] === "-") {
          e.cellElement.style.cssText = "color: red;background-color: #EEEEEE";
        }
      }
    }
  };

  function onDataGridSelectionChanged(e: any) {
    setIsUploadStatusButtonVisible(false);
    if (e.selectedRowsData.length != 0) {
      setSelectedDate(e.selectedRowsData[0].date);
      setSelectedId(e.selectedRowsData[0].id);
      setSelectedName(e.selectedRowsData[0].name);
      setSelectedStatus(e.selectedRowsData[0].status);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Dashboard</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerTitle"}>
            <p className={"text-title"} hidden={isQueryDivHidden}>
              출퇴근 현황{" "}
            </p>
          </div>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <DateBox
                label="일자"
                defaultValue={new Date()}
                valueChangeEvent="change"
                onValueChange={onStartDateDateBoxValueChanged}
                type="date"
                width={150}
                height={40}
              />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <SelectBox label="부서" dataSource={departmentFilterList} width={150} height={40} onSelectionChanged={onDepartmentFilterSelectionChanged} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <SelectBox label="이름" dataSource={nameFilterList} width={150} height={40} onSelectionChanged={onNameFilterSelectionChanged} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <SelectBox label="상태" dataSource={statusFilterList} width={150} height={40} onSelectionChanged={onStatusFilterSelectionChanged} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" width={150} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <Button text="출퇴근 관리" onClick={onDetailButtonClicked} icon="find" width={150} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <TextBox label={"출근"} readOnly={true} value={allUser} width={150} height={40} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <TextBox label={"결근"} readOnly={true} value={absent} width={70} height={40} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <TextBox label={"연차"} readOnly={true} value={vacation} width={70} height={40} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <TextBox label={"출장"} readOnly={true} value={businessTrip} width={70} height={40} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <TextBox label={"외근"} readOnly={true} value={outside} width={70} height={40} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <TextBox label={"재택"} readOnly={true} value={telecommuting} width={70} height={40} />
            </div>
          </div>

          <div hidden={isQueryDivHidden}>
            <DataGrid
              onSelectionChanged={onDataGridSelectionChanged}
              dataSource={jsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={true}
              onCellPrepared={onCellPrepared}
            >
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="date" caption="일자" dataType="date" />
              <Column dataField="day" caption="요일" />
              <Column dataField="department" caption="부서" />
              <Column dataField="name" caption="이름" />
              <Column dataField="status" caption="상태" />
              <Column dataField="startTime" caption="출근시각" />
              <Column dataField="endTime" caption="퇴근시각" />
              <Column dataField="overTime" caption="초과근무시간" />

              <Summary>
                <TotalItem column="date" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-containerH3"}>
            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <Button visible={isEditStatusButtonVisible} text="관리자용 편집" onClick={onEditStatusButtonClicked} width={150} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <Button text="출퇴근 기록 삭제" visible={isUploadStatusButtonVisible} onClick={onResetStatusButtonClicked} type="default" icon="upload" />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <Button
                text="일일 업무시간 기록 삭제"
                visible={isUploadStatusButtonVisible}
                onClick={onDeleteDailyWorkButtonClicked}
                type="default"
                icon="upload"
              />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <TextBox label={"일자"} visible={isUploadStatusButtonVisible} readOnly={true} value={uploadStatusDate} width={150} height={40} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <TextBox label={"이름"} visible={isUploadStatusButtonVisible} readOnly={true} value={uploadStatusName} width={150} height={40} />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <SelectBox
                label={"New Status"}
                visible={isUploadStatusButtonVisible}
                dataSource={uploadStatusTypeList}
                height={40}
                onSelectionChanged={onUploadStatusTypeSelectionChanged}
              />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <DateBox
                label="시간"
                visible={isUploadStatusButtonVisible}
                valueChangeEvent="change"
                onValueChange={onUploadStatusNewDateDateBoxValueChanged}
                type="datetime"
                height={40}
              />
            </div>

            <div className={"flex-item1"} hidden={isQueryDivHidden}>
              <Button visible={isUploadStatusButtonVisible} text="Upload" onClick={onUploadStatusButtonClicked} type="default" icon="upload" />
            </div>
          </div>

          <div className={"flex-containerTitle"}>
            <p className={"text-title"} hidden={isUploadDivHidden}>
              출퇴근 등록{" "}
            </p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"} hidden={isUploadDivHidden}>
              <TextBox label="이름" readOnly={true} value={userName} width={150} height={40} />
            </div>

            <div className={"flex-item1"} hidden={isUploadDivHidden}>
              <TextBox label="상태" readOnly={true} value={userStatus} width={250} height={40} />
            </div>

            <div className={"flex-item1"} hidden={isUploadDivHidden}>
              <DateBox label="At" type="datetime" readOnly={true} value={statusTime} width={200} height={40} />
            </div>

            <div className={"flex-item1"} hidden={isUploadDivHidden}>
              <SelectBox dataSource={statusList} width={150} height={40} onSelectionChanged={onMyStatusSelectionChanged} />
            </div>

            <div className={"flex-item1"} hidden={isUploadDivHidden}>
              <Button text=" 출퇴근 업로드" onClick={onUploadMyStatusButtonClicked} type="default" icon="upload" width={150} />
            </div>

            <div className={"flex-item1"} hidden={isUploadDivHidden}>
              <Button text="연차, 출장" onClick={onVacationButtonClicked} />
            </div>

            <div className={"flex-item1"} hidden={true}>
              <Button text="일일 업무시간" onClick={onDailyWorkButtonClicked} width={200} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"} hidden={isVacationDivHidden}>
              <DateBox
                label="일자"
                value={vacationDate}
                valueChangeEvent="change"
                onValueChange={onVacationDateDateBoxValueChanged}
                type="date"
                width={150}
                height={40}
              />
            </div>

            <div className={"flex-item1"} hidden={isVacationDivHidden}>
              <SelectBox dataSource={vacationTypeList} width={230} height={40} onSelectionChanged={onVacationTypeSelectionChanged} />
            </div>

            <div className={"flex-item1"} hidden={isVacationDivHidden}>
              <Button text="Upload" onClick={onUploadVacationButtonClicked} type="default" icon="upload" width={150} />
            </div>
          </div>

          <div className={"flex-containerTitle2"}>
            <p className={"text-title"} hidden={isComplianceTitleDivHidden}>
              임직원 내부통제 준수 확인서
            </p>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"} hidden={isComplianceStatusDivHidden}>
              <TextBox label={"마지막 등록일"} readOnly={true} value={lastComplianceUpload} width={150} height={40} />
            </div>
            <div className={"flex-item1"} hidden={isComplianceStatusDivHidden}>
              <Button text="신규생성" onClick={onNewComplianceButtonClicked} />
            </div>
            <div className={"flex-item1"} hidden={isSearchComplianceDivHidden}>
              <Button text="조회" onClick={onSearchComplianceButtonClicked} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
