import { createContext, useContext } from "react";
export type ProgressContextType = {
  no: string;
  date: Date;
  department: string;
  registrant: string;
  content: string;
};
const ProgressContext = createContext<ProgressContextType>({} as ProgressContextType);
const useProgress = () => useContext(ProgressContext);
export { useProgress };
