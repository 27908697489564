import { createContext, useContext } from "react";
export type GeneralDocumentContextType = {
  no: number;
  name: string;
  ext: string;
  writer: string;
  link1: string;
  link2: string;
  link3: string;
  created_at: Date;
  updated_by: string;
  updated_at: Date;
};
const GeneralDocumentContext = createContext<GeneralDocumentContextType>({} as GeneralDocumentContextType);
const useGeneralDocument = () => useContext(GeneralDocumentContext);
export { useGeneralDocument };
