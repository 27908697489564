import "./purchaseRequest.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUsage } from "../../contexts/usage";
// api
import { queryJsonArray, deleteRowByNo, orderPurchaseRequestAndAlert, updateNoteByNoAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function PurchaseRequest() {
  const { user } = useAuth();
  const usage = useUsage();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [selectedNo, setSelectedNo] = useState(0);
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);
  const [isDeliveredButtonVisible, setIsDeliveredButtonVisible] = useState(false);
  const [isOrderButtonVisible, setIsOrderButtonVisible] = useState(false);
  const [isNoteButtonVisible, setIsNoteButtonVisible] = useState(false);
  const [isNoteGroupVisible, setIsNoteGroupVisible] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [noteNo, setNoteNo] = useState(-1);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
    let acc_purch = 0;
    if (user) {
      setUserId(user.id);
      setUserName(user.name);
      acc_purch = user.acc_purch;
    }
    // (purch) [0] purchase item R, [1] W, [2] D, [3] purchase request R, [4] W, [5] D, [6] Order
    if (U.bitAt(acc_purch, 4)) {
      setIsDeliveredButtonVisible(true);
      setIsNoteButtonVisible(true);
    } else {
      setIsDeliveredButtonVisible(false);
      setIsNoteButtonVisible(false);
    }
    if (U.bitAt(acc_purch, 5)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);
    if (U.bitAt(acc_purch, 6)) setIsOrderButtonVisible(true);
    else setIsOrderButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      let dateRange = calculateDateRange("1개월");
      if (dateRange) search(dateRange[0], dateRange[1]);
    }
  }, []);

  function onFromTextBoxValueChanged(e: string) {
    setFilterFrom(e);
  }
  function onToTextBoxValueChanged(e: string) {
    setFilterTo(e);
  }
  function onDateSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    calculateDateRange(str);
  }

  function calculateDateRange(str: string) {
    let todayStr: string = U.todayEightDigitStringDate();
    setFilterTo(todayStr);
    let todayYMD = U.todayIntArray();
    let moveY: number = 0,
      moveM: number = 0,
      moveD: number = 0;
    if (str === "1주일") moveD = -6;
    if (str === "1개월") {
      moveM = -1;
      moveD = 1;
    }
    if (str === "2개월") {
      moveM = -2;
      moveD = 1;
    }
    if (str === "6개월") {
      moveM = -6;
      moveD = 1;
    }
    if (str === "1년") {
      moveY = -1;
      moveD = 1;
    }
    if (str === "2년") {
      moveY = -2;
      moveD = 1;
    }
    let startYMD = U.findMovedDate(todayYMD[0], todayYMD[1], todayYMD[2], moveY, moveM, moveD);
    let startStr = U.eightDigitStringDateFromIntArray(startYMD[0], startYMD[1], startYMD[2]);
    setFilterFrom(startStr);
    return [startStr, todayStr];
  }

  //////////////////////////// Search Button
  function onSearchButtonClicked() {
    search(filterFrom, filterTo);
  }
  function search(from: string, to: string) {
    setSelectedNo(-1);
    setSelectedRow(undefined);
    setIsNoteGroupVisible(false);
    if (!U.isProperEightDigitStringDate(from)) {
      alert("Enter from date in proper format!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(to)) {
      alert("Enter to date in proper format!", "Error");
      return;
    }
    let dateFrom: Number = parseInt(from);
    let dateTo: Number = parseInt(to);

    let query = "SELECT * FROM purchase";
    query += ` WHERE requestDate >= '${dateFrom}'`;
    query += ` AND requestDate <= '${dateTo}'`;
    query += " ORDER BY requestDate Desc";
    queryJsonArray("/purchase/queryrequest", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function onDeleteButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    let name: string = U.stringValueFromJson(selectedRow, "name");
    let res = confirm(name, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/purchase/deleterequest", selectedNo).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onOrderButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    // status가 "미발주" 상태인지 체크
    let status: string = U.stringValueFromJson(selectedRow, "status");
    if (status !== "미발주") {
      alert("Select 미발주 row!", "Error");
      return;
    }
    status = "미입고";
    let orderDate: number = U.todayEightDigitIntDate();
    orderPurchaseRequestAndAlert("/purchase/orderrequest", selectedNo, status, orderDate).then(() => {
      onSearchButtonClicked();
    });
  }
  function copyToUsageContext(obj: Object) {
    usage.date = U.todayEightDigitStringDate();
    usage.type = "입고";
    usage.registrant = userName;
    usage.itemId = U.stringValueFromJson(obj, "id");
    usage.itemName = U.stringValueFromJson(obj, "name");
    usage.category = U.stringValueFromJson(obj, "category");
    usage.purchaseNo = U.stringValueFromJson(obj, "no");
    usage.orderDate = U.stringValueFromJson(obj, "orderDate");
    usage.usageUnit = U.stringValueFromJson(obj, "usageUnit");
    usage.usageUnitPrice = U.stringValueFromJson(obj, "unitPrice");
  }

  function onPartlyDeliveredButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    let status: string = U.stringValueFromJson(selectedRow, "status");
    if (status !== "미입고" && status !== "부분입고") {
      alert("미입고 혹은 부분입고 항목을 선택하세요!", "Error");
      return;
    }
    copyToUsageContext(selectedRow);
    usage.tag = "부분입고";
    navigate("/purchase/newUsage");
  }

  function onFullyDeliveredButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    let status: string = U.stringValueFromJson(selectedRow, "status");
    if (status !== "미입고" && status !== "부분입고") {
      alert("미입고 혹은 부분입고 항목을 선택하세요!", "Error");
      return;
    }
    copyToUsageContext(selectedRow);
    usage.tag = "입고완료";
    navigate("/purchase/newUsage");
  }

  function onNoteButtonClicked() {
    if (isNoteGroupVisible) {
      setIsNoteGroupVisible(false);
      return;
    }
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    setIsNoteGroupVisible(true);
    let name: string = U.stringValueFromJson(selectedRow, "name");
    setNoteTitle(name);
    let note: string = U.stringValueFromJson(selectedRow, "note");
    setNoteContent(note);
    setNoteNo(selectedNo);
  }

  function onNoteTitleTextBoxValueChanged(e: string) {
    setNoteTitle(e);
  }
  function onNoteContentTextAreaValueChanged(e: string) {
    setNoteContent(e);
  }

  function onUpdateNoteButtonClicked() {
    updateNoteByNoAndAlert("/purchase/editrequestnote", noteNo, noteContent).then(() => {
      onSearchButtonClicked();
    });
  }

  function onDataGridSelectionChanged(e: any) {
    setIsNoteGroupVisible(false);
    if (e.selectedRowsData.length != 0) {
      setSelectedNo(e.selectedRowsData[0].no);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>구매이력 조회</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                placeholder="From (YYYYMMDD)"
                value={filterFrom}
                valueChangeEvent="keyup"
                onValueChange={onFromTextBoxValueChanged}
                width={200}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <TextBox
                placeholder="To (YYYYMMDD)"
                value={filterTo}
                valueChangeEvent="keyup"
                onValueChange={onToTextBoxValueChanged}
                width={200}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <SelectBox
                dataSource={["1주일", "1개월", "2개월", "6개월", "1년", "2년"]}
                defaultValue={"1개월"}
                width={150}
                height={40}
                onSelectionChanged={onDateSelectionChanged}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" visible={isDeleteButtonVisible} onClick={onDeleteButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="발주처리" visible={isOrderButtonVisible} onClick={onOrderButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="부분입고" visible={isDeliveredButtonVisible} onClick={onPartlyDeliveredButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="입고완료" visible={isDeliveredButtonVisible} onClick={onFullyDeliveredButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Note" visible={isNoteButtonVisible} onClick={onNoteButtonClicked} />
            </div>
          </div>

          <div>
            <div className={"flex-item2"}>
              <TextBox
                label="품명"
                visible={isNoteGroupVisible}
                readOnly={true}
                placeholder="품명"
                value={noteTitle}
                onValueChange={onNoteTitleTextBoxValueChanged}
                valueChangeEvent="keyup"
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextArea
                label="Note"
                placeholder="Note"
                visible={isNoteGroupVisible}
                onValueChange={onNoteContentTextAreaValueChanged}
                value={noteContent}
                showClearButton={true}
              ></TextArea>
            </div>
            <div className={"flex-item2"}>
              <Button text="Update Note" visible={isNoteGroupVisible} onClick={onUpdateNoteButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="requestDate" caption="요청일" />
              <Column dataField="requester" caption="요청자" />
              <Column dataField="status" caption="현상태" />
              <Column dataField="id" caption="허가품목코드" />
              <Column dataField="name" caption="품명" />
              <Column dataField="quantity" caption="구매수량" />
              <Column dataField="purchaseUnit" caption="구매단위" />
              <Column dataField="unitPrice" caption="구매단위단가" dataType="number" format="#,##0" />
              <Column dataField="totalPrice" caption="합계금액 (원)" dataType="number" format="#,##0" />
              <Column dataField="isProjectBudget" caption="과제비 여부" dataType="boolean" />
              <Column dataField="usageUnitQuantity" caption="재고단위 구매수량" />
              <Column dataField="usageUnit" caption="재고단위" />
              <Column dataField="monthlyUsage" caption="월간사용량" dataType="number" format="#,##0" />
              <Column dataField="orderDate" caption="발주일" />
              <Column dataField="estDelDate" caption="납기예정일" />
              <Column dataField="deliveryDate" caption="입고완료일" />
              <Column dataField="note" caption="Note" />
              <Column dataField="purchaseReason" caption="구매사유" />
              <Column dataField="supplier" caption="공급업체" />
              <Column dataField="category" caption="분류" />
              <Column dataField="catNo" caption="Cat. No." />

              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="requestDate" summaryType="count" valueFormat="#,##0" />
                <TotalItem column="totalPrice" summaryType="sum" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
