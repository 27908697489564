import "./purchaseItemNew.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { usePurchaseItem } from "../../contexts/purchaseItem";
// api
import { uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";

export default function PurchaseItemNew() {
  const { user } = useAuth();
  const purchaseItem = usePurchaseItem();
  const navigate = useNavigate();

  const [userId, setUserId] = useState<string>("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isChemicalVisible, setIsChemicalVisible] = useState(true);
  const [purpose, setPurpose] = useState("");
  const [purchaseUnit, setPurchaseUnit] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [usageUnit, setUsageUnit] = useState("");
  const [purchaseUnitRatio, setPurchaseUnitRatio] = useState("");
  const [leadTime, setLeadTime] = useState("");
  const [manager, setManager] = useState("");
  const [category, setCategory] = useState("주입물질");
  const [site, setSite] = useState("판교");
  const [storage, setStorage] = useState("");
  const [isPeriodic, setIsPeriodic] = useState("일시적 구매");
  const [isChemical, setIsChemical] = useState("일반 제품");
  const [catNo, setCatNo] = useState("");
  const [casNo, setCasNo] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [supplier, setSupplier] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contact, setContact] = useState("");
  const [selectionReason, setSelectionReason] = useState("");
  const [comparativeEstimate0, setComparativeEstimate0] = useState("");
  const [comparativeEstimate1, setComparativeEstimate1] = useState("");
  const [comparativeEstimate2, setComparativeEstimate2] = useState("");

  const [hasMsds, setHasMsds] = useState("미보유");
  const [msdsHazSta, setMsdsHazSta] = useState("");
  const [hazLev, setHazLev] = useState("");
  const [hazMat, setHazMat] = useState("");
  const [speHazMat, setSpeHazMat] = useState("");
  const [worEnvHazFac, setWorEnvHazFac] = useState("");
  const [heaCheHazFac, setHeaCheHazFac] = useState("");
  const [expStaMat, setExpStaMat] = useState("");
  const [quaConHazFac, setQuaConHazFac] = useState("");
  const [repHazMat, setRepHazMat] = useState("");
  const [nonSecMat, setNonSecMat] = useState("");
  const [chemicalNote, setChemicalNote] = useState("");

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }
    if (!purchaseItem.id) return;
    if (purchaseItem.id.length === 0) return;
    // edit 버튼으로 진입한 경우: context 변수로부터 element에 복사
    setId(purchaseItem.id);
    setName(purchaseItem.name);
    setIsDisabled(purchaseItem.isDisabled);
    setPurpose(purchaseItem.purpose);
    setPurchaseUnit(purchaseItem.purchaseUnit);
    setUnitPrice(purchaseItem.unitPrice);
    setUsageUnit(purchaseItem.usageUnit);
    setPurchaseUnitRatio(purchaseItem.purchaseUnitRatio);
    setLeadTime(purchaseItem.leadTime);
    setManager(purchaseItem.manager);
    setCategory(purchaseItem.category);
    setSite(purchaseItem.site);
    setStorage(purchaseItem.storage);
    setIsPeriodic(purchaseItem.isPeriodic);
    setIsChemical(purchaseItem.isChemical);
    if (purchaseItem.isChemical === "화학 제품") setIsChemicalVisible(true);
    setCatNo(purchaseItem.catNo);
    setCasNo(purchaseItem.casNo);
    setManufacturer(purchaseItem.manufacturer);
    setSupplier(purchaseItem.supplier);
    setContactPerson(purchaseItem.contactPerson);
    setContact(purchaseItem.contact);
    setSelectionReason(purchaseItem.selectionReason);
    setComparativeEstimate0(purchaseItem.comparativeEstimate0);
    setComparativeEstimate1(purchaseItem.comparativeEstimate1);
    setComparativeEstimate2(purchaseItem.comparativeEstimate2);
    setHasMsds(purchaseItem.hasMsds);
    setMsdsHazSta(purchaseItem.msdsHazSta);
    setHazLev(purchaseItem.hazLev);
    setHazMat(purchaseItem.hazMat);
    setSpeHazMat(purchaseItem.speHazMat);
    setWorEnvHazFac(purchaseItem.worEnvHazFac);
    setHeaCheHazFac(purchaseItem.heaCheHazFac);
    setExpStaMat(purchaseItem.expStaMat);
    setQuaConHazFac(purchaseItem.quaConHazFac);
    setRepHazMat(purchaseItem.repHazMat);
    setNonSecMat(purchaseItem.nonSecMat);
    setChemicalNote(purchaseItem.chemicalNote);
  }, []);

  function onIdTextBoxValueChanged(e: string) {
    setId(e);
  }
  function onNameTextBoxValueChanged(e: string) {
    setName(e);
  }
  function onIsDisabledCheckBoxValueChanged(e: boolean) {
    setIsDisabled(e);
  }
  function onPurposeTextBoxValueChanged(e: string) {
    setPurpose(e);
  }
  function onPurchaseUnitTextBoxValueChanged(e: string) {
    setPurchaseUnit(e);
  }
  function onUnitPriceTextBoxValueChanged(e: string) {
    setUnitPrice(e);
  }
  function onUsageUnitTextBoxValueChanged(e: string) {
    setUsageUnit(e);
  }
  function onPurchaseUnitRatioTextBoxValueChanged(e: string) {
    setPurchaseUnitRatio(e);
  }
  function onLeadTimeTextBoxValueChanged(e: string) {
    setLeadTime(e);
  }
  function onManagerTextBoxValueChanged(e: string) {
    setManager(e);
  }
  function onCategorySelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setCategory(str);
  }
  function onSiteSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setSite(str);
  }
  function onStorageTextBoxValueChanged(e: string) {
    setStorage(e);
  }
  function onIsPeriodicSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setIsPeriodic(str);
  }
  function onIsChemicalSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setIsChemical(str);
    if (str === "화학 제품") setIsChemicalVisible(true);
    else setIsChemicalVisible(false);
  }
  function onCatNoTextBoxValueChanged(e: string) {
    setCatNo(e);
  }
  function onCasNoTextBoxValueChanged(e: string) {
    setCasNo(e);
  }
  function onManufacturerTextBoxValueChanged(e: string) {
    setManufacturer(e);
  }
  function onSupplierTextBoxValueChanged(e: string) {
    setSupplier(e);
  }
  function onContactPersonTextBoxValueChanged(e: string) {
    setContactPerson(e);
  }
  function onContactTextBoxValueChanged(e: string) {
    setContact(e);
  }
  function onSelectionReasonTextBoxValueChanged(e: string) {
    setSelectionReason(e);
  }
  function onComparativeEstimate0TextBoxValueChanged(e: string) {
    setComparativeEstimate0(e);
  }
  function onComparativeEstimate1TextBoxValueChanged(e: string) {
    setComparativeEstimate1(e);
  }
  function onComparativeEstimate2TextBoxValueChanged(e: string) {
    setComparativeEstimate2(e);
  }
  function onHasMsdsSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setHasMsds(str);
  }
  function onMsdsHazStaTextBoxValueChanged(e: string) {
    setMsdsHazSta(e);
  }
  function onHazLevTextBoxValueChanged(e: string) {
    setHazLev(e);
  }
  function onHazMatTextBoxValueChanged(e: string) {
    setHazMat(e);
  }
  function onSpeHazMatTextBoxValueChanged(e: string) {
    setSpeHazMat(e);
  }
  function onWorEnvHazFacTextBoxValueChanged(e: string) {
    setWorEnvHazFac(e);
  }
  function onHeaCheHazFacTextBoxValueChanged(e: string) {
    setHeaCheHazFac(e);
  }
  function onExpStaMatTextBoxValueChanged(e: string) {
    setExpStaMat(e);
  }
  function onQuaConHazFacTextBoxValueChanged(e: string) {
    setQuaConHazFac(e);
  }
  function onRepHazMatTextBoxValueChanged(e: string) {
    setRepHazMat(e);
  }
  function onNonSecMatTextBoxValueChanged(e: string) {
    setNonSecMat(e);
  }
  function onChemicalNoteTextBoxValueChanged(e: string) {
    setChemicalNote(e);
  }

  async function onUploadButtonClicked() {
    if (!U.isProperPurchaseItemId(id)) {
      alert("Enter proper value to 허가품목코드!", "Error");
      return;
    }
    if (name.length === 0) {
      alert("Enter value to 품명!", "Error");
      return;
    }

    // boolean인 column 들에 대해 string에서 int으로 변환 후 upload
    let isPeriodicN: number = 0;
    if (isPeriodic === "주기적 구매") isPeriodicN = 1;
    let isChemicalN: number = 0;
    if (isChemical === "화학 제품") isChemicalN = 1;
    let hasMsdsN: number = 0;
    if (hasMsds === "보유") hasMsdsN = 1;

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let purchaseUnitRatioN: number = parseInt(purchaseUnitRatio);
    if (Number.isNaN(purchaseUnitRatioN)) purchaseUnitRatioN = 0;
    let unitPriceF: number = parseFloat(unitPrice);
    if (Number.isNaN(unitPriceF)) unitPriceF = 0;
    let leadTimeN: number = parseInt(leadTime);
    if (Number.isNaN(leadTimeN)) leadTimeN = 0;

    let obj: Object = {
      id: id,
      name: name,
      isDisabled: isDisabled,
      purpose: purpose,
      purchaseUnit: purchaseUnit,
      unitPrice: unitPriceF,
      usageUnit: usageUnit,
      purchaseUnitRatio: purchaseUnitRatioN,
      leadTime: leadTimeN,
      manager: manager,
      category: category,
      site: site,
      storage: storage,
      isPeriodic: isPeriodicN,
      isChemical: isChemicalN,
      catNo: catNo,
      casNo: casNo,
      manufacturer: manufacturer,
      supplier: supplier,
      contactPerson: contactPerson,
      contact: contact,
      selectionReason: selectionReason,
      comparativeEstimate0: comparativeEstimate0,
      comparativeEstimate1: comparativeEstimate1,
      comparativeEstimate2: comparativeEstimate2,
      hasMsds: hasMsdsN,
      msdsHazSta: msdsHazSta,
      hazLev: hazLev,
      hazMat: hazMat,
      speHazMat: speHazMat,
      worEnvHazFac: worEnvHazFac,
      heaCheHazFac: heaCheHazFac,
      expStaMat: expStaMat,
      quaConHazFac: quaConHazFac,
      repHazMat: repHazMat,
      nonSecMat: nonSecMat,
      chemicalNote: chemicalNote,
    };
    await uploadJson("/purchase/uploaditem", obj).then((response_status: number) => {
      console.log(response_status);
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 22) {
        let res = confirm("Do you want to overwrite?", "Same id exists");
        res.then((dialogResult) => {
          if (dialogResult) {
            // Yes
            uploadJsonAndAlert("/purchase/overwriteitem", obj);
            navigate(-1);
          } else return;
        });
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>구매허가품목 등록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="허가품목코드" value={id} valueChangeEvent="keyup" onValueChange={onIdTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="품명" value={name} valueChangeEvent="keyup" onValueChange={onNameTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <CheckBox text="비사용" value={isDisabled} onValueChange={onIsDisabledCheckBoxValueChanged} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="사용용도"
                value={purpose}
                valueChangeEvent="keyup"
                onValueChange={onPurposeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="구매단위"
                value={purchaseUnit}
                valueChangeEvent="keyup"
                onValueChange={onPurchaseUnitTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="구매단위원가"
                value={unitPrice}
                valueChangeEvent="keyup"
                onValueChange={onUnitPriceTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="재고단위"
                value={usageUnit}
                valueChangeEvent="keyup"
                onValueChange={onUsageUnitTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="구매단위비율"
                value={purchaseUnitRatio}
                valueChangeEvent="keyup"
                onValueChange={onPurchaseUnitRatioTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="안전재고기간"
                value={leadTime}
                valueChangeEvent="keyup"
                onValueChange={onLeadTimeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="사내관리담당자"
                value={manager}
                valueChangeEvent="keyup"
                onValueChange={onManagerTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <SelectBox
                label="분류"
                dataSource={["주입물질", "세포배양", "공동소모품", "분석", "전달공정"]}
                value={category}
                width={300}
                onSelectionChanged={onCategorySelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <SelectBox label="사이트" dataSource={["판교", "분당"]} value={site} width={300} onSelectionChanged={onSiteSelectionChanged} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="보관위치"
                value={storage}
                valueChangeEvent="keyup"
                onValueChange={onStorageTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <SelectBox
                label="주기적/일시적 구매"
                dataSource={["주기적 구매", "일시적 구매"]}
                value={isPeriodic}
                width={300}
                onSelectionChanged={onIsPeriodicSelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <SelectBox
                label="화학/일반 제품"
                dataSource={["일반 제품", "화학 제품"]}
                value={isChemical}
                width={300}
                onSelectionChanged={onIsChemicalSelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Cat. No." value={catNo} valueChangeEvent="keyup" onValueChange={onCatNoTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Cas/Serial"
                value={casNo}
                valueChangeEvent="keyup"
                onValueChange={onCasNoTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="제조사"
                value={manufacturer}
                valueChangeEvent="keyup"
                onValueChange={onManufacturerTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="공급업체"
                value={supplier}
                valueChangeEvent="keyup"
                onValueChange={onSupplierTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="업체담당자"
                value={contactPerson}
                valueChangeEvent="keyup"
                onValueChange={onContactPersonTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="업체연락처"
                value={contact}
                valueChangeEvent="keyup"
                onValueChange={onContactTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="선정사유"
                value={selectionReason}
                valueChangeEvent="keyup"
                onValueChange={onSelectionReasonTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="비교견적-1"
                value={comparativeEstimate0}
                valueChangeEvent="keyup"
                onValueChange={onComparativeEstimate0TextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="비교견적-2"
                value={comparativeEstimate1}
                valueChangeEvent="keyup"
                onValueChange={onComparativeEstimate1TextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="비교견적-3"
                value={comparativeEstimate2}
                valueChangeEvent="keyup"
                onValueChange={onComparativeEstimate2TextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <SelectBox
                label="MSDS 보유"
                visible={isChemicalVisible}
                dataSource={["미보유", "보유"]}
                value={hasMsds}
                width={300}
                onSelectionChanged={onHasMsdsSelectionChanged}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="MSDS 유해문구"
                visible={isChemicalVisible}
                value={msdsHazSta}
                valueChangeEvent="keyup"
                onValueChange={onMsdsHazStaTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="위험도 (1~5)"
                visible={isChemicalVisible}
                value={hazLev}
                valueChangeEvent="keyup"
                onValueChange={onHazLevTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="관리대상 유해물질"
                visible={isChemicalVisible}
                value={hazMat}
                valueChangeEvent="keyup"
                onValueChange={onHazMatTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="특별관리물질"
                visible={isChemicalVisible}
                value={speHazMat}
                valueChangeEvent="keyup"
                onValueChange={onSpeHazMatTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="작업환경측정대상 유해인자"
                visible={isChemicalVisible}
                value={worEnvHazFac}
                valueChangeEvent="keyup"
                onValueChange={onWorEnvHazFacTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="특수건강검진대상 유해인자"
                visible={isChemicalVisible}
                value={heaCheHazFac}
                valueChangeEvent="keyup"
                onValueChange={onHeaCheHazFacTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="노출기준 설정물질"
                visible={isChemicalVisible}
                value={expStaMat}
                valueChangeEvent="keyup"
                onValueChange={onExpStaMatTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="허용기준 이하 유지대상 유해인자"
                visible={isChemicalVisible}
                value={quaConHazFac}
                valueChangeEvent="keyup"
                onValueChange={onQuaConHazFacTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="공정안전보고서 제출대상 위험물질"
                visible={isChemicalVisible}
                value={repHazMat}
                valueChangeEvent="keyup"
                onValueChange={onRepHazMatTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="영업비밀 인정제외 물질"
                visible={isChemicalVisible}
                value={nonSecMat}
                valueChangeEvent="keyup"
                onValueChange={onNonSecMatTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="비고"
                visible={isChemicalVisible}
                value={chemicalNote}
                valueChangeEvent="keyup"
                onValueChange={onChemicalNoteTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
