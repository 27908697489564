import "./csPart.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCsPart } from "../../contexts/csPart";
import { useCsPartUsage } from "../../contexts/csPartUsage";
// api
import { queryJsonArray, deleteRowById, downloadBlobPng } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { CheckBox } from "devextreme-react/check-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";
import { Url } from "devextreme-react/chart";

export default function CsPart() {
  const { user } = useAuth();
  const csPart = useCsPart();
  const csPartUsage = useCsPartUsage();
  const navigate = useNavigate();

  if (user) {
  }
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imagePartNumber, setImagePartNumber] = useState("");
  const [isPartImageHidden, setIsPartImageHidden] = useState(true);
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedId, setSelectedId] = useState("");
  const [selectedHyperlink, setSelectedHyperlink] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [type, setType] = useState("구매품");
  const [isUsageVisible, setIsUsageVisible] = useState(false);
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);
  const [includeDisabled, setIncludeDisabled] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
    let acc_cs = 0;
    if (user) {
      setUserId(user.id);
      setUserName(user.name);
      acc_cs = user.acc_cs;
    }
    // (cs) [0] part R, [1] W, [2] D, [3] part usage W, [4] D
    if (U.bitAt(acc_cs, 1)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_cs, 2)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);
    if (U.bitAt(acc_cs, 3)) setIsUsageVisible(true);
    else setIsUsageVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search(false, "전체");
    }
  }, []);

  function onTypeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setType(str);
    search(includeDisabled, str);
  }

  function onSearchButtonClicked() {
    search(includeDisabled, type);
  }

  function search(includeD: boolean, typeToSearch: string) {
    setSelectedId("");
    setSelectedRow(undefined);
    let query = `SELECT * FROM cspart`;
    if (typeToSearch !== "전체") {
      query += ` WHERE type = '${typeToSearch}'`;
      if (includeD === false) {
        query += " AND isDisabled = 0";
      }
    } else {
      if (includeD === false) {
        query += " WHERE isDisabled = 0";
      }
    }

    queryJsonArray("/cs/querypart", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function onImageButtonClicked() {
    if (!isPartImageHidden) {
      // clicked to hide image
      setIsPartImageHidden(true);
      setImageUrl("");
    } else {
      // clicked to show image
      if (!selectedRow) {
        alert("Select row first!", "Error");
        return;
      }
      let id: string = U.stringValueFromJson(selectedRow, "id");
      let partNumber: string = U.stringValueFromJson(selectedRow, "partNumber");
      console.log("id: ", id);
      downloadBlobPng(id).then((response: string | undefined) => {
        console.log("response: ", response);
        if (response !== undefined) setImageUrl(response);
        setImagePartNumber(partNumber);
        setIsPartImageHidden(false);
      });
    }
  }

  function onStockInButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCsPartUsageContext(selectedRow);
    csPartUsage.type = "입고";
    navigate("/cs/partNewUsage");
  }

  function copyToCsPartUsageContext(obj: Object) {
    csPartUsage.date = U.todayEightDigitStringDate();
    csPartUsage.registrant = userName;
    csPartUsage.itemId = U.stringValueFromJson(obj, "id");
    csPartUsage.itemPartNumber = U.stringValueFromJson(obj, "partNumber");
    csPartUsage.itemType = U.stringValueFromJson(obj, "type");
  }

  function onUseButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCsPartUsageContext(selectedRow);
    csPartUsage.type = "사용";
    navigate("/cs/partNewUsage");
  }

  function onDestroyButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCsPartUsageContext(selectedRow);
    csPartUsage.type = "폐기";
    navigate("/cs/partNewUsage");
  }

  function onCheckButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCsPartUsageContext(selectedRow);
    csPartUsage.type = "실재고 확인";
    navigate("/cs/partNewUsage");
  }

  function onNewButtonClicked() {
    csPart.id = "";
    navigate("/cs/newPart");
  }

  function copyToCsPartContext(obj: Object) {
    csPart.id = U.stringValueFromJson(obj, "id");
    csPart.isDisabled = U.booleanValueFromJson(obj, "isDisabled");
    csPart.type = U.stringValueFromJson(obj, "type");
    csPart.partNumber = U.stringValueFromJson(obj, "partNumber");
    csPart.partName = U.stringValueFromJson(obj, "partName");
    csPart.spec = U.stringValueFromJson(obj, "spec");
    csPart.purpose = U.stringValueFromJson(obj, "purpose");
    csPart.supplier = U.stringValueFromJson(obj, "supplier");
    csPart.contactPerson = U.stringValueFromJson(obj, "contactPerson");
    csPart.contact = U.stringValueFromJson(obj, "contact");
    csPart.hyperlink = U.stringValueFromJson(obj, "hyperlink");
    csPart.manager = U.stringValueFromJson(obj, "manager");
    csPart.site = U.stringValueFromJson(obj, "site");
    csPart.storage = U.stringValueFromJson(obj, "storage");
    csPart.note = U.stringValueFromJson(obj, "note");
    csPart.price = U.floatValueFromJson(obj, "price");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCsPartContext(selectedRow);
    navigate("/cs/newPart");
  }

  function onDeleteButtonClicked() {
    if (selectedId.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedId, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/cs/deletepart", selectedId).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onOpenHyperlinkButtonClicked() {
    if (selectedHyperlink.length === 0) {
      alert("Select row which has hyperlink!", "Error");
      return;
    }
    window.open(selectedHyperlink);
  }

  function onIncludeDisabledCheckBoxValueChanged(e: boolean) {
    setIncludeDisabled(e);
    search(e, type);
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedId(e.selectedRowsData[0].id);
      setSelectedHyperlink(e.selectedRowsData[0].hyperlink);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  function onDataGridCellDbClick(e: any) {
    onImageButtonClicked();
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>셀샷부품 재고현황</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <SelectBox
                dataSource={["전체", "구매품", "전자회로", "CNC", "사출품", "3D프린팅"]}
                defaultValue={"전체"}
                width={150}
                height={40}
                onSelectionChanged={onTypeSelectionChanged}
              />
            </div>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
            <div className={"flex-item1"}>
              <Button text="Image" onClick={onImageButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Open Hyperlink" onClick={onOpenHyperlinkButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <CheckBox text="비사용 품목 포함" value={includeDisabled} onValueChange={onIncludeDisabledCheckBoxValueChanged} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="입고" visible={isUsageVisible} onClick={onStockInButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="사용" visible={isUsageVisible} onClick={onUseButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="폐기" visible={isUsageVisible} onClick={onDestroyButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="실재고 확인" visible={isUsageVisible} onClick={onCheckButtonClicked} />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"part-image"} hidden={isPartImageHidden}>
              <img alt={""} src={imageUrl} />
            </div>
            <span hidden={isPartImageHidden}>{imagePartNumber}</span>
          </div>

          <div>
            <DataGrid
              onCellDblClick={onDataGridCellDbClick}
              onSelectionChanged={onDataGridSelectionChanged}
              dataSource={jsonDataSource}
              columnAutoWidth={true}
              allowColumnReordering={true}
            >
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="type" caption="종류" />
              <Column dataField="id" caption="ID" />
              <Column dataField="partNumber" caption="Part Number" />
              <Column dataField="quantity" caption="재고수량" dataType="number" format="#,##0" />
              <Column dataField="partName" caption="Part Name" />
              <Column dataField="spec" caption="Spec." />
              <Column dataField="site" caption="Site" />
              <Column dataField="storage" caption="보관위치" />
              <Column dataField="price" caption="단가 (원)" dataType="number" format="#,##0.##" />
              <Column dataField="note" caption="Note" />
              <Column dataField="isDisabled" caption="비사용" dataType="boolean" />
              <Column dataField="manager" caption="사내담당자" />
              <Column dataField="supplier" caption="공급업체" />
              <Column dataField="contactPerson" caption="업체담당자" />
              <Column dataField="contact" caption="업체연락처" />
              <Column dataField="purpose" caption="사용용도" />
              <Column dataField="hyperlink" caption="Hyperlink" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="type" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
