import "./progressNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useProgress } from "../../contexts/progress";
// api
import { uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import DateBox from "devextreme-react/date-box";
import TextArea from "devextreme-react/text-area";

export default function ProgressNew() {
  const { user } = useAuth();
  const progressContext = useProgress();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [no, setNo] = useState("");
  const [date, setDate] = useState<Date>();
  const [department, setDepartment] = useState("");
  const [registrant, setRegistrant] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }

    if (!progressContext.no) {
      // new item
      setNo("0");
      setDepartment(progressContext.department);
      setRegistrant(progressContext.registrant);
      setDate(progressContext.date);
      return;
    }
    if (progressContext.no.length === 0) {
      // new item
      setNo("0");
      setDepartment(progressContext.department);
      setRegistrant(progressContext.registrant);
      setDate(progressContext.date);
      return;
    }
    // edit 버튼으로 진입한 경우: context 변수로부터 element에 복사
    setNo(progressContext.no);
    setDepartment(progressContext.department);
    setDate(progressContext.date);
    setRegistrant(progressContext.registrant);
    setContent(progressContext.content);
  }, []);

  function onDateDateBoxValueChanged(e: any) {
    setDate(e);
  }
  function onContentTextAreaValueChanged(e: string) {
    setContent(e);
  }

  ///////////////////////////// Upload Button
  async function onUploadButtonClicked() {
    if (content.length === 0) {
      alert("내용을 입력하세요!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let noN: number = parseInt(no);
    if (Number.isNaN(noN)) noN = 0;

    let obj: Object = {
      no: noN,
      date: date,
      department: department,
      registrant: registrant,
      content: content,
    };
    await uploadJsonAndAlert("/progress/upload", obj);
    navigate(-1);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>진척도 등록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <DateBox
                label="날짜"
                type="date"
                //pickerType="rollers"
                value={date}
                valueChangeEvent="change"
                onValueChange={onDateDateBoxValueChanged}
                width={300}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="부서" value={department} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="등록자" value={registrant} readOnly={true} width={300} />
            </div>
          </div>

          <div className={"flex-stretch"}>
            <div className={"flex-item2"}>
              <TextArea label="내용" value={content} onValueChange={onContentTextAreaValueChanged} autoResizeEnabled={true} maxHeight={600} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
