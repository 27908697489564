import { createContext, useContext } from "react";
export type CertificateContextType = {
  no: number;
  registrationDate: number;
  type: string;
  title: string;
  result: string;
  expireDate: number;
  organization: string;
  cost: number;
  manager: string;
  note: string;
  updated_by: string;
  updated_at: Date;
};
const CertificateContext = createContext<CertificateContextType>({} as CertificateContextType);
const useCertificate = () => useContext(CertificateContext);
export { useCertificate };
