import "./dbUsage.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useUserList } from "../../contexts/userList";
import { useLog } from "../../contexts/log";
// api
import { queryJsonArray } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";
import PieChart, { Series, Label, Connector, SmallValuesGrouping, Legend } from "devextreme-react/pie-chart";

export default function DbUsage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const userListContext = useUserList();
  const logContext = useLog();

  const [userId, setUserId] = useState("");
  const [filterFrom, setFilterFrom] = useState("");
  const [filterTo, setFilterTo] = useState("");
  const [departmentFilterList, setDepartmentFilterList] = useState<Array<string>>();
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [nameFilterList, setNameFilterList] = useState<Array<string>>();
  const [nameFilter, setNameFilter] = useState("");
  const [userIdFilter, setUserIdFilter] = useState("");

  const [dataSource1, setDataSource1] = useState<Array<Object>>();
  const [dataSource2, setDataSource2] = useState<Array<Object>>();
  const [chart1Title, setChart1Title] = useState("");
  const [chart2Title, setChart2Title] = useState("");
  const [tableJsonDataSource, setTableJsonDataSource] = useState<Array<Object>>();

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;

      // userList가 이미 다운로드 받은 경우
      initializeQueryList();
      setNameFilter("All");
      setDepartmentFilter("All");
    }
  }, []);

  function initializeQueryList() {
    // userListContext 정보를 분석해서 selectBox의 item list를 초기화한다.
    let l1: Array<string> = ["All"];
    let l2: Array<string> = ["All"];
    for (let i = 0; i < userListContext.names.length; i++) {
      //if (!U.bitAt(userListContext.acc_admins[i], 0)) continue;  // acc_admin을 검사해서 근태관리 대상인 user에 대해서만 처리한다.
      U.addStringArrayIfNotExist(l1, userListContext.departments[i]);
      U.addStringArrayIfNotExist(l2, userListContext.names[i]);
    }
    setDepartmentFilterList(l1);
    setNameFilterList(l2);

    let dateRange = calculateDateRange("1개월");
    if (dateRange) search(dateRange[0], dateRange[1], "All", "", "All");
  }

  function onFromTextBoxValueChanged(e: string) {
    setFilterFrom(e);
  }
  function onToTextBoxValueChanged(e: string) {
    setFilterTo(e);
  }

  function onDateRangeSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    calculateDateRange(str);
  }
  function calculateDateRange(str: string) {
    let todayStr: string = U.todayEightDigitStringDate();
    setFilterTo(todayStr);
    let todayYMD = U.todayIntArray();
    let moveY: number = 0,
      moveM: number = 0,
      moveD: number = 0;
    if (str === "1주일") moveD = -6;
    if (str === "1개월") {
      moveM = -1;
      moveD = 1;
    }
    if (str === "2개월") {
      moveM = -2;
      moveD = 1;
    }
    if (str === "6개월") {
      moveM = -6;
      moveD = 1;
    }
    if (str === "1년") {
      moveY = -1;
      moveD = 1;
    }
    if (str === "2년") {
      moveY = -2;
      moveD = 1;
    }
    let startYMD = U.findMovedDate(todayYMD[0], todayYMD[1], todayYMD[2], moveY, moveM, moveD);
    let startStr = U.eightDigitStringDateFromIntArray(startYMD[0], startYMD[1], startYMD[2]);
    setFilterFrom(startStr);
    return [startStr, todayStr];
  }
  function onDepartmentFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setDepartmentFilter(str);
    setNameFilter("All");
    setUserIdFilter("");

    calculateStatistics(str, "", "All");
  }

  function onNameFilterSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setNameFilter(str);
    let userId: string = U.getStringValueFromStringArray(str, userListContext.names, userListContext.ids);
    setUserIdFilter(userId);
    calculateStatistics(departmentFilter, userId, str);
  }

  function onSearchButtonClicked() {
    search(filterFrom, filterTo, departmentFilter, userIdFilter, nameFilter);
  }

  function search(from: string, to: string, departmentToSearch: string, userIdToSearch: string, userNameToSearch: string) {
    if (!U.isProperEightDigitStringDate(from)) {
      alert("Enter from date in proper format!", "Error");
      return;
    }
    if (!U.isProperEightDigitStringDate(to)) {
      alert("Enter to date in proper format!", "Error");
      return;
    }
    let dateFrom: number = parseInt(from);
    let dateTo: number = parseInt(to);
    let startDate = U.startUTCStringDateFromEightDigitIntDate(dateFrom);
    let endDate = U.endUTCStringDateFromEightDigitIntDate(dateTo);

    let query = "SELECT id, route, time FROM log";
    query += ` WHERE time >= '${startDate}' AND time < '${endDate}'`;
    query += " ORDER BY time Desc";
    queryJsonArray("/dbusage/query", query).then((logJson: Array<Object>) => {
      // logContext에 query 결과 저장
      logContext.ids = Array<string>();
      logContext.routes = Array<string>();
      logContext.times = Array<Date>();

      for (let i = 0; i < logJson.length; i++) {
        logContext.ids.push(U.stringValueFromJson(logJson[i], "id"));
        logContext.routes.push(U.stringValueFromJson(logJson[i], "route"));
        logContext.times.push(U.dateValueFromJson(logJson[i], "time"));
      }

      if (userNameToSearch.length > 0) calculateStatistics(departmentToSearch, userIdToSearch, userNameToSearch);
    });
  }

  function calculateStatistics(departmentToSearch: string, userIdToSearch: string, userNameToSearch: string) {
    // logContext의 값을 바탕으로 주어진 사용자에 대해 통계 계산해서 화면에 표시해준다.
    let userIdList1 = Array<string>();
    let routeList1 = Array<string>();
    let timeList1 = Array<Date>();

    for (let i = 0; i < logContext.ids.length; i++) {
      // log 각각의 기록에 대해 filter 설정에 따라 skip 여부를 체크한다.
      let userId = logContext.ids[i];
      let route = logContext.routes[i];
      let time = logContext.times[i];
      if (userNameToSearch === "All") {
        // userNameToSearch == "All"인 경우 departmentToSearch에 대해서 체크한다.
        if (departmentToSearch === "All") {
          let userName: string = U.getStringValueFromStringArray(userId, userListContext.ids, userListContext.names);
          if (userName.length === 0) continue; // 퇴사자인 경우 등 userList에 포함되지 않은 사람의 정보는 skip
        } else {
          let userDepartment: string = U.getStringValueFromStringArray(userId, userListContext.ids, userListContext.departments);
          if (userDepartment !== departmentToSearch) continue;
        }
      } else {
        // userNameToSearch에 특정 사용자가 선택된 경우에는 departmentToSearch는 고려하지 않는다.
        if (userId !== userIdToSearch) continue;
      }

      // 해당 row의 data (userId, route)가 부서 filter와 이름 filter를 만족하면 아래 코드가 실행된다.
      userIdList1.push(userId);
      routeList1.push(route);
      timeList1.push(time);
    }

    // login과 attendance 관련된 action을 제외한 나머지 action을 구하기
    let userIdList2 = Array<string>();
    let routeList2 = Array<string>();
    let timeList2 = Array<Date>();
    for (let i = 0; i < userIdList1.length; i++) {
      let route = routeList1[i];
      if (route.indexOf("/login") === 0) continue;
      if (route.indexOf("/attendance") === 0) continue;
      userIdList2.push(userIdList1[i]);
      routeList2.push(route);
      timeList2.push(timeList1[i]);
    }

    // 위에서 upload action만 추가로 filter
    let userIdList3 = Array<string>();
    let routeList3 = Array<string>();
    for (let i = 0; i < userIdList2.length; i++) {
      let route = routeList2[i];
      if (route.indexOf("upload") === -1) continue;
      userIdList3.push(userIdList2[i]);
      routeList3.push(route);
    }

    // Table
    let newJsonData = new Array<Object>();
    let dateNow = new Date(Date.now());
    for (let i = 0; i < userIdList2.length; i++) {
      let userId: string = userIdList2[i];
      let userName: string = U.getStringValueFromStringArray(userId, userListContext.ids, userListContext.names);
      let obj: Object | null = new Object();
      obj = U.addStringToJson(obj, "userName", userName);
      obj = U.addStringToJson(obj, "route", routeList2[i]);
      obj = U.addDateToJson(obj, "time", timeList2[i]);
      if (obj !== null) newJsonData.push(obj);
    }
    setTableJsonDataSource(newJsonData);

    // Pie Chart - 1
    newJsonData = new Array<Object>();
    if (userIdList2.length > 0) {
      let userIdList2Result = U.calculateStringRepeatDensity(userIdList2); // returned: [stringList, repeatList]
      let processedUserIds = userIdList2Result[0];
      let processedUserIdDensity = userIdList2Result[1];
      let cntSum: number = 0;
      for (let i = 0; i < processedUserIds.length; i++) {
        cntSum += Number(processedUserIdDensity[i]);
      }
      for (let i = 0; i < processedUserIds.length; i++) {
        let oneRowJson: Object | null = new Object();
        let userName: string = U.getStringValueFromStringArray(String(processedUserIds[i]), userListContext.ids, userListContext.names);
        oneRowJson = U.addStringToJson(oneRowJson, "title", userName);
        oneRowJson = U.addNumberToJson(oneRowJson, "percent", (100.0 * Number(processedUserIdDensity[i])) / cntSum);
        if (oneRowJson === null) continue;
        newJsonData.push(oneRowJson);
      }
      setChart1Title(`All Actions (${cntSum})`);
    } else {
      setChart1Title("All Actions (No Data)");
    }
    setDataSource1(newJsonData);

    // Pie Chart - 2
    newJsonData = new Array<Object>();
    if (userIdList3.length > 0) {
      let userIdList3Result = U.calculateStringRepeatDensity(userIdList3); // returned: [stringList, repeatList]
      let processedUserIds = userIdList3Result[0];
      let processedUserIdDensity = userIdList3Result[1];
      let cntSum: number = 0;
      for (let i = 0; i < processedUserIds.length; i++) {
        cntSum += Number(processedUserIdDensity[i]);
      }
      for (let i = 0; i < processedUserIds.length; i++) {
        let oneRowJson: Object | null = new Object();
        let userName: string = U.getStringValueFromStringArray(String(processedUserIds[i]), userListContext.ids, userListContext.names);
        oneRowJson = U.addStringToJson(oneRowJson, "title", userName);
        oneRowJson = U.addNumberToJson(oneRowJson, "percent", (100.0 * Number(processedUserIdDensity[i])) / cntSum);
        if (oneRowJson === null) continue;
        newJsonData.push(oneRowJson);
      }
      setChart2Title(`Upload Actions (${cntSum})`);
    } else {
      setChart2Title("Upload Actions (No Data)");
    }
    setDataSource2(newJsonData);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>FemtoDB 사용량</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox
                placeholder="From (YYYYMMDD)"
                value={filterFrom}
                valueChangeEvent="keyup"
                onValueChange={onFromTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <TextBox
                placeholder="To (YYYYMMDD)"
                value={filterTo}
                valueChangeEvent="keyup"
                onValueChange={onToTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <SelectBox
                dataSource={["1주일", "1개월", "2개월", "6개월", "1년", "2년"]}
                defaultValue={"1주일"}
                width={120}
                height={40}
                onSelectionChanged={onDateRangeSelectionChanged}
              />
            </div>

            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
          </div>

          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <SelectBox
                label="부서"
                dataSource={departmentFilterList}
                value={departmentFilter}
                width={180}
                height={40}
                onSelectionChanged={onDepartmentFilterSelectionChanged}
              />
            </div>
            <div className={"flex-item1"}>
              <SelectBox
                label="이름"
                dataSource={nameFilterList}
                value={nameFilter}
                width={180}
                height={40}
                onSelectionChanged={onNameFilterSelectionChanged}
              />
            </div>
          </div>

          <div className={"flex-containerTitle2"}>
            <p className={"text-title"}>(Login, 근태 관련된 Action은 제외)</p>
          </div>

          <div className={"flex-item1"}>
            <PieChart id="pie" dataSource={dataSource1} palette="Bright" title={chart1Title}>
              <Series argumentField="title" valueField="percent">
                <Label visible={true} customizeText={formatLabel} format="fixedPoint">
                  <Connector visible={true} width={0.5} />
                </Label>
                <SmallValuesGrouping threshold={1.0} mode="smallValueThreshold" />
              </Series>
              <Legend horizontalAlignment="center" verticalAlignment="bottom" />
              <Export enabled={true} />
            </PieChart>
          </div>

          <div className={"flex-item1"}>
            <PieChart id="pie" dataSource={dataSource2} palette="Bright" title={chart2Title}>
              <Series argumentField="title" valueField="percent">
                <Label visible={true} customizeText={formatLabel} format="fixedPoint">
                  <Connector visible={true} width={0.5} />
                </Label>
                <SmallValuesGrouping threshold={1.0} mode="smallValueThreshold" />
              </Series>
              <Legend horizontalAlignment="center" verticalAlignment="bottom" />
              <Export enabled={true} />
            </PieChart>
          </div>

          <div>
            <DataGrid dataSource={tableJsonDataSource} columnAutoWidth={true} allowColumnReordering={false}>
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="time" caption="시간" dataType="datetime" />
              <Column dataField="userName" caption="사용자" />
              <Column dataField="route" caption="내용" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="time" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

function formatLabel(arg: any) {
  return `${arg.argumentText}: ${arg.valueText}%`;
}
