import { createContext, useContext } from "react";
export type ProjectMeetingContextType = {
  no: number;
  projectNo: number;
  projectName: string;
  name: string;
  date: number;
  registrant: string;
  content: string;
  updated_by: string;
  updated_at: Date;
};
const ProjectMeetingContext = createContext<ProjectMeetingContextType>({} as ProjectMeetingContextType);
const useProjectMeeting = () => useContext(ProjectMeetingContext);
export { useProjectMeeting };
