import { createContext, useContext } from "react";
export type ProjectContextType = {
  no: number;
  name: string;
  status: string;
  progress: number;
  department: string;
  manager: string;
  startDate: number;
  scheduledEndDate: number;
  endDate: number;
  note: string;
  cloudiumPath: string;
  purpose: string;
  abstract: string;
  conclusion: string;
  updated_by: string;
  updated_at: Date;
};
const ProjectContext = createContext<ProjectContextType>({} as ProjectContextType);
const useProject = () => useContext(ProjectContext);
export { useProject };
