import { createContext, useContext } from "react";
export type CtPartContextType = {
  id: string;
  name: string;
  description: string;
  supplier: string;
  manager: string;
};
const CtPartContext = createContext<CtPartContextType>({} as CtPartContextType);
const useCtPart = () => useContext(CtPartContext);
export { useCtPart };
