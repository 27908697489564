import "./projectMeeting.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
// api
import { queryJsonArray, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function ProjectMeeting() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedNo, setSelectedNo] = useState(-1);
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [content, setContent] = useState("");
  const [isContentHidden, setIsContentHidden] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  ////////////////////////// Search Button
  function onSearchButtonClicked() {
    search();
  }
  function search() {
    //setSelectedNo(-1);
    //setIsContentHidden(true);

    let query = "SELECT * FROM projectmeeting";
    query += " ORDER BY date Desc, no Desc";
    queryJsonArray("/project/query", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        let oneRowJson: Object | null = jsonData[i];
        let date: number = U.intValueFromJson(oneRowJson, "date");
        let date2: string = U.stringDateFromEightDigitIntDate(date);
        oneRowJson = U.addStringToJson(oneRowJson, "date2", date2);
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }
      setJsonDataSource(newJsonData);
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedNo(e.selectedRowsData[0].no);
      setSelectedRow(e.selectedRowsData[0]);
      setIsContentHidden(false);
      setContent(e.selectedRowsData[0].content);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Project 회의록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
          </div>

          <div className={"flex-stretch"}>
            <div className={"flex-item2"} hidden={isContentHidden}>
              <TextArea label="내용" value={content} readOnly={true} autoResizeEnabled={true} maxHeight={800} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="date2" caption="일자" />
              <Column dataField="projectName" caption="Project" />
              <Column dataField="name" caption="제목" />
              <Column dataField="registrant" caption="등록자" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="date2" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
